import { useMetadataKeys, useSavedSegments } from '@blissbook/application/graph'
import type { OperandField } from '@blissbook/ui/lib/expression'
import { useAudienceFields } from '@blissbook/ui/lib/expression/fields'
import { useSearchGroups } from './useSearchGroups'
import { useSearchMetadataValues } from './useSearchMetadataValues'
import { useSearchPeople } from './useSearchPeople'
import { useSearchSavedSegments } from './useSearchSavedSegments'

export type UseAudienceExpressionFieldsArgs = {
  allowPeople?: boolean
}

export function useAudienceExpressionFields({
  allowPeople,
}: UseAudienceExpressionFieldsArgs = {}): OperandField[] {
  const metadataKeys = useMetadataKeys()
  const searchMetadataValues = useSearchMetadataValues()

  const savedSegments = useSavedSegments()
  const hasSavedSegments = savedSegments.length > 0

  const searchGroups = useSearchGroups({ limit: 250 })
  const searchPeople = useSearchPeople({ limit: 250 })
  const searchSavedSegments = useSearchSavedSegments({ limit: 250 })

  return useAudienceFields(
    {
      metadataKeys,
      searchGroups,
      searchMetadataValues,
      searchPeople: allowPeople ? searchPeople : undefined,
      searchSavedSegments: hasSavedSegments ? searchSavedSegments : undefined,
    },
    [
      allowPeople,
      hasSavedSegments,
      metadataKeys,
      searchGroups,
      searchPeople,
      searchSavedSegments,
    ],
  )
}
