import { faCommentPlus } from '@fortawesome/pro-regular-svg-icons/faCommentPlus'
import { ButtonView, Plugin } from 'ckeditor5'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { getIconPath } from './util'

export class CommentUI extends Plugin {
  init() {
    const editor = this.editor as BlissbookEditor
    const { t } = editor

    editor.ui.componentFactory.add('addComment', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faCommentPlus),
        label: t('Comment'),
        tooltip: true,
        withText: true,
      })

      // Callback executed once the button is clicked
      view.on('execute', async () => {
        editor.execute('addCommentThread')
      })

      return view
    })
  }
}
