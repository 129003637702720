import { areOperandValuesValid } from '@blissbook/lib/expression'
// @ts-ignore: WIP imports
import { Dropdown, SearchInput } from '@blissbook/ui/lib'
import React, { useRef } from 'react'
import {
  OperandDropdownFooter,
  OperandDropdownOption,
  OperandDropdownToggleButton,
  RemoveIcon,
} from '.'
import type { BaseOperandField } from '../types'

export type TypeaheadOperandOption<ScalarValue> = {
  Icon?: (props: { className?: string }) => JSX.Element
  label: string
  value: ScalarValue
}

export type TypeaheadOperandField<ScalarValue> = BaseOperandField & {
  allowAny?: boolean
  allowEvery?: boolean
  searchOptions: (
    text: string,
  ) => Promise<TypeaheadOperandOption<ScalarValue>[]>
  useOptions: (
    values: ScalarValue[] | undefined,
  ) => TypeaheadOperandOption<ScalarValue>[] | undefined
}

export type TypeaheadOperandValue<ScalarValue> = {
  isEvery: boolean
  isNot: boolean
  values?: ScalarValue[]
}

type MatchOptionValue = {
  isEvery: boolean
  isNot: boolean
}

type MatchOption = {
  label: string
  hasValues: boolean
  value: MatchOptionValue
}

const allMatchOptions: MatchOption[] = [
  {
    label: 'includes any matching',
    hasValues: true,
    value: { isNot: false, isEvery: false },
  },
  {
    label: 'includes all matching',
    hasValues: true,
    value: { isNot: false, isEvery: true },
  },
  {
    label: 'excludes any matching',
    hasValues: true,
    value: { isNot: true, isEvery: false },
  },
  {
    label: 'excludes all matching',
    hasValues: true,
    value: { isNot: true, isEvery: true },
  },
  {
    label: 'has any value',
    hasValues: false,
    value: { isNot: false, isEvery: false },
  },
  {
    label: 'is unknown',
    hasValues: false,
    value: { isNot: true, isEvery: false },
  },
]

export function getMatchOptions({
  allowAny,
  allowEvery,
}: {
  allowAny?: boolean
  allowEvery?: boolean
} = {}) {
  return allMatchOptions.filter(
    (option) =>
      (allowEvery || !option.value.isEvery) && (allowAny || option.hasValues),
  )
}

export function getMatchOption<T>(
  options: MatchOption[],
  value: TypeaheadOperandValue<T>,
): MatchOption {
  if (value.values) {
    return options.find(
      (option) =>
        option.hasValues === true &&
        option.value.isEvery === value.isEvery &&
        option.value.isNot === value.isNot,
    )
  }

  return options.find(
    (option) =>
      option.hasValues === false && option.value.isNot === value.isNot,
  )
}

type SelectOption<ScalarValue> = {
  children: React.ReactNode
  key: string
  label: string
  value: ScalarValue
}

type TypeaheadOperandInputProps<
  Field extends TypeaheadOperandField<ScalarValue>,
  OperandValue extends TypeaheadOperandValue<ScalarValue>,
  ScalarValue,
> = {
  className?: string
  field: Field
  forceOpen?: boolean
  isOpen: boolean
  onChange: (value: OperandValue) => void
  onRemove: () => void
  setOpen: (isOpen: boolean) => void
  value: OperandValue
}

export function TypeaheadOperandInput<
  Field extends TypeaheadOperandField<ScalarValue>,
  OperandValue extends TypeaheadOperandValue<ScalarValue>,
  ScalarValue,
>({
  className,
  field,
  forceOpen,
  onChange,
  onRemove,
  setOpen,
  value,
  ...props
}: TypeaheadOperandInputProps<Field, OperandValue, ScalarValue>) {
  const isValid = areOperandValuesValid(value.values)

  // Determine the values to display, retain values when switching between options
  const valuesRef = useRef<ScalarValue[]>([])
  if (value.values) valuesRef.current = value.values
  const values = valuesRef.current

  // Get the match selection type option
  const matchOptions = getMatchOptions(field)
  const matchOption = getMatchOption(matchOptions, value)

  // Get the typeahead options
  const { searchOptions, useOptions } = field
  const options = useOptions(value.values)

  function getOptionFromValue(value: ScalarValue) {
    return options?.find((option) => option.value === value)
  }

  function getLabelFromValue(value: ScalarValue): string {
    const option = getOptionFromValue(value)
    return option ? option.label : value.toString()
  }

  function handleSetOpen(isOpen: boolean) {
    setOpen(isOpen)
  }

  async function getSelectOptions(text: string) {
    const options = await searchOptions(text)
    return options.slice(0, 250).map((option) => {
      const Icon = option.Icon || field.Icon
      return {
        children: (
          <OperandDropdownOption
            checked={(operand: TypeaheadOperandValue<ScalarValue>) =>
              operand.values?.includes(option.value)
            }
            Icon={(props) => <Icon {...props} />}
          >
            {option.label}
          </OperandDropdownOption>
        ),
        key: option.value,
        value: option.value,
      }
    })
  }

  function handleAddValue(newValue: ScalarValue) {
    onChange({ ...value, values: [...values, newValue] })
  }

  function handleRemoveValue(oldValue: ScalarValue) {
    const newValues = values.filter((value) => value !== oldValue)
    onChange({ ...value, values: newValues })
  }

  return (
    <Dropdown.Provider {...props} setOpen={handleSetOpen}>
      <OperandDropdownToggleButton
        className={className}
        icon={<field.Icon />}
        isInvalid={!isValid && !forceOpen}
        label={field.label}
        onRemove={onRemove}
      >
        {matchOption.label}{' '}
        {matchOption.hasValues && values
          ? values.map(getLabelFromValue).join(', ')
          : ''}
      </OperandDropdownToggleButton>

      <Dropdown.Menu
        className='tw-flex tw-flex-col tw-overflow-visible tw-space-y-2 tw-p-3'
        maxHeight={400}
      >
        <div className='tw-flex tw-gap-2 tw-items-center'>
          <Dropdown.Provider>
            <Dropdown.ToggleButton className='btn btn-input tw-w-60'>
              {matchOption.label}
            </Dropdown.ToggleButton>
            <Dropdown.Menu className='tw-p-3' sameWidth>
              {matchOptions.map((option, index) => (
                <Dropdown.Item
                  active={option === matchOption}
                  checked={option === matchOption}
                  key={index}
                  onClick={() => {
                    const { hasValues } = option
                    onChange({
                      ...value,
                      ...option.value,
                      values: hasValues ? values : undefined,
                    })
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Provider>

          {matchOption.hasValues && (
            <SearchInput
              autoFocus={values.length === 0}
              className='tw-w-64'
              disabled={options === undefined}
              getOptions={getSelectOptions}
              key={!options}
              minLength={0}
              noClearValueOnSelect
              onSelect={(option: SelectOption<ScalarValue>) => {
                const { value } = option
                if (values.includes(value)) {
                  handleRemoveValue(value)
                } else {
                  handleAddValue(value)
                }
              }}
              placeholder={`Search for ${field.label}...`}
            />
          )}
        </div>

        {matchOption.hasValues && values.length > 0 && (
          <div className='tw-flex-1 tw-flex tw-flex-col tw-overflow-auto'>
            {values.map((value, index) => (
              <div
                className='tw-flex tw-items-center tw-justify-between tw-text-gray-600 hover:tw-text-gray-800 tw-px-2 tw-py-2 tw-text-base tw-group'
                key={index}
              >
                {getLabelFromValue(value)}
                <RemoveIcon
                  className='tw-invisible group-hover:tw-visible'
                  onClick={() => {
                    handleRemoveValue(value)
                  }}
                />
              </div>
            ))}
          </div>
        )}

        <OperandDropdownFooter onClose={() => setOpen(false)} />
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
