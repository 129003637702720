import {
  TextOperandCondition,
  type TextOperandValue,
  isTextOperandValueValid,
} from '@blissbook/lib/expression'
// @ts-ignore: WIP imports
import { Dropdown, Input } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import {
  OperandDropdownFooter,
  OperandDropdownToggleButton,
} from './components'
import type { BaseOperandField } from './types'

type TextOperandConditionOption = {
  label: string
  value: {
    textCondition: TextOperandCondition
    isNot: boolean
  }
  noText?: boolean
}

export type TextOperandConditionOptionArgs = {
  allowEquals?: boolean
  allowExists?: boolean
}

export function getOptions({
  allowEquals,
  allowExists,
}: TextOperandConditionOptionArgs): TextOperandConditionOption[] {
  const options: TextOperandConditionOption[] = []

  options.push(
    {
      label: 'contains',
      value: {
        textCondition: TextOperandCondition.Contains,
        isNot: false,
      },
    },
    {
      label: 'does not contain',
      value: {
        textCondition: TextOperandCondition.Contains,
        isNot: true,
      },
    },
  )

  if (allowEquals) {
    options.push(
      {
        label: 'equals',
        value: {
          textCondition: TextOperandCondition.Equals,
          isNot: false,
        },
      },
      {
        label: 'does not equal',
        value: {
          textCondition: TextOperandCondition.Equals,
          isNot: true,
        },
      },
      {
        label: 'starts with',
        value: {
          textCondition: TextOperandCondition.StartsWith,
          isNot: false,
        },
      },
      {
        label: 'does not start with',
        value: {
          textCondition: TextOperandCondition.StartsWith,
          isNot: true,
        },
      },
      {
        label: 'ends with',
        value: {
          textCondition: TextOperandCondition.EndsWith,
          isNot: false,
        },
      },
      {
        label: 'does not end with',
        value: {
          textCondition: TextOperandCondition.EndsWith,
          isNot: true,
        },
      },
    )
  }

  if (allowExists) {
    options.push(
      {
        label: 'exists',
        value: {
          textCondition: TextOperandCondition.HasValue,
          isNot: false,
        },
        noText: true,
      },
      {
        label: 'does not exist',
        value: {
          textCondition: TextOperandCondition.HasValue,
          isNot: true,
        },
        noText: true,
      },
    )
  }

  return options
}

export type TextOperandField = BaseOperandField &
  Pick<TextOperandValue, 'field' | 'path' | 'type'> & {
    allowEquals?: boolean
    allowExists?: boolean
    defaultValue: TextOperandValue
  }

export type TextOperandInputProps = {
  className?: string
  field: TextOperandField
  forceOpen?: boolean
  isOpen: boolean
  onChange: (value: TextOperandValue) => void
  onRemove: () => void
  setOpen: (isOpen: boolean) => void
  value: TextOperandValue
}

export function TextOperandInput({
  className,
  field,
  forceOpen,
  onChange,
  onRemove,
  setOpen,
  value,
  ...props
}: TextOperandInputProps) {
  const activeValue = { ...field.defaultValue, ...value }
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null)
  const isValid = isTextOperandValueValid(value)

  const options = getOptions(field)
  const activeOption = options.find(
    (option) =>
      option.value.textCondition === activeValue.textCondition &&
      option.value.isNot === activeValue.isNot,
  )

  function handleSetOpen(isOpen: boolean) {
    setOpen(isOpen)
  }

  return (
    <Dropdown.Provider {...props} setOpen={handleSetOpen}>
      <OperandDropdownToggleButton
        className={className}
        icon={<field.Icon />}
        isInvalid={!isValid && !forceOpen}
        label={field.label}
        onRemove={onRemove}
      >
        {activeOption.label} {!activeOption.noText && value.text}
      </OperandDropdownToggleButton>

      <Dropdown.Menu className='tw-overflow-visible tw-p-3' maxHeight={400}>
        <div className='tw-flex tw-gap-2'>
          <Dropdown.Provider>
            <Dropdown.ToggleButton className='btn btn-input tw-w-40'>
              {activeOption.label}
            </Dropdown.ToggleButton>
            <Dropdown.Menu className='tw-p-3 tw-w-48'>
              {options.map((option, index) => (
                <Dropdown.Item
                  active={option === activeOption}
                  checked={option === activeOption}
                  className='tw-flex tw-items-center tw-justify-between'
                  key={index}
                  onClick={() => {
                    onChange({ ...value, ...option.value })
                    inputEl?.focus()
                  }}
                >
                  <div className='tw-flex-1 ellipsis'>{option.label}</div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Provider>

          {!activeOption.noText && (
            <Input
              autoFocus
              className='form-control tw-w-72'
              debounce={200}
              onChangeValue={(text: string) => {
                onChange({ ...value, text })
              }}
              onEnterKey={() => {
                setOpen(false)
              }}
              placeholder={`Enter ${field.label}`}
              ref={setInputEl}
              value={value.text}
            />
          )}
        </div>

        <OperandDropdownFooter onClose={() => setOpen(false)} />
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
