import { AnnotationUI } from './AnnotationUI'
import { AudienceExpressionUI } from './AudienceExpressionUI'
import { CommentArchiveUI } from './CommentArchive'
import { CommentUI } from './CommentUI'
import { HeadingsUI } from './HeadingsUI'
import { HistoryDropdownUI } from './HistoryDropdownUI'
import { IconsUI } from './IconsUI'
import { ImageUI } from './ImageUI'
import { IndentBlockUI } from './IndentBlockUI'
import { LinkUI } from './LinkUI'
import { MediaUI } from './MediaUI'
import { PdfUI } from './PdfUI'
import { ReadMoreUI } from './ReadMoreUI'
import { RevisionHistoryUI } from './RevisionHistoryUI'

export const uiPlugins = [
  AnnotationUI,
  AudienceExpressionUI,
  HeadingsUI,
  IconsUI,
  ImageUI,
  IndentBlockUI,
  LinkUI,
  MediaUI,
  PdfUI,
  ReadMoreUI,
]

export const uiCollabPlugins = [
  CommentUI,
  CommentArchiveUI,
  HistoryDropdownUI,
  RevisionHistoryUI,
]
