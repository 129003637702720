import { getMetadataKeyLabel } from '@blissbook/lib/metadata'
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faBriefcase } from '@fortawesome/pro-regular-svg-icons/faBriefcase'
import { faBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding'
import { faCircleDollar } from '@fortawesome/pro-regular-svg-icons/faCircleDollar'
import { faCity } from '@fortawesome/pro-regular-svg-icons/faCity'
import { faEnvelopeOpenDollar } from '@fortawesome/pro-regular-svg-icons/faEnvelopeOpenDollar'
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe'
import { faLocationPin } from '@fortawesome/pro-regular-svg-icons/faLocationPin'
import { faMoneyCheckDollarPen } from '@fortawesome/pro-regular-svg-icons/faMoneyCheckDollarPen'

import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import React from 'react'

const iconsByLabel: Record<string, IconDefinition> = {
  City: faCity,
  'Company Name': faBuilding,
  Country: faGlobe,
  'Cost Center': faCircleDollar,
  'Employment Type': faBriefcase,
  'FLSA Status': faMoneyCheckDollarPen,
  'Home City': faCity,
  'Home Country': faGlobe,
  'Home State': faLocationPin,
  Location: faLocationPin,
  'Pay Frequency': faEnvelopeOpenDollar,
  'Pay Period': faCircleDollar,
  State: faLocationPin,
  'Work City': faCity,
  'Work Country': faGlobe,
  'Work State': faLocationPin,
}

function resolveIcon(metadataKey: string): IconDefinition {
  const label = getMetadataKeyLabel(metadataKey)
  return iconsByLabel[label] || faBriefcase
}

export type MetadataIconProps = Omit<FontAwesomeIconProps, 'icon'> & {
  metadataKey: string
}

export function MetadataIcon({ metadataKey, ...props }: MetadataIconProps) {
  const icon = resolveIcon(metadataKey)
  return <FontAwesomeIcon {...props} icon={icon} />
}
