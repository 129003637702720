import {
  type RootExpression,
  emptyRootExpression,
} from '@blissbook/lib/expression'
import { useQueryParam } from '@blissbook/ui/hooks/useQueryParam'
import {
  decodeBase64JSON,
  encodeBase64JSON,
} from '@blissbook/ui/lib/encodeBase64JSON'
import { useCallback, useMemo } from 'react'

/** Hook to store the expressions in a query parameter */
export function useExpressionState(defaultExpression = emptyRootExpression) {
  const [value, setValue] = useQueryParam('expression')

  const expression = useMemo(() => {
    if (value === null) return emptyRootExpression
    return decodeBase64JSON(value, defaultExpression)
  }, [defaultExpression, value])

  /** Set a new expression in the URL. Use `null` for an empty expresion, to override a saved report */
  const emptyValue: null | undefined =
    defaultExpression === emptyRootExpression ? undefined : null
  const setExpression = useCallback(
    (newExpression: RootExpression | undefined) => {
      if (!newExpression) return setValue(undefined)

      const isEmpty = newExpression.expressions.length === 0
      const newValue = isEmpty ? emptyValue : encodeBase64JSON(newExpression)
      return setValue(newValue)
    },
    [emptyValue, setValue],
  )

  return [expression, setExpression] as const
}
