import { cx } from '@emotion/css'
import React from 'react'
import type { BlissbookEditor } from '../BlissbookEditor'
import {
  type SelectionState,
  useSelectedState,
  useSelectionKey,
} from '../hooks'
import { CKEditorAudienceExpressionsView } from './CKEditorAudienceExpressionsView'
import {
  AddLinkToolbar,
  EditLinkToolbar,
  EditReadMoreToolbar,
  EditTableToolbar,
  type InlineToolbarType,
  ResizeToolbar,
} from './toolbar'

const resizeNames = ['pdf', 'imageBlock', 'imageInline', 'media']

function getToolbar({
  editor,
  toolbarType,
  selected,
}: {
  editor: BlissbookEditor
  toolbarType: InlineToolbarType | undefined
  selected: SelectionState
}) {
  if (toolbarType === 'link') {
    return selected.linkHref ? (
      <EditLinkToolbar editor={editor} initialValue={selected.linkHref} />
    ) : (
      <AddLinkToolbar editor={editor} />
    )
  }

  if (selected.linkHref) {
    return <EditLinkToolbar editor={editor} initialValue={selected.linkHref} />
  }

  const { element } = selected
  switch (element?.name) {
    case 'readMore':
      return <EditReadMoreToolbar editor={editor} element={element} />
    case 'table':
      return <EditTableToolbar editor={editor} element={element} />
  }

  if (resizeNames.includes(selected.element?.name)) {
    return <ResizeToolbar editor={editor} element={selected.element} />
  }
}

export function CKEditorTools({
  className,
  editor,
  toolbarType,
}: {
  className?: string
  editor: BlissbookEditor
  toolbarType: InlineToolbarType | undefined
}) {
  const selected = useSelectedState(editor)
  const selectionKey = useSelectionKey(editor)
  const toolbar = getToolbar({ editor, toolbarType, selected })
  return (
    <div
      className={cx('tw-pointer-events-none tw-p-3', className)}
      key={selectionKey}
    >
      <CKEditorAudienceExpressionsView editor={editor} />
      {toolbar}
    </div>
  )
}
