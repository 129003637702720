import type { SavedSegmentsOperandValue } from '@blissbook/lib/expression'
import React from 'react'
import { useSavedSegments } from '../ExpressionContext'
import { getMatchOption } from './GroupsOperandInput'
import { SavedSegmentsFieldIcon } from './SavedSegmentsOperandInput'
import { MultipleValueOperandView, SingleValueOperandView } from './components'

export type SavedSegmentsOperandViewProps = {
  inline?: boolean
  value: SavedSegmentsOperandValue
}

export function SavedSegmentsOperandView({
  inline,
  value,
}: SavedSegmentsOperandViewProps) {
  const matchOption = getMatchOption(value)
  const savedSegments = useSavedSegments(value.ids)

  if (savedSegments.length < 2) {
    const { isNot } = matchOption.value
    const [savedSegment] = savedSegments
    return (
      <div className={inline ? 'tw-inline-flex tw-max-w-full' : 'tw-flex'}>
        <SingleValueOperandView
          icon={<SavedSegmentsFieldIcon />}
          inline={inline}
          isInvalid={!savedSegment}
          label='Segment'
        >
          {isNot ? 'is not' : 'is'} {savedSegment?.name}
        </SingleValueOperandView>
      </div>
    )
  }

  return (
    <MultipleValueOperandView
      icon={<SavedSegmentsFieldIcon />}
      label='Segments'
      values={savedSegments.map((ss) => ss.name)}
    >
      {matchOption.label}
    </MultipleValueOperandView>
  )
}
