import { useMouseDown } from '@blissbook/ui/hooks/useMouseDown'
import { Button, Dropdown, Link } from '@blissbook/ui/lib'
import { ChangeIcon } from '@blissbook/ui/lib/icons'
import { trimHash } from '@blissbook/ui/util/history'
import { useCurrentAnchorName } from '@blissbook/ui/util/hooks'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { scrollToHashAndReplace } from '@blissbook/ui/util/scroll'
import { useStore } from '@blissbook/ui/util/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React, { useState } from 'react'
import { useAnnotationsContext } from '../annotations'
import { initialState } from '../state'
import { translate } from '../translate'

const { toc } = initialState.handbook

const parseSectionId = (hash) => {
  const match = hash.match(/section[-_](\d+)/)
  if (match) return Number.parseInt(match[1])
}

export const getSectionId = (location) => {
  const hash = trimHash(location.hash)
  return hash && parseSectionId(hash)
}

export function setBookmark(bookmark) {
  scrollToHashAndReplace(bookmark, {
    delay: 500,
    setQuery: true,
  }).catch(logUIError)
}

export const Toc = React.memo(({ isOpen, setTocOpen }) => {
  const { annotationGroupsByBookmark } = useAnnotationsContext()
  const [node, setNode] = useState()
  const handbook = useStore('handbook')
  const otherHandbooks = useStore('otherHandbooks')

  // Lock or close the ToC on any mousedown
  useMouseDown(
    (event) => {
      if (!node) return
      const isOutside = !node.contains(event.target)
      const isSearchBar = event.target.closest('#desktop-search')
      if (setTocOpen && isOutside && !isSearchBar) setTocOpen(!isOutside)
    },
    [node],
  )

  // Keep track of the current section
  const currentAnchorName = useCurrentAnchorName()

  const onClickItem = (event, item) => {
    event.preventDefault()
    event.stopPropagation()
    setTocOpen(false)
    setBookmark(item.bookmark)
  }

  const onWheel = () => {
    setTocOpen(true)
  }

  return (
    <nav
      className={classnames('toc', { '-open': isOpen })}
      onWheel={onWheel}
      ref={setNode}
    >
      <header className='tw-flex tw-items-center tw-justify-between'>
        <Choose>
          <When condition={otherHandbooks.length > 0}>
            <div className='tw-relative tw-flex-1 tw-min-w-0'>
              <Dropdown.Provider>
                <Dropdown.ToggleButton
                  className='tw-flex tw-items-center tw-py-1'
                  css={{
                    '[data-icon]': {
                      transform: 'translateY(1px)',
                    },
                  }}
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    maxWidth: '100%',
                  }}
                >
                  <span className='ellipsis' title={handbook.name}>
                    {handbook.name}
                  </span>
                </Dropdown.ToggleButton>
                <Dropdown.Menu className='tw-w-full' maxHeight={200}>
                  <Link
                    className='dropdown-item'
                    href={'/'}
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    {translate('All documents')}
                  </Link>
                  <div className='dropdown-divider' />
                  {otherHandbooks.map((handbook) => {
                    return (
                      <Link
                        key={handbook.id}
                        className='dropdown-item ellipsis'
                        href={handbook.path}
                      >
                        {handbook.name}
                      </Link>
                    )
                  })}
                </Dropdown.Menu>
              </Dropdown.Provider>
            </div>
          </When>
          <Otherwise>
            <h4 className='ellipsis tw-my-0' title={handbook.name}>
              {handbook.name}
            </h4>
          </Otherwise>
        </Choose>

        <Button
          className='tw-flex tw-items-center btn-icon nav-close tw-ml-6'
          color='gray-600'
          onClick={() => setTocOpen(false)}
          aria-label={translate('Close Table of Contents')}
          title={translate('Close Table of Contents')}
        >
          <FontAwesomeIcon
            icon='angle-left'
            style={{ fontSize: 12, marginRight: 3 }}
          />
          <FontAwesomeIcon icon={['far', 'bars']} />
        </Button>
      </header>

      <ul className='sections list-unstyled'>
        {toc
          .filter((i) => !i.isHidden)
          .map((item, index) => (
            <li
              key={index}
              className={classnames('section', {
                nested: item.chapterId,
              })}
            >
              <a
                href={item.bookmark}
                className={classnames('bookmark', {
                  active: item.bookmark === currentAnchorName,
                })}
                onClick={(event) => onClickItem(event, item)}
              >
                {item.title}
                <If condition={annotationGroupsByBookmark.has(item.bookmark)}>
                  <ChangeIcon size={14} />
                </If>
              </a>
            </li>
          ))}
      </ul>
    </nav>
  )
})
