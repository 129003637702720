import { useHandbooksById } from '@blissbook/application/hooks'
import { useCallback, useMemo } from 'react'

function useOrderedHandbooks() {
  const handbooksById = useHandbooksById()
  return useMemo(() => {
    const handbooks = Object.values(handbooksById)
    return handbooks.sort((a, b) => a.name.localeCompare(b.name))
  }, [handbooksById])
}

export type FilterHandbooksArgs = {
  isPublished?: boolean
  limit: number
  search: string
}

async function filterHandbooks(
  handbooks: IHandbook[],
  { isPublished, limit, search }: FilterHandbooksArgs,
) {
  search = search.toLowerCase()

  const result: IHandbook[] = []
  for (const handbook of handbooks) {
    if (result.length >= limit) break
    if (isPublished && !handbook.publishedAt) continue
    if (handbook.name.toLowerCase().includes(search)) {
      result.push(handbook)
    }
  }
  return result
}

export type SearchHandbooksArgs = Omit<FilterHandbooksArgs, 'search'>

export function useSearchHandbooks(args: SearchHandbooksArgs) {
  const handbooks = useOrderedHandbooks()
  return useCallback(
    (search) => filterHandbooks(handbooks, { ...args, search }),
    [handbooks],
  )
}
