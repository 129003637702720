import type {
  Expression,
  OperandValue,
  RootExpression,
} from '@blissbook/lib/expression'
import { useMemo } from 'react'

export type TruncatedExpression = Expression & {
  truncatedCount?: number
}

export type TruncatedRootExpression = RootExpression & {
  expressions: TruncatedExpression[]
  truncatedCount?: number
}

function getConjunctionLineCount(index: number): number {
  return index === 0 ? 0 : 1
}

function getOperandLineCount(operand: OperandValue): number {
  switch (operand.type) {
    case 'groups': {
      const count = operand.ids.length
      return count > 1 ? count + 1 : count
    }
    case 'savedSegments': {
      const count = operand.ids.length
      return count > 1 ? count + 1 : count
    }
    default:
      return 1
  }
}

export function truncateRootExpression(
  rootExpression: RootExpression,
  maxLines: number,
): TruncatedRootExpression {
  let usedLineCount = 0

  const truncatedRootExpression: TruncatedRootExpression = {
    ...rootExpression,
    expressions: [],
  }
  for (
    let expressionIndex = 0;
    expressionIndex < rootExpression.expressions.length;
    expressionIndex++
  ) {
    const expression = rootExpression.expressions[expressionIndex]

    const truncatedExpression: TruncatedExpression = {
      ...expression,
      operands: [],
    }
    for (
      let operandIndex = 0;
      operandIndex < expression.operands.length;
      operandIndex++
    ) {
      const operand = expression.operands[operandIndex]
      usedLineCount +=
        getOperandLineCount(operand) + getConjunctionLineCount(operandIndex)
      truncatedExpression.operands.push(operand)
      if (usedLineCount >= maxLines) {
        truncatedExpression.truncatedCount =
          expression.operands.length - truncatedExpression.operands.length
        break
      }
    }

    truncatedRootExpression.expressions.push(truncatedExpression)
    usedLineCount += getConjunctionLineCount(expressionIndex)
    if (usedLineCount >= maxLines) {
      truncatedRootExpression.truncatedCount =
        rootExpression.expressions.length -
        truncatedRootExpression.expressions.length
      break
    }
  }

  return truncatedRootExpression
}

export function useTruncatedRootExpression(
  rootExpression: RootExpression,
  maxLines: number,
): TruncatedRootExpression {
  return useMemo(
    () => truncateRootExpression(rootExpression, maxLines),
    [rootExpression, maxLines],
  )
}
