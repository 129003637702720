import type { AudienceRootExpression } from '@blissbook/lib/expression'
import {
  Command,
  type DocumentFragment,
  type DocumentSelection,
  type Element,
} from 'ckeditor5'
import {
  decodeBase64JSON,
  encodeBase64JSON,
} from '../../../lib/encodeBase64JSON'

export const audienceExpressionAttributeKey = 'audienceExpression'

function blockDepth(block: Element | DocumentFragment) {
  let depth = 0
  while (block.parent) {
    block = block.parent
    depth++
  }
  return depth
}

function areBlocksSameDepth(blocks: Element[]) {
  const depths = blocks.map(blockDepth)
  return depths.every((depth) => depth === depths[0])
}

function getSelectedBlocks(selection: DocumentSelection) {
  const selectedBlock = selection.getSelectedElement()
  if (selectedBlock) return [selectedBlock]
  return [...selection.getSelectedBlocks()]
}

export function getSelectedAudienceExpression(selection: DocumentSelection) {
  const blocks = getSelectedBlocks(selection)
  return getAudienceExpression(blocks)
}

export function getAudienceExpressionValue(blocks: Element[]) {
  if (blocks.length === 0) return

  const [firstBlock] = blocks
  const value = firstBlock.getAttribute(audienceExpressionAttributeKey) as
    | string
    | undefined

  for (const block of blocks) {
    if (block.getAttribute(audienceExpressionAttributeKey) !== value) {
      return
    }
  }

  return value
}

export function getAudienceExpression(blocks: Element[]) {
  const value = getAudienceExpressionValue(blocks)
  if (!value) return
  return decodeBase64JSON<AudienceRootExpression>(value)
}

export class SetAudienceExpressionCommand extends Command {
  execute(options: {
    audienceExpression: AudienceRootExpression
    blocks?: Element[]
  }) {
    const { editor } = this
    const { model } = editor
    const { selection } = model.document
    const { audienceExpression } = options
    const value = encodeBase64JSON(audienceExpression)

    model.change((writer) => {
      const blocks = options.blocks ?? getSelectedBlocks(selection)
      for (const block of blocks) {
        if (value) {
          writer.setAttribute(audienceExpressionAttributeKey, value, block)
        } else {
          writer.removeAttribute(audienceExpressionAttributeKey, block)
        }
      }
    })
  }

  refresh() {
    const { selection } = this.editor.model.document
    const blocks = getSelectedBlocks(selection)
    this.isEnabled = areBlocksSameDepth(blocks)
    this.value = getAudienceExpression(blocks)
  }
}
