import type { RootExpression } from '@blissbook/lib/expression'
import { Button } from '@blissbook/ui/lib'
import pluralize from 'pluralize'
import React, { Fragment } from 'react'
import {
  type ExpressionContextProps,
  ExpressionContextProvider,
} from './ExpressionContext'
import { ExpressionView } from './ExpressionView'
import { useTruncatedRootExpression } from './TruncatedRootExpression'

export type RootExpressionViewProps = Partial<ExpressionContextProps> & {
  inline?: boolean
  maxLines?: number
  value: RootExpression
}

export function RootExpressionView({
  groupsById,
  handbooksById,
  inline,
  maxLines = 24,
  peopleById,
  savedSegmentsById,
  value,
}: RootExpressionViewProps) {
  const hideParentheses =
    value.expressions.length === 1 ||
    value.expressions.every((expression) => expression.operands.length === 1)
  const rootExpression = useTruncatedRootExpression(value, maxLines)

  return (
    <ExpressionContextProvider
      groupsById={groupsById}
      handbooksById={handbooksById}
      peopleById={peopleById}
      savedSegmentsById={savedSegmentsById}
    >
      <div
        className={
          inline
            ? 'tw-inline-block tw-space-x-2 tw-max-w-full'
            : 'tw-flex tw-flex-col tw-gap-1'
        }
      >
        {rootExpression.expressions.map((expression, index) => (
          <Fragment key={index}>
            {index > 0 && <div>{value.conjunction}</div>}
            <ExpressionView
              hideParentheses={hideParentheses}
              inline={inline}
              value={expression}
            />
          </Fragment>
        ))}

        <If condition={rootExpression.truncatedCount > 0}>
          <div className={inline ? 'tw-inline-flex ellipsis' : ''}>
            ...{value.conjunction}{' '}
            {pluralize('other', rootExpression.truncatedCount, true)}
          </div>
        </If>
      </div>
    </ExpressionContextProvider>
  )
}
