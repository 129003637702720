import {
  type PeopleSource,
  useMetadataValuesLazyQuery,
} from '@blissbook/application/graph'
import { useCallback } from 'react'

export function useSearchMetadataValues(source?: PeopleSource) {
  const [query] = useMetadataValuesLazyQuery()

  return useCallback(
    async function searchMetadataValues(metadataKey: string, text: string) {
      const lowerText = text.toLowerCase()
      const res = await query({
        variables: {
          metadataKey,
          source,
        },
      })
      return res.data?.metadataValues.filter((value) =>
        value.toLowerCase().includes(lowerText),
      )
    },
    [source],
  )
}
