import { usePeople } from '@blissbook/application/hooks'
import type { Person } from '@blissbook/ui/lib/expression'
import { useEffect, useMemo, useState } from 'react'

export function usePeopleById(personIdSet: Set<number>) {
  const [peopleById, setPeopleById] = useState(new Map<number, Person>())

  // Prevent unnecesary fetches using a string key
  const personIdsKey = [...personIdSet].join(',')
  const personIds = useMemo(() => [...personIdSet], [personIdsKey])
  const people = usePeople({ ids: personIds, showArchived: true }, [personIds])

  // Whenever the people change, accumlate them into a map
  useEffect(() => {
    if (!people) return

    const newPeopleById = new Map<number, Person>(peopleById)
    for (const person of people) {
      newPeopleById.set(person.id, person)
    }
    setPeopleById(newPeopleById)
  }, [people])

  return peopleById
}
