import type { RootExpression } from '@blissbook/lib/expression'
import { useMemo } from 'react'

export type ExpressionIds = {
  groupIds: Set<number>
  handbookIds: Set<number>
  personIds: Set<number>
  savedSegmentIds: Set<number>
}

export function getExpressionIds(value: RootExpression): ExpressionIds {
  const groupIds = new Set<number>()
  const handbookIds = new Set<number>()
  const personIds = new Set<number>()
  const savedSegmentIds = new Set<number>()
  const result = {
    groupIds,
    handbookIds,
    personIds,
    savedSegmentIds,
  }
  if (!value) return result

  for (const expression of value.expressions) {
    for (const operand of expression.operands) {
      switch (operand.type) {
        case 'groups':
          operand.ids.forEach(groupIds.add, groupIds)
          break
        case 'handbooks':
          operand.ids.forEach(handbookIds.add, handbookIds)
          break
        case 'managers':
          operand.ids.forEach(personIds.add, personIds)
          break
        case 'people':
          operand.ids?.forEach(personIds.add, personIds)
          break
        case 'savedSegments':
          operand.ids.forEach(savedSegmentIds.add, savedSegmentIds)
          break
      }
    }
  }

  return result
}

export function useExpressionIds(value: RootExpression): ExpressionIds {
  return useMemo(() => getExpressionIds(value), [value])
}
