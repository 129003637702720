import type { OperandValue } from '@blissbook/lib/expression'
import React, { type ReactNode, createContext, useContext } from 'react'

const OperandContext = createContext<OperandValue>(undefined)

export type OperandContextProviderProps = {
  children: ReactNode
  value: OperandValue
}

export const OperandContextProvider = ({
  children,
  value,
}: OperandContextProviderProps) => {
  return (
    <OperandContext.Provider value={value}>{children}</OperandContext.Provider>
  )
}

export function useOperandContext() {
  return useContext(OperandContext)
}
