import { DateOperandTimeUnit } from '@blissbook/application/graph'
import {
  DateOperandCondition,
  OperandType,
  TextOperandCondition,
} from '@blissbook/lib/expression'
import { type Property, propertyTypes } from '@blissbook/lib/properties'
import { faAsterisk } from '@fortawesome/pro-regular-svg-icons/faAsterisk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { type DocumentStatus, documentStatuses } from '../../document'
import type {
  HandbooksOperandField,
  OperandField,
  TypeaheadSelectOperandOption,
} from '../operands'
import { type BuildPeopleFieldArgs, buildPeopleField } from './peopleFields'

export const nameField: OperandField = {
  type: OperandType.Text,
  field: 'name',
  label: 'Name',
  Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'text']} />,
  allowEquals: true,
  allowExists: true,
  defaultValue: {
    field: 'name',
    type: OperandType.Text,
    textCondition: TextOperandCondition.Contains,
    isNot: false,
    text: '',
  },
}

export const textField: OperandField = {
  type: OperandType.Text,
  field: 'content',
  Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'memo']} />,
  label: 'In-Policy Text',
  defaultValue: {
    field: 'content',
    type: OperandType.Text,
    textCondition: TextOperandCondition.Contains,
    isNot: false,
    text: '',
  },
}

export type BuildStatusFieldArgs = {
  /** Whether or not to show workflow fields */
  isPolicyWorkflowEnabled: boolean
}

export function buildStatusField({
  isPolicyWorkflowEnabled,
}: BuildStatusFieldArgs): OperandField {
  const statuses = isPolicyWorkflowEnabled
    ? documentStatuses
    : documentStatuses.filter((status) => status.key !== 'inReview')

  const mapStatusToOption = (
    status: DocumentStatus,
  ): TypeaheadSelectOperandOption => ({
    Icon: (props) => <FontAwesomeIcon {...props} icon={status.icon} />,
    label: status.label,
    value: status.key,
  })

  return {
    type: OperandType.Select,
    field: 'status',
    label: 'Phase',
    Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'circle']} />,
    inputType: 'typeahead',
    allowEvery: true,
    defaultValue: {
      field: 'status',
      type: OperandType.Select,
      isEvery: false,
      isNot: false,
      values: [],
    },
    searchOptions: async (text) =>
      statuses
        .filter((status) => status.label.toLowerCase().includes(text))
        .map(mapStatusToOption),
    useOptions(values) {
      return statuses
        .filter((status) => values?.includes(status.key))
        .map(mapStatusToOption)
    },
  }
}

export const annotatedOptions = [
  {
    id: 'hasComments',
    label: 'Comments',
  },
  {
    id: 'hasSuggestions',
    label: 'Suggestions',
  },
  {
    id: 'hasAnnotations',
    label: 'Annotations',
  },
  {
    id: 'hasPublishedAnnotations',
    label: 'Published Annotations',
  },
]

export const annotatedField: OperandField = {
  type: OperandType.Select,
  field: 'annotated',
  Icon: (props) => <FontAwesomeIcon {...props} icon={faAsterisk} />,
  label: 'Annotated',
  heading: 'Policies with',
  inputType: 'checkbox',
  options: annotatedOptions,
  defaultValue: {
    field: 'annotated',
    type: OperandType.Select,
    isEvery: false,
    isNot: false,
    values: ['hasComments', 'hasSuggestions'],
  },
}

export type BuildHandbooksFieldArgs = Pick<
  HandbooksOperandField,
  'searchHandbooks'
>

export const HandbooksFieldIcon: HandbooksOperandField['Icon'] = (props) => (
  <FontAwesomeIcon {...props} icon={['far', 'file-invoice']} />
)

export function buildHandbooksField(
  args: BuildHandbooksFieldArgs,
): HandbooksOperandField {
  return {
    ...args,
    type: OperandType.Handbooks,
    label: 'Documents',
    Icon: HandbooksFieldIcon,
    defaultValue: {
      type: OperandType.Handbooks,
      isEvery: false,
      isNot: false,
      ids: [],
    },
  }
}
const lastPublishedField: OperandField = {
  type: OperandType.Date,
  field: 'lastPublishedAt',
  Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'rocket']} />,
  label: 'Last Published',
  defaultValue: {
    field: 'lastPublishedAt',
    type: OperandType.Date,
    dateCondition: DateOperandCondition.LessThanOrEqualToDaysAgo,
    days: 0,
    timeUnit: DateOperandTimeUnit.Days,
  },
}

const updatedAtField: OperandField = {
  type: OperandType.Date,
  field: 'updatedAt',
  Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'edit']} />,
  label: 'Last Modified',
  defaultValue: {
    field: 'updatedAt',
    type: OperandType.Date,
    dateCondition: DateOperandCondition.LessThanOrEqualToDaysAgo,
    days: 0,
    timeUnit: DateOperandTimeUnit.Days,
  },
}

function buildDatePropertyField(property: Property): OperandField {
  const { icon } = propertyTypes[property.type]
  return {
    type: OperandType.Date,
    field: 'property',
    path: property.id,
    Icon: (props) => <FontAwesomeIcon {...props} icon={icon} />,
    label: property.label,
    isFuture: true,
    showOverdue: true,
    defaultValue: {
      field: 'property',
      path: property.id,
      type: OperandType.Date,
      dateCondition: DateOperandCondition.LessThanOrEqualToDaysFromNow,
      days: 0,
      timeUnit: DateOperandTimeUnit.Days,
    },
  }
}

function buildPersonPropertyField(
  property: Property,
  args: BuildPeopleFieldArgs,
): OperandField {
  return buildPeopleField(args, {
    field: 'property',
    path: property.id,
    label: property.label,
    allowAny: true,
    allowEvery: property.allowMultiple,
  })
}

function buildSelectPropertyField(property: Property): OperandField {
  const { options } = property
  const { icon } = propertyTypes[property.type]
  return {
    type: OperandType.Select,
    field: 'property',
    path: property.id,
    label: property.label,
    Icon: (props) => <FontAwesomeIcon {...props} icon={icon} />,
    inputType: 'typeahead',
    allowAny: true,
    allowEvery: property.allowMultiple,
    defaultValue: {
      field: 'property',
      path: property.id,
      type: OperandType.Select,
      isEvery: false,
      isNot: false,
      values: [],
    },
    async searchOptions(text) {
      text = text.toLowerCase()
      return options
        .filter((option) => option.label.toLowerCase().includes(text))
        .map((option) => ({
          label: option.label,
          value: option.id,
        }))
    },
    useOptions: (values?: string[]) => {
      return values?.map((value) => {
        const option = options.find((option) => option.id === value)
        const label = option?.label ?? value
        return { label, value }
      })
    },
  }
}

function buildTextPropertyField(property: Property): OperandField {
  const { icon } = propertyTypes[property.type]
  return {
    type: OperandType.Text,
    field: 'property',
    path: property.id,
    Icon: (props) => <FontAwesomeIcon {...props} icon={icon} />,
    label: property.label,
    allowEquals: true,
    defaultValue: {
      field: 'property',
      path: property.id,
      type: OperandType.Text,
      textCondition: TextOperandCondition.Contains,
      isNot: false,
      text: '',
    },
  }
}

export type BuildPropertyFieldArgs = BuildPeopleFieldArgs

export function buildPropertyField(
  property: Property,
  args: BuildPropertyFieldArgs,
): OperandField {
  switch (property.type) {
    case 'date':
      return buildDatePropertyField(property)
    case 'person':
      return buildPersonPropertyField(property, args)
    case 'select':
      return buildSelectPropertyField(property)
    case 'text':
      return buildTextPropertyField(property)
  }
}

export type GetDocumentsFieldsArgs = Partial<
  BuildHandbooksFieldArgs & BuildStatusFieldArgs & BuildPeopleFieldArgs
> & {
  properties: Property[]
}

export function getDocumentsFields({
  isPolicyWorkflowEnabled,
  properties,
  searchHandbooks,
  searchPeople,
}: GetDocumentsFieldsArgs): OperandField[] {
  const fields = []

  fields.push(nameField, textField)

  const statusField = buildStatusField({ isPolicyWorkflowEnabled })
  fields.push(statusField, annotatedField)

  if (searchHandbooks) {
    const handbooksField = buildHandbooksField({ searchHandbooks })
    fields.push(handbooksField)
  }

  for (const property of properties) {
    const field = buildPropertyField(property, { searchPeople })
    if (field) fields.push(field)
  }

  fields.push(updatedAtField, lastPublishedField)

  return fields
}

export function useDocumentsFields(
  args: GetDocumentsFieldsArgs,
  deps: any[] = [],
) {
  return useMemo(() => getDocumentsFields(args), deps)
}
