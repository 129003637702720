import {
  PeopleSource,
  useMetadataKeys,
  useSavedSegments,
} from '@blissbook/application/graph'
import { useOrganization } from '@blissbook/application/hooks'
import type { OperandField } from '@blissbook/ui/lib/expression'
import {
  type GetPeopleFieldsArgs,
  usePeopleFields,
} from '@blissbook/ui/lib/expression/fields'
import { useSearchGroupNames } from './useSearchGroupNames'
import { useSearchGroups } from './useSearchGroups'
import { useSearchHandbooks } from './useSearchHandbooks'
import { useSearchMetadataValues } from './useSearchMetadataValues'
import { useSearchPeople } from './useSearchPeople'
import { useSearchSavedSegments } from './useSearchSavedSegments'

export type UsePeopleExpressionFieldsArgs = Pick<
  GetPeopleFieldsArgs,
  | 'hideLevelField'
  | 'lastNotifiedField'
  | 'otherFields'
  | 'showComplianceStatus'
> & {
  hideManagers?: boolean
  peopleSource?: PeopleSource
  showEmployeeMetadata?: boolean
  showHandbooks?: boolean
  showSavedSegments?: boolean
}

export function usePeopleExpressionFields({
  hideLevelField,
  hideManagers,
  peopleSource = PeopleSource.People,
  showEmployeeMetadata,
  showHandbooks,
  showSavedSegments,
  ...args
}: UsePeopleExpressionFieldsArgs = {}): OperandField[] {
  const {
    customSsoIdPeopleCount,
    employeeIdPeopleCount,
    lastHiredOnPeopleCount,
    managersCount,
  } = useOrganization()
  const metadataKeys = useMetadataKeys(peopleSource)
  const searchGroupNames = useSearchGroupNames(peopleSource)
  const searchMetadataValues = useSearchMetadataValues(peopleSource)

  const hasManagers = managersCount > 0
  const showManagers = hasManagers && !hideManagers

  const savedSegments = useSavedSegments()
  const hasSavedSegments = savedSegments.length > 0

  const searchHandbooks = useSearchHandbooks({ isPublished: true, limit: 250 })
  const searchGroups = useSearchGroups({ limit: 250 })
  const searchManagers = useSearchPeople({ isManager: true, limit: 250 })
  const searchSavedSegments = useSearchSavedSegments({ limit: 250 })

  return usePeopleFields(
    {
      ...args,
      customSsoIdPeopleCount,
      employeeIdPeopleCount,
      hideLevelField: !hasManagers || hideLevelField,
      lastHiredOnPeopleCount,
      metadataKeys: showEmployeeMetadata ? metadataKeys : undefined,
      searchGroups:
        peopleSource === PeopleSource.People ? searchGroups : undefined,
      searchGroupNames:
        peopleSource === PeopleSource.HrisPeople ? searchGroupNames : undefined,
      searchHandbooks: showHandbooks ? searchHandbooks : undefined,
      searchManagers: showManagers ? searchManagers : undefined,
      searchMetadataValues,
      searchSavedSegments:
        showSavedSegments && hasSavedSegments ? searchSavedSegments : undefined,
    },
    [
      customSsoIdPeopleCount,
      employeeIdPeopleCount,
      hasManagers,
      hideLevelField,
      lastHiredOnPeopleCount,
      metadataKeys,
      peopleSource,
      savedSegments,
      searchGroups,
      searchHandbooks,
      searchSavedSegments,
      showEmployeeMetadata,
      showManagers,
      showSavedSegments,
      showHandbooks,
      ...Object.values(args),
    ],
  )
}
