import { Plugin, Widget, toWidget, toWidgetEditable } from 'ckeditor5'
import { UnwrapReadMoreCommand } from './UnwrapReadMoreCommand'
import { WrapInReadMoreCommand } from './WrapInReadMoreCommand'

export class ReadMoreEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    this.editor.commands.add(
      'wrapInReadMore',
      new WrapInReadMoreCommand(this.editor),
    )
    this.editor.commands.add(
      'unwrapReadMore',
      new UnwrapReadMoreCommand(this.editor),
    )
  }

  _defineSchema() {
    const { schema } = this.editor.model

    schema.register('readMore', {
      allowWhere: '$block',
      isObject: true,
    })

    schema.register('readMoreContent', {
      allowIn: 'readMore',
      allowContentOf: '$root',
      isLimit: true,
    })

    schema.addChildCheck((context, childDefinition) => {
      if (
        context.endsWith('readMoreContent') &&
        childDefinition.name === 'readMore'
      ) {
        return false
      }
    })
  }

  _defineConverters() {
    const { conversion } = this.editor

    // <readMore> converters
    conversion.for('upcast').elementToElement({
      model: 'readMore',
      view: {
        name: 'div',
        classes: 'read-more',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'readMore',
      view: {
        name: 'div',
        classes: 'read-more',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'readMore',
      view: (_modelElement, { writer: viewWriter }) => {
        const div = viewWriter.createContainerElement('div', {
          class: 'read-more',
        })

        return toWidget(div, viewWriter, {
          hasSelectionHandle: true,
          label: 'Read More',
        })
      },
    })

    // <readMoreContent> converters
    conversion.for('upcast').elementToElement({
      model: 'readMoreContent',
      view: {
        name: 'div',
        classes: 'read-more-content',
      },
    })
    conversion.for('dataDowncast').elementToElement({
      model: 'readMoreContent',
      view: {
        name: 'div',
        classes: 'read-more-content',
      },
    })
    conversion.for('editingDowncast').elementToElement({
      model: 'readMoreContent',
      view: (_modelElement, { writer: viewWriter }) => {
        // Note: You use a more specialized createEditableElement() method here.
        const div = viewWriter.createEditableElement('div', {
          class: 'read-more-content',
        })

        return toWidgetEditable(div, viewWriter)
      },
    })
  }
}
