import { excludeSearchWords } from '@blissbook/common/search'
import type { BlissbookTocItem } from '@blissbook/lib/blissbook'
import flatMap from 'lodash/flatMap'
import sortBy from 'lodash/sortBy'
import { initialState } from '../state'
const { toc } = initialState.handbook

const isExclude = (word: string) => excludeSearchWords.includes(word)

// Constants
export const SECTION_SELECTOR = 'article.blissbook-branded section'
const SEARCH_SELECTOR = 'h1,h2,h3,h4,h5,h6,p'
const RESULT_TYPES = ['chapter', 'policy', 'section', 'text'] as const
type ResultType = (typeof RESULT_TYPES)[number]

type HandbookSearchIndex = {
  el: HTMLElement
  type: ResultType
  tocItem: BlissbookTocItem
  text: string
  title: string
  tooltip?: string
}

export type SearchResult = HandbookSearchIndex & {
  index: number
  instance: number
}

function getChapterFromTocItem(tocItem: BlissbookTocItem) {
  const { chapterId } = tocItem
  if (!chapterId) return
  return toc.find((item) => item.sectionId === chapterId)
}

function getSectionFromTocItem(tocItem: BlissbookTocItem): {
  el: HTMLElement
  type: ResultType
} {
  const { documentId, sectionId } = tocItem
  if (documentId) {
    return {
      el: document.querySelector<HTMLElement>(
        `${SECTION_SELECTOR}[data-document-id="${documentId}"]`,
      ),
      type: 'policy',
    }
  }
  if (sectionId) {
    const isChapter = toc.some((item) => item.chapterId === tocItem.sectionId)
    return {
      el: document.querySelector<HTMLElement>(
        `${SECTION_SELECTOR}[data-id="${sectionId}"]`,
      ),
      type: isChapter ? 'chapter' : 'section',
    }
  }
}

// Map the section element to search data
function getTocItemResults(
  tocItem: BlissbookTocItem,
  results: HandbookSearchIndex[] = [],
): HandbookSearchIndex[] {
  const { title } = tocItem
  const chapter = getChapterFromTocItem(tocItem)
  const section = getSectionFromTocItem(tocItem)
  if (!section) return results

  // Chapter or Section
  if (title) {
    results.push({
      ...section,
      tocItem,
      text: title,
      title,
      tooltip: chapter?.title,
    })
  }

  // Text
  const $resultEls = $(section.el).find(SEARCH_SELECTOR)
  $resultEls.each((_index, resultEl) => {
    // Exclude title text. Already taken care of above
    const isTitle =
      $(resultEl).hasClass('section-title') ||
      $(resultEl).parent().hasClass('section-title')
    if (isTitle) return

    // Must have _some_ text
    const text = $(resultEl).text().replace(/\s+/g, ' ')
    if (!text) return

    results.push({
      el: resultEl,
      tocItem,
      text,
      title,
      tooltip: chapter ? `${chapter.title} > ${title}` : title,
      type: 'text',
    })
  })

  return results
}

// Build the search index
function buildSearchIndex() {
  const results: HandbookSearchIndex[] = []
  toc.forEach((item) => getTocItemResults(item, results))
  return sortBy(results, (result) => RESULT_TYPES.indexOf(result.type))
}

// Index the entire handbook
let searchIndex: HandbookSearchIndex[]
function getSearchIndex() {
  searchIndex = searchIndex || buildSearchIndex()
  return searchIndex
}

// Determine the results for this search
export function getResults(search: string) {
  if (!search || search.length < 2) return []
  // Build the index, if we haven't already
  const searchIndex = getSearchIndex()
  // Perform the search
  search = search.toLowerCase()
  return flatMap(searchIndex, (entry) => {
    // Find all the matches
    const indexes = []
    const text = entry.text.toLowerCase()
    let index
    while (index !== -1) {
      index = text.indexOf(search, index + 1)
      if (index !== -1) indexes.push(index)
    }

    // Map all the matches
    return indexes.map(
      (index, instance) =>
        ({
          ...entry,
          index,
          instance,
        }) as SearchResult,
    )
  })
}

export const renderResultHtml = (result: SearchResult, search: string) => {
  const { text } = result

  // Bold the text
  let start = result.index
  let end = start + search.length
  let html = [
    text.substring(0, start),
    '<strong>',
    text.substring(start, end),
    '</strong>',
    text.substring(end, text.length),
  ].join('')

  // If this is text, center it
  if (result.type === 'text') {
    // Find the start and stop indexes
    const words = html.split(' ')
    words.forEach((word, index) => {
      if (word.indexOf('<strong>') !== -1) start = index
      if (word.indexOf('</strong>') !== -1) end = index
    })

    // Determine indexes
    start = Math.max(start - 1, 0)
    end = Math.min(end + 1, words.length - 1)
    if (isExclude(words[start])) start = Math.max(start - 1, 0)
    if (isExclude(words[end])) end = Math.min(end + 1, words.length - 1)

    // Update the html
    const subset = words.slice(start, end + 1)
    if (start > 0) subset.unshift('...')
    if (end < words.length - 1) subset.push('...')
    html = subset.join(' ')
  }

  return html
}
