import type { BlissbookSection } from '@blissbook/lib/blissbook'
import type { FontDataModel } from '@blissbook/lib/models'
import { logUIError } from '@blissbook/ui/util/integrations/sentry'
import { produce } from 'immer'
import { EventEmitter } from '../EventEmitter'
import type { HandbookDataModel } from './HandbookDataModel'
import { HandbookSectionDataModel } from './HandbookSectionDataModel'
import type {
  DocumentNodeRemoveInput,
  DocumentRefNodeUpdateInput,
  DocumentRefsAddInput,
  HandbookAcknowledgementSetAttributesInput,
  HandbookAcknowledgementSetFragmentInput,
  HandbookBrandingSetAttributesInput,
  HandbookBrandingSetCssAttributesInput,
  HandbookBrandingSetCustomHtmlAttributesInput,
  HandbookContactSetFragmentInput,
  HandbookNodeMoveInput,
  HandbookSectionAddInput,
  HandbookSectionClearDirtyInput,
  HandbookSectionCloneInput,
  HandbookSectionCloneResult,
  HandbookSectionConvertToPoliciesInput,
  HandbookSectionConvertToPoliciesResult,
  HandbookSectionEntryAddInput,
  HandbookSectionEntryAddResult,
  HandbookSectionEntryMoveInput,
  HandbookSectionEntryMoveResult,
  HandbookSectionEntryRemoveInput,
  HandbookSectionEntryRemoveResult,
  HandbookSectionEntrySetAttributesInput,
  HandbookSectionEntrySetAttributesResult,
  HandbookSectionEntrySetFragmentInput,
  HandbookSectionEntrySetFragmentResult,
  HandbookSectionMoveToHandbookInput,
  HandbookSectionRemoveInput,
  HandbookSectionSetAttributesInput,
  HandbookSectionSetAttributesResult,
  HandbookSectionSetFragmentInput,
  HandbookSectionSetFragmentResult,
  HandbookSectionSetSettingsInput,
  HandbookSectionSetSettingsResult,
  HandbookSetSettingsInput,
  HandbookSetSettingsResult,
  HandbookTransaction,
  RevertDocumentRefsInput,
  RevertDocumentRefsResult,
} from './HandbookTransaction'

type HandbookEditorImpl = {
  // Assets
  archiveFont: (fontId: number) => Promise<void>

  // Handbook-Level
  moveNode: (input: HandbookNodeMoveInput) => Promise<void>
  setHandbookAcknowledgementAttrs: (
    input: HandbookAcknowledgementSetAttributesInput,
  ) => Promise<void>
  setHandbookAcknowledgementFragment: (
    input: HandbookAcknowledgementSetFragmentInput,
  ) => Promise<void>
  setHandbookBrandingAttrs: (
    input: HandbookBrandingSetAttributesInput,
  ) => Promise<void>
  setHandbookBrandingCssAttrs: (
    input: HandbookBrandingSetCssAttributesInput,
  ) => Promise<void>
  setHandbookBrandingCustomHtmlAttrs: (
    input: HandbookBrandingSetCustomHtmlAttributesInput,
  ) => Promise<void>
  setHandbookContactFragment: (
    input: HandbookContactSetFragmentInput,
  ) => Promise<void>
  setHandbookSettings: (
    input: HandbookSetSettingsInput,
  ) => Promise<HandbookSetSettingsResult>

  // Document-Level
  addDocuments(input: DocumentRefsAddInput): Promise<string[]>
  removeNode(input: DocumentNodeRemoveInput): Promise<void>
  revertDocumentRefs: (
    input: RevertDocumentRefsInput,
  ) => Promise<RevertDocumentRefsResult>
  updateDocumentRefNode: (input: DocumentRefNodeUpdateInput) => Promise<void>

  // Section-Level
  addHandbookSection: (
    input: HandbookSectionAddInput,
  ) => Promise<BlissbookSection>
  clearHandbookSectionDirty: (
    input: HandbookSectionClearDirtyInput,
  ) => Promise<void>
  cloneHandbookSection: (
    input: HandbookSectionCloneInput,
  ) => Promise<HandbookSectionCloneResult>
  convertHandbookSectionsToPolicies: (
    input: HandbookSectionConvertToPoliciesInput,
  ) => Promise<HandbookSectionConvertToPoliciesResult>
  moveHandbookSectionToHandbook: (
    input: HandbookSectionMoveToHandbookInput,
  ) => Promise<void>
  removeHandbookSection: (input: HandbookSectionRemoveInput) => Promise<void>
  setHandbookSectionAttrs: (
    input: HandbookSectionSetAttributesInput,
  ) => Promise<HandbookSectionSetAttributesResult>
  setHandbookSectionFragment: (
    input: HandbookSectionSetFragmentInput,
  ) => Promise<HandbookSectionSetFragmentResult>
  setHandbookSectionSettings: (
    input: HandbookSectionSetSettingsInput,
  ) => Promise<HandbookSectionSetSettingsResult>

  // Section-Entry-Level
  addHandbookSectionEntry: (
    input: HandbookSectionEntryAddInput,
  ) => Promise<HandbookSectionEntryAddResult>
  moveHandbookSectionEntry: (
    input: HandbookSectionEntryMoveInput,
  ) => Promise<HandbookSectionEntryMoveResult>
  removeHandbookSectionEntry: (
    input: HandbookSectionEntryRemoveInput,
  ) => Promise<HandbookSectionEntryRemoveResult>
  setHandbookSectionEntryAttrs: (
    input: HandbookSectionEntrySetAttributesInput,
  ) => Promise<HandbookSectionEntrySetAttributesResult>
  setHandbookSectionEntryFragment: (
    input: HandbookSectionEntrySetFragmentInput,
  ) => Promise<HandbookSectionEntrySetFragmentResult>
}

export interface HandbookEditorEvents {
  handbook: HandbookDataModel
  lastErrorAt: Date
  lastRequestAt: Date
  lastResponseAt: Date
  transaction: { handbook: HandbookDataModel; tr: HandbookTransaction }
}

export class HandbookEditor extends EventEmitter<HandbookEditorEvents> {
  // Data
  handbook: HandbookDataModel

  // Status
  lastErrorAt?: Date
  lastRequestAt?: Date
  lastResponseAt?: Date

  private readonly _impl: HandbookEditorImpl

  constructor(handbook: HandbookDataModel, impl: HandbookEditorImpl) {
    super()
    this.handbook = handbook
    this._impl = impl
  }

  applyTransaction(tr: HandbookTransaction) {
    const handbook = produce(this.handbook, (draft) => {
      draft.applyTransaction(tr)
    })
    this.handbook = handbook
    this.emit('handbook', handbook)
    this.emit('transaction', { handbook, tr })
  }

  // Assets

  addFont(font: FontDataModel) {
    const handbook = produce(this.handbook, (draft) => {
      draft.fonts.push(font)
    })
    this.handbook = handbook
    this.emit('handbook', handbook)
  }

  async archiveFont(font: FontDataModel) {
    await this._impl.archiveFont(font.id)

    const handbook = produce(this.handbook, (draft) => {
      draft.fonts = draft.fonts.filter((f) => f.id !== font.id)
    })
    this.handbook = handbook
    this.emit('handbook', handbook)
  }

  // Handbook-Level

  async setHandbookAcknowledgementAttrs(
    input: HandbookAcknowledgementSetAttributesInput,
  ) {
    return this.transaction(async () => {
      await this._impl.setHandbookAcknowledgementAttrs(input)
      this.applyTransaction({
        type: 'setAcknowledgementAttrs',
        value: input,
      })
    })
  }

  async setHandbookBrandingAttrs(input: HandbookBrandingSetAttributesInput) {
    return this.transaction(async () => {
      await this._impl.setHandbookBrandingAttrs(input)
      this.applyTransaction({
        type: 'setBrandingAttrs',
        value: input,
      })
    })
  }

  async setHandbookBrandingCssAttrs(
    input: HandbookBrandingSetCssAttributesInput,
  ) {
    return this.transaction(async () => {
      await this._impl.setHandbookBrandingCssAttrs(input)
      this.applyTransaction({
        type: 'setBrandingAttrs',
        value: input,
      })
    })
  }

  async setHandbookBrandingCustomHtmlAttrs(
    input: HandbookBrandingSetCustomHtmlAttributesInput,
  ) {
    return this.transaction(async () => {
      await this._impl.setHandbookBrandingCustomHtmlAttrs(input)
      this.applyTransaction({
        type: 'setBrandingAttrs',
        value: input,
      })
    })
  }

  async setHandbookAcknowledgementFragment(
    input: HandbookAcknowledgementSetFragmentInput,
  ) {
    return this.transaction(async () => {
      await this._impl.setHandbookAcknowledgementFragment(input)
      this.applyTransaction({
        ...input,
        type: 'setAcknowledgementFragment',
      })
    })
  }

  async setHandbookContactFragment(input: HandbookContactSetFragmentInput) {
    return this.transaction(async () => {
      await this._impl.setHandbookContactFragment(input)
      this.applyTransaction({
        ...input,
        type: 'setContactFragment',
      })
    })
  }

  async setHandbookSettings(input: HandbookSetSettingsInput) {
    return this.transaction(async () => {
      const result = await this._impl.setHandbookSettings(input)
      this.applyTransaction({
        type: 'setSettings',
        result,
      })
    })
  }

  // Document

  /** Add documentRef nodes to the handbook which are pointers to the lastPublishedVersion of a document/policy */
  async addDocumentRefs(input: DocumentRefsAddInput) {
    return this.transaction(async () => {
      const { documentIds, index } = input
      const nodeIds = await this._impl.addDocuments(input)
      this.applyTransaction({
        type: 'addDocumentRefs',
        result: {
          content: documentIds.map((documentId, index) => ({
            type: 'documentRef',
            attrs: { documentId, id: nodeIds[index] },
          })),
          index,
        },
      })
    })
  }

  async removeNode(input: DocumentNodeRemoveInput) {
    return this.transaction(async () => {
      await this._impl.removeNode(input)
      this.applyTransaction({
        ...input,
        type: 'removeNode',
      })
    })
  }

  async revertDocumentRefs(input: RevertDocumentRefsInput) {
    return this.transaction(async () => {
      const result = await this._impl.revertDocumentRefs(input)
      this.applyTransaction({
        ...input,
        type: 'revertDocumentRefs',
        result,
      })
      return result
    })
  }

  async updateDocumentRefNode(input: DocumentRefNodeUpdateInput) {
    return this.transaction(async () => {
      await this._impl.updateDocumentRefNode(input)
      this.applyTransaction({
        ...input,
        type: 'updateDocumentRefNode',
      })
    })
  }

  // Section-Level

  async addHandbookSection(input: HandbookSectionAddInput) {
    return this.transaction(async () => {
      const { position } = input
      const result = await this._impl.addHandbookSection(input)
      const section = HandbookSectionDataModel.fromJSON(result)
      this.applyTransaction({
        type: 'addSection',
        result: { position, section },
      })
      return section
    })
  }

  async clearHandbookSectionDirty(input: HandbookSectionClearDirtyInput) {
    return this.transaction(async () => {
      await this._impl.clearHandbookSectionDirty(input)
      this.applyTransaction({
        ...input,
        type: 'clearDirty',
      })
    })
  }

  async cloneHandbookSection(input: HandbookSectionCloneInput) {
    return this.transaction(async () => {
      const result = await this._impl.cloneHandbookSection(input)
      this.applyTransaction({
        type: 'cloneSection',
        result,
      })
    })
  }

  async convertHandbookSectionsToPolicies(
    input: HandbookSectionConvertToPoliciesInput,
  ) {
    return this.transaction(async () => {
      const result = await this._impl.convertHandbookSectionsToPolicies(input)
      this.applyTransaction({
        ...input,
        type: 'convertSectionsToPolicies',
        result,
      })
    })
  }

  async moveNode(input: HandbookNodeMoveInput) {
    return this.transaction(async () => {
      await this._impl.moveNode(input)
      this.applyTransaction({
        ...input,
        type: 'moveNode',
      })
    })
  }

  async moveHandbookSectionToHandbook(
    input: HandbookSectionMoveToHandbookInput,
  ) {
    return this.transaction(async () => {
      await this._impl.moveHandbookSectionToHandbook(input)
      this.applyTransaction({
        ...input,
        type: 'removeSection',
      })
    })
  }

  async removeHandbookSection(input: HandbookSectionRemoveInput) {
    return this.transaction(async () => {
      await this._impl.removeHandbookSection(input)
      this.applyTransaction({
        ...input,
        type: 'removeSection',
      })
    })
  }

  async setHandbookSectionAttrs(input: HandbookSectionSetAttributesInput) {
    return this.transaction(async () => {
      const result = await this._impl.setHandbookSectionAttrs(input)
      this.applyTransaction({
        ...input,
        type: 'setAttrs',
        result,
      })
    })
  }

  async setHandbookSectionFragment(input: HandbookSectionSetFragmentInput) {
    return this.transaction(async () => {
      const result = await this._impl.setHandbookSectionFragment(input)
      this.applyTransaction({
        ...input,
        type: 'setFragment',
        result,
      })
    })
  }

  async setHandbookSectionSettings(input: HandbookSectionSetSettingsInput) {
    return this.transaction(async () => {
      const { sectionId } = input
      const result = await this._impl.setHandbookSectionSettings(input)
      this.applyTransaction({
        type: 'setSettings',
        sectionId,
        result,
      })
    })
  }

  // Section-Entry-Level

  async addHandbookSectionEntry(input: HandbookSectionEntryAddInput) {
    return this.transaction(async () => {
      const result = await this._impl.addHandbookSectionEntry(input)
      const { entry } = result
      this.applyTransaction({
        ...input,
        type: 'add',
        entryUid: entry.uid,
        result,
      })
      return entry
    })
  }

  async moveHandbookSectionEntry(input: HandbookSectionEntryMoveInput) {
    return this.transaction(async () => {
      const result = await this._impl.moveHandbookSectionEntry(input)
      this.applyTransaction({
        ...input,
        type: 'move',
        result,
      })
    })
  }

  async setHandbookSectionEntryAttrs(
    input: HandbookSectionEntrySetAttributesInput,
  ) {
    return this.transaction(async () => {
      const result = await this._impl.setHandbookSectionEntryAttrs(input)
      this.applyTransaction({
        ...input,
        type: 'setAttrs',
        result,
      })
    })
  }

  async setHandbookSectionEntryFragment(
    input: HandbookSectionEntrySetFragmentInput,
  ) {
    return this.transaction(async () => {
      const result = await this._impl.setHandbookSectionEntryFragment(input)
      this.applyTransaction({
        ...input,
        type: 'setFragment',
        result,
      })
    })
  }

  async removeHandbookSectionEntry(input: HandbookSectionEntryRemoveInput) {
    return this.transaction(async () => {
      const result = await this._impl.removeHandbookSectionEntry(input)
      this.applyTransaction({
        ...input,
        type: 'remove',
        result,
      })
    })
  }

  private async transaction<T>(callback: () => Promise<T>) {
    try {
      this.setLastRequestAt()
      const result = await callback()
      this.setLastResponseAt()
      return result
    } catch (error) {
      logUIError(error)
      this.setLastErrorAt()
      throw error
    }
  }

  private setLastErrorAt() {
    const lastErrorAt = new Date()
    this.lastErrorAt = lastErrorAt
    this.emit('lastErrorAt', lastErrorAt)
  }

  private setLastRequestAt() {
    const lastRequestAt = new Date()
    this.lastRequestAt = lastRequestAt
    this.emit('lastRequestAt', lastRequestAt)
  }

  private setLastResponseAt() {
    const lastResponseAt = new Date()
    this.lastResponseAt = lastResponseAt
    this.emit('lastResponseAt', lastResponseAt)
  }
}
