import { PeopleExpressionView } from '@blissbook/application/expression'
import type { AudienceRootExpression } from '@blissbook/lib/expression'
import { Tooltip, type TooltipProps } from '@blissbook/ui/lib'
import React from 'react'

export type PeopleExpressionTooltipContentProps = {
  audienceExpression?: AudienceRootExpression
  description?: React.ReactNode
  title?: string
}

export function PeopleExpressionTooltipContent({
  audienceExpression,
  description,
  title,
}: PeopleExpressionTooltipContentProps) {
  return (
    <div className='tw-flex tw-flex-col tw-gap-2'>
      {title && <div className='tw-text-blurple-500 tw-uppercase'>{title}</div>}
      {description && <div className='tw-text-sm'>{description}</div>}
      {audienceExpression && (
        <PeopleExpressionView value={audienceExpression} />
      )}
    </div>
  )
}

export type PeopleExpressionTooltipProps = Omit<
  TooltipProps,
  'content' | 'theme'
> &
  PeopleExpressionTooltipContentProps

export function PeopleExpressionTooltip({
  audienceExpression,
  description,
  title,
  ...props
}: PeopleExpressionTooltipProps) {
  const content = (
    <PeopleExpressionTooltipContent
      audienceExpression={audienceExpression}
      description={description}
      title={title}
    />
  )

  return <Tooltip {...props} content={content} theme='expression' />
}
