import {
  type HandbooksOperandValue,
  type OperandType,
  isHandbooksOperandValueValid,
} from '@blissbook/lib/expression'
// @ts-ignore: WIP imports
import { Dropdown, SearchInput } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useHandbooksById } from '../ExpressionContext'
import type { Handbook } from '../types'
import { renderMaxValues } from './GroupsOperandInput'
import {
  OperandDropdownFooter,
  OperandDropdownOption,
  OperandDropdownToggleButton,
  RemoveIcon,
} from './components'
import type { BaseOperandField } from './types'

type HandbooksIsNotOption = {
  label: string
  value: boolean
}

const isNotOptions: HandbooksIsNotOption[] = [
  {
    label: 'includes',
    value: false,
  },
  {
    label: 'excludes',
    value: true,
  },
]

type HandbooksIsEveryOption = {
  label: string
  value: boolean
}

const isEveryOptions: HandbooksIsEveryOption[] = [
  {
    label: 'any',
    value: false,
  },
  {
    label: 'all',
    value: true,
  },
]

type HandbookOption = {
  key: number
  label: string
  value: Handbook
}

export function renderHandbooks(handbooks: Handbook[], maxValues?: number) {
  const values = handbooks.map((handbook) => handbook.name)
  return renderMaxValues(values, maxValues)
}

export type HandbooksOperandField = BaseOperandField & {
  type: OperandType.Handbooks
  defaultValue: HandbooksOperandValue
  searchHandbooks: (text: string) => Promise<Handbook[]>
}

export type HandbooksOperandInputProps = {
  className?: string
  field: HandbooksOperandField
  forceOpen?: boolean
  isOpen: boolean
  onChange: (value: HandbooksOperandValue) => void
  onRemove: () => void
  setOpen: (isOpen: boolean) => void
  value: HandbooksOperandValue
}

export function HandbooksOperandInput({
  className,
  field,
  forceOpen,
  onChange,
  onRemove,
  setOpen,
  value,
  ...props
}: HandbooksOperandInputProps) {
  const isValid = isHandbooksOperandValueValid(value)

  // Calculate the handbooks from the IDs
  const { searchHandbooks } = field
  const handbookIds = value.ids
  const handbooksById = useHandbooksById()
  const handbooks: Handbook[] = handbookIds.map((id) => {
    return (
      handbooksById.get(id) || {
        id,
        name: `Document #${id}`,
      }
    )
  })
  handbooks.sort((a, b) => a.name.localeCompare(b.name))

  const isNotOption = isNotOptions.find(
    (option) => option.value === value.isNot,
  )
  const isEveryOption = isEveryOptions.find(
    (option) => option.value === value.isEvery,
  )

  function handleSetOpen(isOpen: boolean) {
    setOpen(isOpen)
  }

  function handleSelectIsNot(isNot: boolean) {
    onChange({ ...value, isNot })
  }

  function handleSelectIsEvery(isEvery: boolean) {
    onChange({ ...value, isEvery })
  }

  async function getHandbookOptions(text: string) {
    const handbooks = await searchHandbooks(text)
    return handbooks.map((handbook) => ({
      children: (
        <OperandDropdownOption
          checked={(operand: HandbooksOperandValue) =>
            operand.ids.includes(handbook.id)
          }
          Icon={(props) => (
            <FontAwesomeIcon {...props} icon={['far', 'file-invoice']} />
          )}
        >
          {handbook.name}
        </OperandDropdownOption>
      ),
      icon: ['far', 'file-invoice'],
      label: handbook.name,
      key: handbook.id,
      value: handbook,
    }))
  }

  function handleAddHandbookId(handbookId: number) {
    onChange({ ...value, ids: [...handbookIds, handbookId] })
  }

  function handleRemoveHandbookId(handbookId: number) {
    onChange({
      ...value,
      ids: handbookIds.filter((id) => id !== handbookId),
    })
  }

  return (
    <Dropdown.Provider {...props} setOpen={handleSetOpen}>
      <OperandDropdownToggleButton
        className={className}
        icon={<field.Icon />}
        isInvalid={!isValid && !forceOpen}
        label='Document'
        onRemove={onRemove}
      >
        {isNotOption.label} {isEveryOption.label}
        {' of '}
        <span className='ellipsis' title={renderHandbooks(handbooks)}>
          {renderHandbooks(handbooks, 3)}
        </span>
      </OperandDropdownToggleButton>

      <Dropdown.Menu
        className='tw-overflow-visible tw-space-y-2 tw-p-3'
        maxHeight={400}
      >
        <div className='tw-flex tw-gap-2 tw-items-center'>
          <Dropdown.Provider>
            <Dropdown.ToggleButton className='btn btn-input tw-w-24'>
              {isNotOption.label}
            </Dropdown.ToggleButton>
            <Dropdown.Menu className='tw-p-3 tw-w-40'>
              {isNotOptions.map((option, index) => (
                <Dropdown.Item
                  active={option === isNotOption}
                  checked={option === isNotOption}
                  className='tw-flex tw-items-center tw-justify-between'
                  key={index}
                  onClick={() => {
                    handleSelectIsNot(option.value)
                  }}
                >
                  <div className='tw-flex-1 ellipsis'>{option.label}</div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Provider>

          <Dropdown.Provider>
            <Dropdown.ToggleButton className='btn btn-input tw-w-16'>
              {isEveryOption.label}
            </Dropdown.ToggleButton>
            <Dropdown.Menu className='tw-p-3 tw-w-40'>
              {isEveryOptions.map((option, index) => (
                <Dropdown.Item
                  active={option === isEveryOption}
                  checked={option === isEveryOption}
                  className='tw-flex tw-items-center tw-justify-between'
                  key={index}
                  onClick={() => {
                    handleSelectIsEvery(option.value)
                  }}
                >
                  <div className='tw-flex-1 ellipsis'>{option.label}</div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Provider>

          <SearchInput
            autoFocus={handbookIds.length === 0}
            dropdownWidth={400}
            getOptions={getHandbookOptions}
            minLength={0}
            noClearValueOnSelect
            onSelect={(option: HandbookOption) => {
              const handbook = option.value
              if (handbookIds.includes(handbook.id)) {
                handleRemoveHandbookId(handbook.id)
              } else {
                handleAddHandbookId(handbook.id)
              }
            }}
            placeholder='Search for Documents...'
          />
        </div>

        {handbooks.length > 0 && (
          <div className='tw-flex-1 tw-flex tw-flex-col tw-overflow-auto'>
            {handbooks.map((handbook, index) => (
              <div
                className='tw-flex tw-items-center tw-justify-between tw-text-gray-600 hover:tw-text-gray-800 tw-px-2 tw-py-2 tw-text-base tw-group'
                key={index}
              >
                {handbook.name}
                <RemoveIcon
                  className='tw-invisible group-hover:tw-visible'
                  onClick={() => {
                    handleRemoveHandbookId(handbook.id)
                  }}
                />
              </div>
            ))}
          </div>
        )}

        <OperandDropdownFooter onClose={() => setOpen(false)} />
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
