import { faExpandWide } from '@fortawesome/pro-solid-svg-icons/faExpandWide'
import { ButtonView, Plugin } from 'ckeditor5'
import { getIconPath } from '../ui/util'

export class ReadMoreUI extends Plugin {
  init() {
    const editor = this.editor
    const t = editor.t

    // The "readMore" button must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add('readMore', (locale) => {
      // The state of the button will be bound to the widget command.
      const command = editor.commands.get('wrapInReadMore')

      // The button will be an instance of ButtonView.
      const buttonView = new ButtonView(locale)

      buttonView.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faExpandWide),
        label: t('Wrap In Read More'),
        tooltip: true,
      })

      // @ts-ignore Bind the state of the button to the command.
      buttonView.bind('isOn', 'isEnabled').to(command, 'value', 'isEnabled')

      // Execute the command when the button is clicked (executed).
      this.listenTo(buttonView, 'execute', () =>
        editor.execute('wrapInReadMore'),
      )

      return buttonView
    })
  }
}
