import {
  type PeopleSource,
  useGroupNamesLazyQuery,
} from '@blissbook/application/graph'
import { useCallback } from 'react'

export function useSearchGroupNames(source?: PeopleSource) {
  const [query] = useGroupNamesLazyQuery({})

  return useCallback(
    async function searchGroupNames(text: string) {
      const res = await query({
        variables: { source },
      })
      return res.data?.groupNames.filter((value) =>
        value.toLowerCase().includes(text),
      )
    },
    [source],
  )
}
