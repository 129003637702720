import {
  DateOperandCondition,
  type DateOperandValue,
  type OperandType,
  isDateConditionAbsolute,
  isDateOperandValueValid,
} from '@blissbook/lib/expression'
import type { DateOperandTimeUnit } from '@blissbook/ui/admin/graph/types-and-hooks'
// @ts-ignore: WIP imports
import { Dropdown, Input } from '@blissbook/ui/lib'
import { DatePicker } from '@blissbook/ui/lib/input/DatePicker'
import moment from 'moment'
import React, { useState } from 'react'
import {
  OperandDropdownFooter,
  OperandDropdownToggleButton,
} from './components'
import type { BaseOperandField } from './types'

type DateOperandConditionOption = {
  label: string
  isDaysAgo?: boolean
  isDaysFromNow?: boolean
  showOverdue?: boolean
  value: DateOperandCondition
}

function getOptions(field: DateOperandField): DateOperandConditionOption[] {
  const options: DateOperandConditionOption[] = []

  if (field.showOverdue) {
    options.push({
      label: 'is overdue',
      value: DateOperandCondition.IsOverdue,
    })
  }

  if (field.isFuture) {
    options.push(
      {
        label: 'is less than',
        isDaysFromNow: true,
        value: DateOperandCondition.LessThanDaysFromNow,
      },
      {
        label: 'is less than or equal to',
        isDaysFromNow: true,
        value: DateOperandCondition.LessThanOrEqualToDaysFromNow,
      },
      {
        label: 'is exactly',
        isDaysFromNow: true,
        value: DateOperandCondition.ExactlyDaysFromNow,
      },
      {
        label: 'is more than',
        isDaysFromNow: true,
        value: DateOperandCondition.MoreThanDaysFromNow,
      },
      {
        label: 'is more than or equal to',
        isDaysFromNow: true,
        value: DateOperandCondition.MoreThanOrEqualToDaysFromNow,
      },
    )
  } else {
    options.push(
      {
        label: 'is less than',
        isDaysAgo: true,
        value: DateOperandCondition.LessThanDaysAgo,
      },
      {
        label: 'is less than or equal to',
        isDaysAgo: true,
        value: DateOperandCondition.LessThanOrEqualToDaysAgo,
      },
      {
        label: 'is exactly',
        isDaysAgo: true,
        value: DateOperandCondition.ExactlyDaysAgo,
      },
      {
        label: 'is more than',
        isDaysAgo: true,
        value: DateOperandCondition.MoreThanDaysAgo,
      },
      {
        label: 'is more than or equal to',
        isDaysAgo: true,
        value: DateOperandCondition.MoreThanOrEqualToDaysAgo,
      },
    )
  }

  options.push(
    {
      label: 'is before',
      value: DateOperandCondition.BeforeDate,
    },
    {
      label: 'is on or before',
      value: DateOperandCondition.IsOnOrBefore,
    },
    {
      label: 'is on',
      value: DateOperandCondition.OnDate,
    },
    {
      label: 'is on or after',
      value: DateOperandCondition.IsOnOrAfter,
    },
    {
      label: 'is after',
      value: DateOperandCondition.AfterDate,
    },
    {
      label: 'is unknown',
      value: DateOperandCondition.HasNoValue,
    },
    {
      label: 'has any value',
      value: DateOperandCondition.HasValue,
    },
  )

  return options
}

function isRelativeOption(option: DateOperandConditionOption) {
  return option.isDaysAgo || option.isDaysFromNow
}

/** Format the date to send */
function formatDate(date: Date) {
  return moment(date).format('YYYY-MM-DD')
}

export type DateOperandField = BaseOperandField & {
  type: OperandType.Date
  field: string
  path?: string
  defaultValue: DateOperandValue
  isFuture?: boolean
  showArchived?: boolean
  showOverdue?: boolean
}

export type DateOperandInputProps = {
  className?: string
  field: DateOperandField
  forceOpen?: boolean
  isOpen: boolean
  onChange: (value: DateOperandValue) => void
  onRemove: () => void
  setOpen: (isOpen: boolean) => void
  value: DateOperandValue
}

export function DateOperandInput({
  className,
  field,
  forceOpen,
  onChange,
  onRemove,
  value,
  ...props
}: DateOperandInputProps) {
  const { setOpen } = props
  const [date, setDate] = useState(() =>
    value.date ? moment(value.date, 'YYYY-MM-DD').toDate() : new Date(),
  )

  const options = getOptions(field)
  const activeOption = options.find(
    (option) => option.value === value.dateCondition,
  )

  const isAbsolute = isDateConditionAbsolute(value.dateCondition)
  const isRelative = isRelativeOption(activeOption)
  const isInvalid = !isDateOperandValueValid(value)

  const timeUnits = [
    { label: 'Hours', value: 'hours' },
    { label: 'Days', value: 'days' },
    { label: 'Weeks', value: 'weeks' },
    { label: 'Months', value: 'months' },
    { label: 'Years', value: 'years' },
  ]

  const selectedTimeUnit = value.timeUnit || 'days'
  const relativeText = field.isFuture
    ? selectedTimeUnit === 'hours'
      ? 'from now'
      : 'from today'
    : 'ago'

  return (
    <Dropdown.Provider {...props}>
      <OperandDropdownToggleButton
        className={className}
        icon={<field.Icon />}
        isInvalid={isInvalid && !forceOpen}
        label={field.label}
        onRemove={onRemove}
      >
        {activeOption.label}
        {isAbsolute && !isInvalid && ` ${value.date}`}
        {isRelative &&
          !isInvalid &&
          ` ${value.days} ${selectedTimeUnit} ${relativeText}`}
      </OperandDropdownToggleButton>

      <Dropdown.Menu className='tw-overflow-visible tw-p-3' maxHeight={400}>
        <div className='tw-flex tw-items-center tw-gap-2'>
          <Dropdown.Provider>
            <Dropdown.ToggleButton className='btn btn-input tw-w-48'>
              {activeOption.label}
            </Dropdown.ToggleButton>
            <Dropdown.Menu className='tw-p-3'>
              {options.map((option, index) => (
                <Dropdown.Item
                  active={option === activeOption}
                  checked={option === activeOption}
                  className='tw-flex tw-items-center tw-justify-between'
                  key={index}
                  onClick={() => {
                    const newValue = { ...value, dateCondition: option.value }
                    if (isDateConditionAbsolute(newValue.dateCondition)) {
                      newValue.date ||= formatDate(date)
                    }
                    onChange(newValue)
                  }}
                >
                  <div className='tw-flex-1 ellipsis'>{option.label}</div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Provider>

          {isRelative && (
            <>
              <Input
                autoFocus
                className='form-control tw-w-20'
                debounce={200}
                onChangeValue={(days: number) => {
                  onChange({ ...value, days })
                }}
                placeholder='Value'
                type='number'
                value={value.days}
              />

              <Dropdown.Provider>
                <Dropdown.ToggleButton className='btn btn-input tw-w-24'>
                  {
                    timeUnits.find((unit) => unit.value === selectedTimeUnit)
                      ?.label
                  }
                </Dropdown.ToggleButton>
                <Dropdown.Menu className='tw-p-3'>
                  {timeUnits.map((unit, index) => (
                    <Dropdown.Item
                      active={unit.value === selectedTimeUnit}
                      checked={unit.value === selectedTimeUnit}
                      key={index}
                      onClick={() => {
                        onChange({
                          ...value,
                          timeUnit: unit.value as DateOperandTimeUnit,
                        })
                      }}
                    >
                      {unit.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown.Provider>

              <span className='tw-whitespace-nowrap'>{relativeText}</span>
            </>
          )}

          {isAbsolute && (
            <DatePicker
              className='form-control'
              dateFormat='yyyy-MM-dd'
              onChange={(date) => {
                onChange({
                  ...value,
                  date: formatDate(date),
                  timeUnit: undefined,
                })
                setDate(date)
              }}
              selected={date}
            />
          )}
        </div>

        <OperandDropdownFooter onClose={() => setOpen(false)} />
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
