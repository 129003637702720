import { Button } from '@blissbook/ui/lib'
import React from 'react'

export type OperandDropdownFooterProps = {
  onClose: () => void
}

export function OperandDropdownFooter({ onClose }: OperandDropdownFooterProps) {
  return (
    <>
      <div
        // biome-ignore lint/a11y/useAriaPropsForRole: the separator is not focussable and does not need to have the needed attributes
        role='separator'
        aria-orientation='horizontal'
        className='tw-my-2 tw-h-px tw-bg-gray-100'
      />

      <div className='tw-flex tw-justify-start'>
        <Button color='primary' onClick={onClose}>
          Done
        </Button>
      </div>
    </>
  )
}
