import { DateOperandTimeUnit } from '@blissbook/application/graph'
import {
  DateOperandCondition,
  OperandType,
  TextOperandCondition,
} from '@blissbook/lib/expression'
import { getMetadataKeyLabel } from '@blissbook/lib/metadata'
import { Tooltip } from '@blissbook/ui/lib'
import { faUserLock } from '@fortawesome/pro-regular-svg-icons/faUserLock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import {
  type DateOperandField,
  GroupsFieldIcon,
  type GroupsOperandField,
  type ManagersOperandField,
  type OperandField,
  PeopleFieldIcon,
  type PeopleOperandField,
  SavedSegmentsFieldIcon,
  type SavedSegmentsOperandField,
  type TypeaheadSelectOperandField,
} from '../operands'
import { MetadataIcon } from './MetadataIcon'
import {
  type BuildHandbooksFieldArgs,
  buildHandbooksField,
} from './documentFields'

export const complianceStatusOptions = [
  {
    id: 'compliant',
    label: 'compliant',
  },
  {
    id: 'non-compliant',
    label: 'not compliant',
  },
]

export const complianceStatusField: OperandField = {
  type: OperandType.Select,
  field: 'complianceStatus',
  label: 'Compliance Status',
  Icon: (props) => (
    <Tooltip content='Compliance Status'>
      <FontAwesomeIcon {...props} icon={['fas', 'shield-halved']} />
    </Tooltip>
  ),
  inputType: 'radio',
  options: complianceStatusOptions,
  defaultValue: {
    field: 'complianceStatus',
    type: OperandType.Select,
    isEvery: false,
    isNot: false,
    values: ['non-compliant'],
  },
}

export const customSsoIdField: OperandField = {
  type: OperandType.Text,
  field: 'customSsoId',
  Icon: (props) => <FontAwesomeIcon {...props} icon={faUserLock} />,
  label: 'SSO ID',
  allowEquals: true,
  allowExists: true,
  defaultValue: {
    field: 'customSsoId',
    type: OperandType.Text,
    textCondition: TextOperandCondition.Contains,
    isNot: false,
    text: '',
  },
}

export const emailField: OperandField = {
  type: OperandType.Text,
  field: 'email',
  Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'envelope']} />,
  label: 'Email',
  allowEquals: true,
  allowExists: true,
  defaultValue: {
    field: 'email',
    type: OperandType.Text,
    textCondition: TextOperandCondition.Contains,
    isNot: false,
    text: '',
  },
}

export const employeeIdField: OperandField = {
  type: OperandType.Text,
  field: 'employeeId',
  Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'hashtag']} />,
  label: 'Employee ID',
  allowEquals: true,
  allowExists: true,
  defaultValue: {
    field: 'employeeId',
    type: OperandType.Text,
    textCondition: TextOperandCondition.Contains,
    isNot: false,
    text: '',
  },
}

export const fullNameField: OperandField = {
  type: OperandType.Text,
  field: 'fullName',
  label: 'Full Name',
  Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'user-large']} />,
  allowEquals: true,
  allowExists: true,
  defaultValue: {
    field: 'fullName',
    type: OperandType.Text,
    textCondition: TextOperandCondition.Contains,
    isNot: false,
    text: '',
  },
}

export const lastHiredOnField: OperandField = {
  type: OperandType.Date,
  field: 'lastHiredOn',
  Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'handshake']} />,
  label: 'Last Hired',
  defaultValue: {
    field: 'lastHiredOn',
    type: OperandType.Date,
    dateCondition: DateOperandCondition.LessThanDaysAgo,
    days: 30,
    timeUnit: DateOperandTimeUnit.Days,
  },
}

export type BuildLastNotifiedFieldArgs = Pick<DateOperandField, 'field'>

export function buildLastNotifiedField({
  field,
}: BuildLastNotifiedFieldArgs): DateOperandField {
  return {
    type: OperandType.Date,
    field,
    Icon: (props) => (
      <FontAwesomeIcon {...props} icon={['far', 'mailbox-flag-up']} />
    ),
    label: 'Last Notified',
    defaultValue: {
      field,
      type: OperandType.Date,
      dateCondition: DateOperandCondition.LessThanDaysAgo,
      days: 30,
      timeUnit: DateOperandTimeUnit.Days,
    },
  }
}

export const levelOptions = [
  {
    id: 'employee',
    label: 'Employee',
  },
  {
    id: 'manager',
    label: 'Manager',
  },
]

export const levelField: OperandField = {
  type: OperandType.Select,
  field: 'level',
  Icon: (props) => (
    <FontAwesomeIcon {...props} icon={['far', 'user-hair-mullet']} />
  ),
  label: 'Level',
  inputType: 'checkbox',
  options: levelOptions,
  defaultValue: {
    field: 'level',
    type: OperandType.Select,
    isEvery: false,
    isNot: false,
    values: ['employee', 'manager'],
  },
}

export type BuildSavedSegmentsFieldArgs = Pick<
  SavedSegmentsOperandField,
  'searchSavedSegments'
>

export function buildSavedSegmentsField(
  args: BuildSavedSegmentsFieldArgs,
): SavedSegmentsOperandField {
  return {
    ...args,
    type: OperandType.SavedSegments,
    label: 'Segments',
    Icon: SavedSegmentsFieldIcon,
    defaultValue: {
      type: OperandType.SavedSegments,
      isEvery: false,
      isNot: false,
      ids: [],
    },
  }
}

export type BuildGroupsFieldArgs = Pick<GroupsOperandField, 'searchGroups'>

export function buildGroupsField(
  args: BuildGroupsFieldArgs,
): GroupsOperandField {
  return {
    ...args,
    type: OperandType.Groups,
    label: 'Groups',
    Icon: GroupsFieldIcon,
    defaultValue: {
      type: OperandType.Groups,
      isEvery: false,
      isNot: false,
      ids: [],
    },
  }
}

export type BuildGroupNamesFieldArgs = {
  /** Get the metadata values for this key */
  searchGroupNames: (search: string) => Promise<string[]>
}

export function buildGroupNamesField({
  searchGroupNames,
}: BuildGroupNamesFieldArgs): TypeaheadSelectOperandField {
  return {
    type: OperandType.Select,
    field: 'groupName',
    label: 'Groups',
    Icon: GroupsFieldIcon,
    inputType: 'typeahead',
    allowEvery: true,
    defaultValue: {
      field: 'groupName',
      type: OperandType.Select,
      isEvery: false,
      isNot: false,
      values: [],
    },
    async searchOptions(search) {
      const values = await searchGroupNames(search)
      return values.map((value) => ({ label: value, value }))
    },
    useOptions: (values) => values?.map((value) => ({ label: value, value })),
  }
}

export type BuildManagerFieldArgs = Pick<ManagersOperandField, 'searchManagers'>

export function buildManagersField(
  args: BuildManagerFieldArgs,
): ManagersOperandField {
  return {
    ...args,
    type: OperandType.Managers,
    label: 'Manager',
    Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'user-tie']} />,
    defaultValue: {
      type: OperandType.Managers,
      isEvery: false,
      isNot: false,
      managesDirectly: true,
      ids: [],
    },
  }
}

export type BuildMetadataFieldArgs = {
  /** Get the metadata values for this key */
  searchMetadataValues: (
    metadataKey: string,
    search: string,
  ) => Promise<string[]>
}

export function buildMetadataField(
  metadataKey: string,
  { searchMetadataValues }: BuildMetadataFieldArgs,
): TypeaheadSelectOperandField {
  return {
    type: OperandType.Select,
    field: 'metadata',
    path: metadataKey,
    label: getMetadataKeyLabel(metadataKey),
    Icon: (props) => <MetadataIcon {...props} metadataKey={metadataKey} />,
    inputType: 'typeahead',
    allowAny: true,
    defaultValue: {
      field: 'metadata',
      path: metadataKey,
      type: OperandType.Select,
      isEvery: false,
      isNot: false,
      values: [],
    },
    async searchOptions(search) {
      const values = await searchMetadataValues(metadataKey, search)
      return values.map((value) => ({ label: value, value }))
    },
    useOptions: (values) => values?.map((value) => ({ label: value, value })),
  }
}

export type BuildPeopleFieldArgs = Pick<PeopleOperandField, 'searchPeople'>

export function buildPeopleField(
  args: BuildPeopleFieldArgs,
  {
    allowAny,
    allowEvery,
    field,
    label = 'People',
    path,
  }: {
    allowAny?: boolean
    allowEvery?: boolean
    field?: string
    label?: string
    path?: string
  } = {},
): PeopleOperandField {
  return {
    ...args,
    type: OperandType.People,
    label,
    allowAny,
    allowEvery,
    Icon: PeopleFieldIcon,
    defaultValue: {
      type: OperandType.People,
      field,
      path,
      isEvery: false,
      isNot: false,
      ids: [],
    },
  }
}

export const archivedAtField: OperandField = {
  type: OperandType.Date,
  field: 'archivedAt',
  Icon: (props) => <FontAwesomeIcon {...props} icon={['far', 'user-slash']} />,
  label: 'Archived',
  showArchived: true,
  defaultValue: {
    field: 'archivedAt',
    type: OperandType.Date,
    dateCondition: DateOperandCondition.LessThanDaysAgo,
    days: 30,
    timeUnit: DateOperandTimeUnit.Days,
  },
}

export type GetPeopleFieldsArgs = Partial<
  BuildHandbooksFieldArgs &
    BuildGroupsFieldArgs &
    BuildGroupNamesFieldArgs &
    BuildManagerFieldArgs &
    BuildMetadataFieldArgs &
    BuildSavedSegmentsFieldArgs
> & {
  /** Number of people with a custom sso id */
  customSsoIdPeopleCount?: number
  /** Number of people with an employee id */
  employeeIdPeopleCount?: number
  /** Whether or not to hide the level field */
  hideLevelField?: boolean
  /** Number of people with a last hired on date */
  lastHiredOnPeopleCount?: number
  /** Arguments for Show Archived field, hide if none */
  lastNotifiedField?: BuildLastNotifiedFieldArgs
  /** Dynamic metadata keys */
  metadataKeys?: string[]
  /** Other fields to show at the end */
  otherFields?: OperandField[]
  /** Whether or not to show the complianceStatusField */
  showComplianceStatus?: boolean
}

export function getPeopleFields({
  customSsoIdPeopleCount,
  employeeIdPeopleCount,
  hideLevelField,
  lastHiredOnPeopleCount,
  metadataKeys,
  otherFields,
  searchGroups,
  searchGroupNames,
  searchHandbooks,
  searchManagers,
  searchMetadataValues,
  searchSavedSegments,
  showComplianceStatus,
  ...args
}: GetPeopleFieldsArgs = {}): OperandField[] {
  const fields = []

  if (showComplianceStatus) {
    fields.push(complianceStatusField)
  }

  fields.push(fullNameField, emailField)

  if (employeeIdPeopleCount > 0) {
    fields.push(employeeIdField)
  }

  if (customSsoIdPeopleCount > 0) {
    fields.push(customSsoIdField)
  }

  if (lastHiredOnPeopleCount > 0) {
    fields.push(lastHiredOnField)
  }

  if (args.lastNotifiedField) {
    const lastNotifiedField = buildLastNotifiedField(args.lastNotifiedField)
    fields.push(lastNotifiedField)
  }

  if (otherFields) {
    fields.push(...otherFields)
  }

  if (!hideLevelField) {
    fields.push(levelField)
  }

  if (searchManagers) {
    const managersField = buildManagersField({ searchManagers })
    fields.push(managersField)
  }

  if (searchSavedSegments) {
    const savedSegmentsField = buildSavedSegmentsField({ searchSavedSegments })
    fields.push(savedSegmentsField)
  }

  if (searchGroups) {
    const groupsField = buildGroupsField({ searchGroups })
    fields.push(groupsField)
  }

  if (searchGroupNames) {
    const groupNamesField = buildGroupNamesField({
      searchGroupNames,
    })
    fields.push(groupNamesField)
  }

  if (searchHandbooks) {
    const handbooksField = buildHandbooksField({ searchHandbooks })
    fields.push(handbooksField)
  }

  if (metadataKeys && searchMetadataValues) {
    for (const metadataKey of metadataKeys) {
      const metadataField = buildMetadataField(metadataKey, {
        searchMetadataValues,
      })
      fields.push(metadataField)
    }
  }

  fields.push(archivedAtField)

  return fields
}

export function usePeopleFields(args?: GetPeopleFieldsArgs, deps: any[] = []) {
  return useMemo(() => getPeopleFields(args), deps)
}
