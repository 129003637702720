import { useHandbooksById as _useHandbooksById } from '@blissbook/application/hooks'
import type { Handbook } from '@blissbook/ui/lib/expression'
import { useMemo } from 'react'

export function useHandbooksById(handbookIdSet: Set<number>) {
  const _handbooksById = _useHandbooksById()
  return useMemo(() => {
    const handbooksById = new Map<number, Handbook>()
    for (const handbookId of handbookIdSet) {
      const handbook = _handbooksById[handbookId]
      if (!handbook) continue
      handbooksById.set(handbookId, handbook)
    }
    return handbooksById
  }, [handbookIdSet, _handbooksById])
}
