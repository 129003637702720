import type { ExpressionConjunction } from '@blissbook/application/graph'
import {
  type Expression,
  type RootExpression,
  isOperandValueValid,
} from '@blissbook/lib/expression'
import { omitTypename } from '@blissbook/ui/lib/graphql'
import { type OperandValueInput, mapOperandValueToInput } from './OperandValue'

export type ExpressionInput = {
  conjunction: ExpressionConjunction
  operands: OperandValueInput[]
}

function mapExpressionToInput(expression: Expression): ExpressionInput {
  const input: ExpressionInput = {
    ...expression,
    operands: [],
  }

  for (const operand of expression.operands) {
    if (!isOperandValueValid(operand)) continue

    const operandInput = mapOperandValueToInput(operand)
    input.operands.push(operandInput)
  }

  return input
}

export type RootExpressionInput = {
  conjunction: ExpressionConjunction
  expressions: ExpressionInput[]
}

export function mapRootExpressionToInput(
  root: RootExpression | undefined,
): RootExpressionInput | undefined {
  if (!root) return undefined

  const inputs: ExpressionInput[] = []

  for (const expression of root.expressions) {
    const input = mapExpressionToInput(expression)
    if (input.operands.length) inputs.push(input)
  }

  if (!inputs.length) return undefined
  return omitTypename({
    ...root,
    expressions: inputs,
  })
}
