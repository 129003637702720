import startCase from 'lodash/startCase'

const metadataKeyLabels: Record<string, string> = {
  companyName: 'Company',
  flsaStatus: 'FLSA Status',
}

export function getMetadataKeyLabel(key: string): string {
  return metadataKeyLabels[key] || startCase(startCase(key).toLowerCase())
}
