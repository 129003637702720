import {
  type RootExpression,
  addIdsToRootExpression,
} from '@blissbook/lib/expression'
import { ExpressionConjunction } from '@blissbook/ui/application/graph'
import { Alert, Dropdown, Link } from '@blissbook/ui/lib'
import { encodeBase64JSON } from '@blissbook/ui/lib/encodeBase64JSON'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { Fragment, useState } from 'react'
import { ExpressionInput } from './ExpressionInput'
import { NewOperandButton } from './NewOperandButton'
import type { ShowArchivedField } from './fields'
import type { OperandField } from './operands'
import { OperandDropdownToggleButton } from './operands/components'
import { shouldCreateSegment } from './utils'

export type RootExpressionInputProps = {
  addButtonEmptyOutline?: boolean
  className?: string
  fields: OperandField[]
  forceLastOperandOpen?: boolean
  showArchivedField?: ShowArchivedField
  onChange: (value: RootExpression) => void
  onCloseOperand?: () => void
  value: RootExpression
  showSegmentAlert?: boolean
}

export function RootExpressionInput({
  addButtonEmptyOutline,
  className,
  fields,
  forceLastOperandOpen,
  onChange,
  onCloseOperand,
  showArchivedField,
  value,
  showSegmentAlert = false,
}: RootExpressionInputProps) {
  const rootExpression = useMemo(() => addIdsToRootExpression(value), [value])
  const [lastOperandOpen, setLastOperandOpen] = useState(false)
  const hasExpression = rootExpression.expressions.length > 0
  const hasFilter = hasExpression || showArchivedField?.value
  forceLastOperandOpen ||= lastOperandOpen

  function handleCloseOperand() {
    setLastOperandOpen(false)
    onCloseOperand?.()
  }

  return (
    <div className={className}>
      <div className='tw-select-none -tw-mb-2 -tw-mr-2'>
        {rootExpression.expressions.map((expression, index) => (
          <Fragment key={index}>
            {index > 0 && (
              <Dropdown.Provider>
                <Dropdown.ToggleButton
                  className='tw-inline-flex tw-ml-2 tw-mr-4 -tw-translate-y-0.5'
                  caret={false}
                >
                  {rootExpression.conjunction}
                </Dropdown.ToggleButton>
                <Dropdown.Menu>
                  {Object.values(ExpressionConjunction).map((conjunction) => (
                    <Dropdown.Item
                      key={conjunction}
                      onClick={() => {
                        onChange({ ...rootExpression, conjunction })
                      }}
                    >
                      {conjunction}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown.Provider>
            )}

            <ExpressionInput
              fields={fields}
              forceLastOperandOpen={forceLastOperandOpen}
              onCloseOperand={handleCloseOperand}
              key={index}
              onChange={(newExpression) => {
                const newExpressions = [...rootExpression.expressions]
                if (newExpression.operands.length === 0) {
                  newExpressions.splice(index, 1)
                } else {
                  newExpressions[index] = newExpression
                }
                onChange({
                  ...rootExpression,
                  expressions: newExpressions,
                })
              }}
              value={expression}
            />
          </Fragment>
        ))}

        {showArchivedField?.value && (
          <Dropdown.Provider>
            <OperandDropdownToggleButton
              className={cx('tw-mb-2 tw-mr-2', hasExpression && 'tw-ml-2')}
              caret={false}
              icon={<FontAwesomeIcon icon={['far', 'eye']} />}
              onRemove={() => {
                showArchivedField.onChange(false)
              }}
            >
              Show Archived
            </OperandDropdownToggleButton>
          </Dropdown.Provider>
        )}

        <NewOperandButton
          className={cx(
            'tw-mt-0.5 tw-mb-2.5 tw-mr-2',
            hasFilter && 'tw-ml-2',
            addButtonEmptyOutline && !hasFilter
              ? 'btn btn-outline-aqua'
              : 'btn-link tw-py-0.5',
          )}
          fields={fields}
          onSelect={(newOperand) => {
            const newExpression = {
              conjunction: ExpressionConjunction.And,
              operands: [newOperand],
            }
            onChange({
              ...rootExpression,
              expressions: [...rootExpression.expressions, newExpression],
            })
            setLastOperandOpen(true)
          }}
          showArchivedField={showArchivedField}
        >
          <FontAwesomeIcon className='tw-mr-2' icon='plus' />
          Add a filter
        </NewOperandButton>
      </div>

      <If condition={shouldCreateSegment(rootExpression) && showSegmentAlert}>
        <Alert
          title='Keep it clean & tidy with Segments!'
          type='info'
          variant='small'
          canDismiss={true}
          className='tw-mt-12'
        >
          <div className='tw-flex tw-flex-col tw-gap-2'>
            <div>
              You've got a lot going on here. You might want to create a Segment
              to handle the complexity!
            </div>
            <Link
              className='tw-no-underline tw-hover:no-underline tw-font-normal'
              to={`/people?expression=${encodeBase64JSON(rootExpression)}`}
              target='_blank'
            >
              Save this filter into a Segment
            </Link>
          </div>
        </Alert>
      </If>
    </div>
  )
}
