import {
  DocumentsDateOperandField,
  DocumentsSelectOperandField,
  DocumentsTextOperandField,
  HandbookRecipientsDateOperandField,
  PeopleDateOperandField,
  PeopleSelectOperandField,
  PeopleTextOperandField,
} from '@blissbook/application/graph'
import {
  type Expression,
  type OperandValue,
  isOperandValueValid,
} from '@blissbook/lib/expression'
import { omitTypename } from '@blissbook/ui/lib/graphql'

export function mapOperandValueToInput(value: OperandValue) {
  const { type, ...operand } = value
  // biome-ignore lint/performance/noDelete: This is not going to affect performance
  if ('path' in operand && operand.path === null) delete operand.path
  return { [type]: omitTypename(operand) }
}

export type OperandValueInput = ReturnType<typeof mapOperandValueToInput>

function isValidEnum<T>(_enum: T, field: string) {
  return Object.values(_enum).includes(field as T)
}

// DocumentsOperand ----------------------------------------------------

export function isValidDocumentsOperand(value: OperandValue) {
  switch (value.type) {
    case 'date':
      return (
        isValidEnum(DocumentsDateOperandField, value.field) &&
        isOperandValueValid(value)
      )
    case 'handbooks':
      return isOperandValueValid(value)
    case 'select':
      return (
        isValidEnum(DocumentsSelectOperandField, value.field) &&
        isOperandValueValid(value)
      )
    case 'text':
      return (
        isValidEnum(DocumentsTextOperandField, value.field) &&
        isOperandValueValid(value)
      )
    default:
      return false
  }
}

export function isValidDocumentsExpression(expression: Expression) {
  return expression.operands.every(isValidDocumentsOperand)
}

// HandbookRecipientsOperand ----------------------------------------------------

export function isValidHandbookRecipientsOperand(value: OperandValue) {
  switch (value.type) {
    case 'date':
      return (
        isValidEnum(HandbookRecipientsDateOperandField, value.field) &&
        isOperandValueValid(value)
      )
    case 'handbooks':
      return false
    default:
      return isValidPeopleOperand(value)
  }
}

export function isValidHandbookRecipientsExpression(expression: Expression) {
  return expression.operands.every(isValidHandbookRecipientsOperand)
}

// PeopleOperand ----------------------------------------------------------------

export function isValidPeopleOperand(value: OperandValue) {
  switch (value.type) {
    case 'date':
      return (
        isValidEnum(PeopleDateOperandField, value.field) &&
        isOperandValueValid(value)
      )
    case 'groups':
      return isOperandValueValid(value)
    case 'handbooks':
      return isOperandValueValid(value)
    case 'managers':
      return isOperandValueValid(value)
    case 'savedSegments':
      return isOperandValueValid(value)
    case 'select':
      return (
        isValidEnum(PeopleSelectOperandField, value.field) &&
        isOperandValueValid(value)
      )
    case 'text':
      return (
        isValidEnum(PeopleTextOperandField, value.field) &&
        isOperandValueValid(value)
      )
    default:
      return false
  }
}

export function isValidPeopleExpression(expression: Expression) {
  return expression.operands.every(isValidPeopleOperand)
}
