import { OperandType, type OperandValue } from '@blissbook/lib/expression'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import type { OperandField } from '../operands'

export function buildUnknownField(value: OperandValue): OperandField {
  if (value.type === OperandType.Select) {
    return {
      type: OperandType.Select,
      field: value.field,
      path: value.path,
      label: value.path,
      Icon: (props) => (
        <FontAwesomeIcon {...props} icon={['far', 'user-large']} />
      ),
      inputType: 'typeahead',
      defaultValue: {
        field: 'metadata',
        path: value.path,
        type: OperandType.Select,
        isEvery: false,
        isNot: false,
        values: [],
      },
      searchOptions: async () => [],
      useOptions: () => [],
    }
  }
}
