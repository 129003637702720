import {
  type AudienceRootExpression,
  areRootExpressionsEqual,
} from '@blissbook/lib/expression'
import { decodeBase64JSON } from '@blissbook/ui/lib/encodeBase64JSON'
import type { Editor, Element } from 'ckeditor5'
import React from 'react'
import type { BlissbookEditor } from '../BlissbookEditor'
import { audienceExpressionAttributeKey } from '../plugins/AudienceExpression'

export type AudienceExpressionGroup = {
  audienceExpression: AudienceRootExpression
  audienceExpressionValue: string
  blocks: Element[]
  rect?: DOMRect
}

function getElementRect(element: Element, editor: Editor) {
  const { editing } = editor

  const viewElement = editing.mapper.toViewElement(element)
  if (!viewElement) return

  const domElement = editing.view.domConverter.mapViewToDom(viewElement)
  if (!domElement) return

  return domElement.getBoundingClientRect()
}

function adjustRect(rect: DOMRect, editorRect: DOMRect) {
  return new DOMRect(
    rect.x - editorRect.x,
    rect.y - editorRect.y,
    rect.width,
    rect.height,
  )
}

function mergeRects(rects: DOMRect[]) {
  let [rect, ...restRects] = rects
  if (!restRects.length) return rect

  for (const nextRect of restRects) {
    const left = Math.min(rect.left, nextRect.left)
    const top = Math.min(rect.top, nextRect.top)
    const width = Math.max(rect.right, nextRect.right) - left
    const height = Math.max(rect.bottom, nextRect.bottom) - top
    rect = new DOMRect(left, top, width, height)
  }

  return rect
}

/** Reduce elements into groups */
function getAudienceExpressionGroups(editor: BlissbookEditor) {
  // Determine the groups
  const root = editor.model.document.getRoot()
  const range = editor.model.createRangeIn(root)
  const groups: AudienceExpressionGroup[] = []
  for (const element of range.getItems()) {
    // Must be an element
    if (!element.is('element')) continue

    // Pull its value
    const audienceExpressionValue = element.getAttribute(
      audienceExpressionAttributeKey,
    ) as string | undefined
    if (!audienceExpressionValue) continue

    // If the blocks are adjacent, extend the rect
    const audienceExpression = decodeBase64JSON<AudienceRootExpression>(
      audienceExpressionValue,
    )
    const lastGroup = groups[groups.length - 1]
    const lastElement = lastGroup?.blocks.at(-1)
    if (
      lastElement?.nextSibling === element &&
      areRootExpressionsEqual(lastGroup?.audienceExpression, audienceExpression)
    ) {
      // Add elements to group
      lastGroup.blocks.push(element)
      continue
    }

    // Otherwise, create a new group
    groups.push({
      audienceExpression,
      audienceExpressionValue,
      blocks: [element],
    })
  }

  // Attach the rects
  const domRoot = editor.editing.view.getDomRoot()
  const editorRect = domRoot.getBoundingClientRect()
  for (const group of groups) {
    const rects = []
    for (const element of group.blocks) {
      const _rect = getElementRect(element, editor)
      if (!_rect) continue

      const rect = adjustRect(_rect, editorRect)
      rects.push(rect)
    }
    group.rect = mergeRects(rects)
  }

  return groups
}

export function useAudienceExpressionGroups(editor: BlissbookEditor) {
  return getAudienceExpressionGroups(editor)
}
