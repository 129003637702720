import React from 'react'

export function MultipleValueOperandView({
  children,
  icon,
  label,
  values,
}: {
  children: React.ReactNode
  icon: JSX.Element
  label: string
  values: string[]
}) {
  return (
    <div className='tw-rounded tw-border-2 tw-border-gray-200'>
      <div className='tw-flex tw-items-center tw-gap-1.5 tw-px-2.5 tw-py-1 tw-rounded-t tw-bg-gray-200'>
        {icon}
        <div className='tw-max-w-full ellipsis'>
          <span className='tw-font-semibold'>{label}</span> {children}
        </div>
      </div>
      <div className='tw-px-2.5 tw-py-2 tw-bg-gray-50 tw-space-y-0.5'>
        {values.map((value, index) => (
          <div key={index}>{value}</div>
        ))}
      </div>
    </div>
  )
}
