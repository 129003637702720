import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons/faClockRotateLeft'
import { faCommentDots } from '@fortawesome/pro-regular-svg-icons/faCommentDots'
import {
  type ButtonExecuteEvent,
  Collection,
  type ListDropdownItemDefinition,
  Plugin,
  ViewModel,
  addListToDropdown,
  createDropdown,
} from 'ckeditor5'
import { getIconPath } from './util'

export class HistoryDropdownUI extends Plugin {
  static get requires() {
    return ['RevisionHistory', 'CommentsArchiveUI']
  }

  init() {
    const { editor } = this
    const { t } = editor
    const accessibleLabel = t('History')
    const defaultTitle = t('History options')

    editor.ui.componentFactory.add('historyDropdown', (locale) => {
      const itemDefinitions = new Collection<ListDropdownItemDefinition>()

      const revisionHistoryDef: ListDropdownItemDefinition = {
        type: 'button',
        model: new ViewModel({
          class: 'ck-icon-fa',
          historyAction: 'revision',
          label: t('Revision History'),
          icon: getIconPath(faClockRotateLeft),
          role: 'menuitem',
          tooltipPosition: 'e',
          withText: true,
        }),
      }
      itemDefinitions.add(revisionHistoryDef)

      const commentsArchiveDef: ListDropdownItemDefinition = {
        type: 'button',
        model: new ViewModel({
          class: 'ck-icon-fa',
          historyAction: 'comments',
          label: t('Comments Archive'),
          icon: getIconPath(faCommentDots),
          role: 'menuitem',
          tooltipPosition: 'e',
          withText: true,
        }),
      }
      itemDefinitions.add(commentsArchiveDef)

      const dropdownView = createDropdown(locale)

      addListToDropdown(dropdownView, itemDefinitions, {
        ariaLabel: accessibleLabel,
        role: 'menu',
      })

      dropdownView.buttonView.set({
        ariaLabel: accessibleLabel,
        ariaLabelledBy: undefined,
        class: 'ck-dropdown__button ck-icon-fa',
        icon: getIconPath(faClock),
        isOn: false,
        label: defaultTitle,
        tooltip: true,
        withText: false,
      })

      dropdownView.extendTemplate({
        attributes: {
          class: ['ck-history-dropdown'],
        },
      })

      this.listenTo<ButtonExecuteEvent>(dropdownView, 'execute', (evt) => {
        const { historyAction } = evt.source as any

        if (historyAction === 'revision') {
          // @ts-ignore: https://ckeditor.com/docs/ckeditor5/latest/api/module_revision-history_revisionhistoryconfig-RevisionHistoryConfig.html#member-showRevisionViewerCallback
          editor.config.get('revisionHistory').showRevisionViewerCallback()
        } else if (historyAction === 'comments') {
          editor.fire('toggleCommentsArchive')
        }

        editor.editing.view.focus()
      })

      return dropdownView
    })
  }
}
