import { Command, type DocumentSelection } from 'ckeditor5'

function hasReadMore(selection: DocumentSelection) {
  for (const range of selection.getRanges()) {
    for (const item of range.getItems()) {
      if (item.is('element', 'readMore')) {
        return true
      }
    }
  }
  return false
}

export class WrapInReadMoreCommand extends Command {
  execute() {
    const { editor } = this
    const { model } = editor
    const { selection } = model.document
    model.change((writer) => {
      // Wrap selected blocks in readMoreContent
      const readMoreContent = writer.createElement('readMoreContent')
      const blocks = [...selection.getSelectedBlocks()]
      const firstBlock = blocks[0]
      const lastBlock = blocks.at(-1)
      const blockRange = writer.createRange(
        writer.createPositionBefore(firstBlock),
        writer.createPositionAfter(lastBlock),
      )
      writer.wrap(blockRange, readMoreContent)

      // Wrap readMoreContent in readMore
      const readMore = writer.createElement('readMore')
      const wrapRange = writer.createRange(
        writer.createPositionBefore(readMoreContent),
        writer.createPositionAfter(readMoreContent),
      )
      writer.wrap(wrapRange, readMore)

      // Focus back on editor
      this.editor.editing.view.focus()
    })
  }

  refresh() {
    const { model } = this.editor
    const { selection } = model.document

    const allowedIn = model.schema.findAllowedParent(
      selection.getFirstPosition(),
      'readMore',
    )

    this.isEnabled = allowedIn !== null && !hasReadMore(selection)
  }
}
