import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useOperandContext } from '../../OperandContext'

export type OperandDropdownOptionProps<T> = {
  checked?: (operand: T) => boolean
  children: React.ReactNode
  Icon: (props: { className: string }) => JSX.Element
}

export function OperandDropdownOption<T>({
  checked,
  children,
  Icon,
}: OperandDropdownOptionProps<T>) {
  const operand = useOperandContext()
  return (
    <div className='tw-flex tw-items-center tw-justify-between'>
      <div className='tw-flex tw-items-center tw-flex-1 tw-min-w-0'>
        <Icon className='dropdown-item-icon' />
        <div className='tw-flex-1 tw-min-w-0 ellipsis'>{children}</div>
      </div>

      <If condition={checked(operand as T)}>
        <FontAwesomeIcon
          className='dropdown-item-icon dropdown-item-icon-check'
          icon='check'
        />
      </If>
    </div>
  )
}
