import React, {
  type ReactNode,
  createContext,
  useContext,
  useMemo,
} from 'react'
import type { Group, Handbook, Person, SavedSegment } from './types'

export type ExpressionContextProps = {
  handbooksById: Map<number, Handbook>
  groupsById: Map<number, Group>
  peopleById: Map<number, Person>
  savedSegmentsById: Map<number, SavedSegment>
}

const ExpressionContext = createContext<ExpressionContextProps>({
  groupsById: new Map(),
  handbooksById: new Map(),
  peopleById: new Map(),
  savedSegmentsById: new Map(),
})

export type ExpressionContextProviderProps = Partial<ExpressionContextProps> & {
  children: ReactNode
}

export const ExpressionContextProvider = ({
  children,
  groupsById,
  handbooksById,
  peopleById,
  savedSegmentsById,
}: ExpressionContextProviderProps) => {
  const value = useMemo(
    () => ({ groupsById, handbooksById, peopleById, savedSegmentsById }),
    [groupsById, handbooksById, peopleById, savedSegmentsById],
  )

  return (
    <ExpressionContext.Provider value={value}>
      {children}
    </ExpressionContext.Provider>
  )
}

export function useGroupsById() {
  const { groupsById } = useContext(ExpressionContext)
  return groupsById
}

export function useGroups(groupIds: number[]): Group[] {
  const groupsById = useGroupsById()
  return useMemo(() => {
    const groups = groupIds.map(
      (id) =>
        groupsById?.get(id) || {
          id,
          name: `Group #${id}`,
        },
    )
    groups.sort((a, b) => a.name.localeCompare(b.name))
    return groups
  }, [groupIds, groupsById])
}

export function useHandbooksById() {
  const { handbooksById } = useContext(ExpressionContext)
  return handbooksById
}

export function usePeopleById() {
  const { peopleById } = useContext(ExpressionContext)
  return peopleById
}

export function usePeople(personIds: number[] | undefined): Person[] {
  const peopleById = usePeopleById()
  return useMemo(() => {
    if (!personIds) return []

    const people = personIds.map(
      (id) =>
        peopleById?.get(id) || {
          id,
          fullName: `Person #${id}`,
        },
    )
    people.sort((a, b) => a.fullName.localeCompare(b.fullName))
    return people
  }, [personIds, peopleById])
}

export function useSavedSegmentsById() {
  const { savedSegmentsById } = useContext(ExpressionContext)
  return savedSegmentsById
}

export function useSavedSegments(savedSegmentIds: number[]): SavedSegment[] {
  const savedSegmentsById = useSavedSegmentsById()
  return useMemo(() => {
    const savedSegments = savedSegmentIds.map(
      (id) =>
        savedSegmentsById?.get(id) || {
          id,
          name: `Saved Segment #${id}`,
        },
    )
    savedSegments.sort((a, b) => a.name.localeCompare(b.name))
    return savedSegments
  }, [savedSegmentIds, savedSegmentsById])
}
