import type { PeopleOperandValue } from '@blissbook/lib/expression'
import React from 'react'
import { usePeople } from '../ExpressionContext'
import { getMatchOption } from './GroupsOperandInput'
import { PeopleFieldIcon } from './PeopleOperandInput'
import { MultipleValueOperandView, SingleValueOperandView } from './components'

export type PeopleOperandViewProps = {
  inline?: boolean
  value: PeopleOperandValue
}

export function PeopleOperandView({ inline, value }: PeopleOperandViewProps) {
  const matchOption = getMatchOption(value)
  const people = usePeople(value.ids)

  if (people.length < 2) {
    const { isNot } = matchOption.value
    const [person] = people
    return (
      <div className={inline ? 'tw-inline-flex tw-max-w-full' : 'tw-flex'}>
        <SingleValueOperandView
          icon={<PeopleFieldIcon />}
          inline={inline}
          isInvalid={!person}
          label='Person'
        >
          {isNot ? 'is not' : 'is'} {person?.fullName}
        </SingleValueOperandView>
      </div>
    )
  }

  return (
    <MultipleValueOperandView
      icon={<PeopleFieldIcon />}
      label='People'
      values={people.map((p) => p.fullName)}
    >
      {matchOption.label}
    </MultipleValueOperandView>
  )
}
