import { getPeople } from '@blissbook/application/api/people'
import { useCallback } from 'react'

export type SearchPeopleArgs = {
  isManager?: boolean
  limit: number
}

export function useSearchPeople({ isManager, limit }: SearchPeopleArgs) {
  return useCallback(
    async (search: string) => {
      return getPeople({
        filter: { isManager, search },
        limit,
      })
    },
    [isManager],
  )
}
