import type { AudienceRootExpression } from '@blissbook/lib/expression'
import { useState } from 'react'
import type { AssetPickerContext } from './AssetPickerContext'

export type AudienceExpressionPickerValue = AudienceRootExpression | undefined

export type PickAudienceExpressionFunction = (
  initialValue?: AudienceExpressionPickerValue,
) => Promise<AudienceExpressionPickerValue>

export type AudienceExpressionPickerModalState = {
  initialValue?: AudienceExpressionPickerValue
  onClose: () => void
  onSubmit: (value: AudienceExpressionPickerValue) => void
}

export type AudienceExpressionPicker = {
  modalState: AudienceExpressionPickerModalState
  pickValue: PickAudienceExpressionFunction
}

export function useAudienceExpressionPicker(): AudienceExpressionPicker {
  const [modalState, setModalState] =
    useState<AudienceExpressionPickerModalState>()

  const pickValue = (initialValue?: AudienceExpressionPickerValue) =>
    new Promise<AudienceExpressionPickerValue>((resolve) => {
      setModalState({
        initialValue,
        onClose: () => {
          setModalState(undefined)
          resolve(undefined)
        },
        onSubmit: resolve,
      })
    })

  return { modalState, pickValue }
}
