import type { RootExpression } from '@blissbook/lib/expression'
import {
  ExpressionContextProvider,
  type OperandField,
  RootExpressionDropdownInput,
  RootExpressionInput,
  useExpressionIds,
} from '@blissbook/ui/lib/expression'
import type { ShowArchivedField } from '@blissbook/ui/lib/expression/fields'
import React from 'react'
import {
  useGroupsById,
  useHandbooksById,
  usePeopleById,
  useSavedSegmentsById,
} from './hooks'

export type PeopleExpressionInputProps = {
  className?: string
  compact?: boolean
  disabled?: boolean
  fields: OperandField[]
  onChange: (value: RootExpression) => void
  showArchivedField?: ShowArchivedField
  showEmployeeReporting?: boolean
  style?: React.CSSProperties
  value: RootExpression
  showSegmentAlert?: boolean
}

export function PeopleExpressionInput({
  compact,
  showEmployeeReporting,
  ...props
}: PeopleExpressionInputProps) {
  const { value } = props
  const { groupIds, handbookIds, personIds, savedSegmentIds } =
    useExpressionIds(value)
  const groupsById = useGroupsById(groupIds)
  const handbooksById = useHandbooksById(handbookIds)
  const peopleById = usePeopleById(personIds)
  const savedSegmentsById = useSavedSegmentsById(savedSegmentIds)
  return (
    <ExpressionContextProvider
      groupsById={groupsById}
      handbooksById={handbooksById}
      peopleById={peopleById}
      savedSegmentsById={savedSegmentsById}
    >
      {compact ? (
        <RootExpressionDropdownInput {...props} />
      ) : (
        <RootExpressionInput {...props} />
      )}
    </ExpressionContextProvider>
  )
}
