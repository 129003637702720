import type { OperandValue } from '@blissbook/lib/expression'
import { buildUnknownField } from '../fields'
import type { DateOperandField } from './DateOperandInput'
import type { GroupsOperandField } from './GroupsOperandInput'
import type { HandbooksOperandField } from './HandbooksOperandInput'
import type { ManagersOperandField } from './ManagersOperandInput'
import type { PeopleOperandField } from './PeopleOperandInput'
import type { SavedSegmentsOperandField } from './SavedSegmentsOperandInput'
import type { SelectOperandField } from './SelectOperandInput'
import type { TextOperandField } from './TextOperandInput'

export type OperandField =
  | DateOperandField
  | GroupsOperandField
  | HandbooksOperandField
  | ManagersOperandField
  | PeopleOperandField
  | SavedSegmentsOperandField
  | SelectOperandField
  | TextOperandField

export function getOperandFieldId(field: OperandField): string {
  const keys: string[] = [field.type]
  if ('field' in field) keys.push(field.field)
  if ('path' in field) keys.push(field.path)
  return keys.join('.')
}

export function isOperandFieldValue(field: OperandField, value: OperandValue) {
  // Check type
  if (field.type !== value.type) return false

  // Check field
  if (!('field' in field)) return true
  if (!('field' in value)) return false
  if (field.field !== value.field) return false

  // Check path
  if (!('path' in field)) return true
  if (!('path' in value)) return false
  if (field.path !== value.path) return false

  return true
}

export function findOrBuildField(
  fields: OperandField[],
  value: OperandValue,
): OperandField {
  // Try to find the field
  const field = fields.find((field) => isOperandFieldValue(field, value))
  if (field) return field

  // If not, build a new field, if possible, so the value can be displayed
  return buildUnknownField(value)
}
