import { Button, type ButtonProps, Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import React from 'react'

export function ToolbarButton({
  className,
  isOn = false,
  tooltip,
  ...props
}: ButtonProps & {
  isOn?: boolean
  tooltip: string
}) {
  return (
    <Tooltip content={tooltip}>
      <Button
        {...props}
        className={cx('ck ck-button', isOn ? 'ck-on' : 'ck-off', className)}
      />
    </Tooltip>
  )
}
