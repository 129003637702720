import { Command, type DocumentSelection } from 'ckeditor5'

export class UnwrapReadMoreCommand extends Command {
  execute() {
    const { model } = this.editor
    const { selection } = model.document
    model.change((writer) => {
      const readMore = selection.getSelectedElement()
      if (!readMore?.is('element', 'readMore')) return

      const readMoreChildren = [...readMore.getChildren()]
      if (readMoreChildren.length > 1) return

      const [readMoreContent] = readMoreChildren
      if (!readMoreContent?.is('element', 'readMoreContent')) return

      // Insert children in place of the block element
      const children = [...readMoreContent.getChildren()]
      for (const child of children) {
        writer.insert(child, writer.createPositionBefore(readMore))
      }

      // Remove the block element
      writer.remove(readMore)

      // Focus back on editor
      this.editor.editing.view.focus()
    })
  }
}
