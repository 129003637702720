import { faExpandWide } from '@fortawesome/pro-solid-svg-icons/faExpandWide'
import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { Element } from 'ckeditor5'
import React, { useState } from 'react'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { getAudienceExpression } from '../../plugins/AudienceExpression'
import { editAudienceExpression } from '../../plugins/ui/AudienceExpressionUI'
import { CKEditorSelection } from '../CKEditorSelection'
import { CKEditorBubbleToolbar } from './CKEditorBubbleToolbar'
import { ToolbarButton } from './ToolbarButton'

export function EditReadMoreToolbar({
  editor,
  element,
}: {
  editor: BlissbookEditor
  element: Element
}) {
  const { pickAudienceExpression } = editor
  const audienceExpression = getAudienceExpression([element])
  const [selectionNode, setSelectionNode] = useState<HTMLDivElement>()

  async function handleEditAudienceExpression() {
    await editAudienceExpression(editor)
  }

  function handleUnwrapReadMore() {
    editor.execute('unwrapReadMore')
  }

  return (
    <>
      <CKEditorBubbleToolbar
        className='ck ck-toolbar tw-py-1'
        editor={editor}
        offset={6}
        refEl={selectionNode}
      >
        {pickAudienceExpression && (
          <ToolbarButton
            isOn={!!audienceExpression}
            onClick={handleEditAudienceExpression}
            tooltip='Limit Access'
          >
            <FontAwesomeIcon icon={faLock} />
          </ToolbarButton>
        )}

        <ToolbarButton
          isOn
          onClick={handleUnwrapReadMore}
          tooltip='Unwrap Read More'
        >
          <FontAwesomeIcon icon={faExpandWide} />
        </ToolbarButton>
      </CKEditorBubbleToolbar>

      <CKEditorSelection editor={editor} ref={setSelectionNode} />
    </>
  )
}
