import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export type RemoveIconProps = React.HTMLProps<HTMLDivElement>

export function RemoveIcon({ className, ...props }: RemoveIconProps) {
  return (
    <div
      {...props}
      className={cx('btn-icon btn-danger tw-cursor-pointer', className)}
    >
      <FontAwesomeIcon icon='times' />
    </div>
  )
}
