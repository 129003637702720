import {
  type SavedSegment,
  useSavedSegments,
} from '@blissbook/application/graph'
import { useCallback, useMemo } from 'react'

function useOrderedSavedSegments() {
  const savedSegments = useSavedSegments()
  return useMemo(() => {
    const activeSavedSegments = savedSegments.filter((ss) => !ss.archivedAt)
    return activeSavedSegments.sort((a, b) => a.name.localeCompare(b.name))
  }, [savedSegments])
}

export type FilterSavedSegmentsArgs = {
  limit: number
  search: string
}

async function filterSavedSegments(
  savedSegments: SavedSegment[],
  { limit, search }: FilterSavedSegmentsArgs,
) {
  search = search.toLowerCase()

  const result: SavedSegment[] = []
  for (const savedSegment of savedSegments) {
    if (result.length >= limit) break
    if (savedSegment.name.toLowerCase().includes(search)) {
      result.push(savedSegment)
    }
  }
  return result
}

export type SearchSavedSegmentsArgs = Omit<FilterSavedSegmentsArgs, 'search'>

export function useSearchSavedSegments(args: SearchSavedSegmentsArgs) {
  const savedSegments = useOrderedSavedSegments()
  return useCallback(
    (search) => filterSavedSegments(savedSegments, { ...args, search }),
    [savedSegments],
  )
}
