import { useHandbooksById } from '@blissbook/application/hooks'
import { Tooltip } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pluralize from 'pluralize'
import React from 'react'

export type ComplianceIconProps = {
  fullName: string
  handbookRecipients?: {
    handbookId: number
    isSignatureRequired: boolean
  }[]
  isAudienceMember: boolean
  isSignatureRequired: boolean
}

export function ComplianceIcon({
  fullName,
  handbookRecipients,
  isAudienceMember,
  isSignatureRequired,
}: ComplianceIconProps) {
  const handbooksById = useHandbooksById()
  const nonCompliantHandbooks = handbookRecipients
    ?.filter((recipient) => recipient.isSignatureRequired)
    ?.map(({ handbookId }) => handbooksById[handbookId])

  return (
    <Choose>
      <When condition={!isAudienceMember}>
        <div className='tw-text-gray-400'>
          <FontAwesomeIcon icon={['far', 'dash']} />
        </div>
      </When>
      <When condition={nonCompliantHandbooks?.length > 0}>
        <Tooltip
          theme='infobox'
          content={
            <div className='tw-text-sm'>
              <div className='tw-text-gray-500 tw-mb-1'>
                {pluralize(
                  'unsigned document',
                  nonCompliantHandbooks.length,
                  true,
                )}
              </div>
              {nonCompliantHandbooks.map((handbook) => (
                <div
                  className='tw-flex tw-items-center tw-gap-2'
                  key={handbook.id}
                >
                  <ComplianceIcon
                    fullName={fullName}
                    isAudienceMember
                    isSignatureRequired
                  />
                  {handbook.name}
                </div>
              ))}
            </div>
          }
        >
          <div className='tw-text-red-500'>
            <FontAwesomeIcon icon={['far', 'times-circle']} />
          </div>
        </Tooltip>
      </When>
      <When condition={isSignatureRequired}>
        <Tooltip content={`${fullName}'s signature is required`}>
          <div className='tw-text-red-500'>
            <FontAwesomeIcon icon={['far', 'times-circle']} />
          </div>
        </Tooltip>
      </When>
      <Otherwise>
        <Tooltip content={`${fullName}'s signature is NOT required`}>
          <div className='tw-text-green-500'>
            <FontAwesomeIcon icon={['far', 'check-circle']} />
          </div>
        </Tooltip>
      </Otherwise>
    </Choose>
  )
}
