import type {
  PickAudienceExpressionFunction,
  PickImageFunction,
  PickMediaFunction,
  PickPdfFunction,
} from '@blissbook/application/editor'
import uiConfig from '@blissbook/ui-config'
import { CKEditorMediaProviders } from '@blissbook/ui/ckeditor/components'
import {
  Bold,
  type EditorConfig,
  Italic,
  List,
  type ToolbarConfig,
} from 'ckeditor5'
import type { MergeFieldsConfig } from 'ckeditor5-premium-features'
import { useEffect, useRef, useState } from 'react'
import { bundleVersion } from '../bundle/bundleVersion'
import type { InlineToolbarType } from '../components'
import { basePlugins, collabPlugins, defaultConfig } from '../plugins'
import { uiCollabPlugins, uiPlugins } from '../plugins/ui'

// Pull out the config
const licenseKey = uiConfig.ckeditor?.licenseKey
const baseTokenUrl = window.location.origin + '/ckeditor/token'
const webSocketUrl = uiConfig.ckeditor?.webSocketUrl

function formatTokenUrl(channelId: string | undefined) {
  if (!channelId) return baseTokenUrl
  return baseTokenUrl + '?channel=' + channelId
}

export type EditorConfigOptions = {
  balloonToolbar?: ToolbarConfig
  channelId?: string
  initialData?: string
  mergeFields?: MergeFieldsConfig
  pickAudienceExpression?: PickAudienceExpressionFunction
  pickImage?: PickImageFunction
  pickMedia?: PickMediaFunction
  pickPdf?: PickPdfFunction
}

export type RevisionHistoryState = {
  editorContainerRef: React.RefObject<HTMLDivElement>
  viewerContainerRef: React.RefObject<HTMLDivElement>
  viewerEditorElementRef: React.RefObject<HTMLDivElement>
  viewerSidebarContainerRef: React.RefObject<HTMLDivElement>
}

function useRevisionHistoryState(): RevisionHistoryState {
  const editorContainerRef = useRef(null)
  const viewerContainerRef = useRef(null)
  const viewerEditorElementRef = useRef(null)
  const viewerSidebarContainerRef = useRef(null)
  return {
    editorContainerRef,
    viewerContainerRef,
    viewerEditorElementRef,
    viewerSidebarContainerRef,
  }
}

export function useCKEditorState(options: EditorConfigOptions) {
  const {
    channelId,
    initialData,
    mergeFields,
    pickAudienceExpression,
    pickImage,
    pickMedia,
    pickPdf,
  } = options
  const sidebarRef = useRef(null)
  const revisionHistory = useRevisionHistoryState()
  const [inlineToolbarType, setInlineToolbarType] =
    useState<InlineToolbarType>()
  const toolbarRef = useRef(null)
  const tokenUrl = formatTokenUrl(channelId)

  const [isMounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)

    return () => {
      setMounted(false)
    }
  }, [])

  let editorConfig: EditorConfig = {
    ...defaultConfig,
    image: {
      toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageStyle:inline',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'imageShape:rounded',
        'imageShape:circle',
        '|',
        ...(pickImage ? ['editImage'] : []),
        'removeImage',
      ],
    },
    initialData,
    licenseKey,
    mediaEmbed: {
      toolbar: ['editMedia', 'removeMedia'],
      providers: CKEditorMediaProviders,
    },
    mergeFields,
    placeholder: 'Add your content here...',
    plugins: [...basePlugins, ...uiPlugins],
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableProperties',
        'tableCellProperties',
      ],
    },
  }

  const balloonToolbar = [
    'bold',
    'italic',
    'underline',
    'fontSize',
    'fontColor',
    'removeFormat',
    '|',
    'link',
  ]

  const toolbar = {
    items: [
      'headings',
      'bold',
      'italic',
      'underline',
      '|',
      'link',
      ...(pickImage ? ['insertBlissbookImage'] : []),
      ...(pickMedia ? ['insertMedia'] : []),
      ...(pickPdf ? ['insertPdf'] : []),
      'insertTable',
      'readMore',
      ...(pickAudienceExpression ? ['audienceExpression'] : []),
      '|',
      'alignment',
      'bulletedList',
      'numberedList',
      '|',
      'undo',
      'redo',
    ],
  }

  // If we are going real-time
  if (channelId) {
    editorConfig = {
      ...editorConfig,

      cloudServices: {
        bundleVersion,
        tokenUrl,
        webSocketUrl,
      },

      collaboration: {
        channelId,
      },

      comments: {
        editorConfig: {
          extraPlugins: [Bold, Italic, List],
        },
      },

      revisionHistory: {
        editorContainer: revisionHistory.editorContainerRef.current,
        resumeUnsavedRevision: false,
        viewerContainer: revisionHistory.viewerContainerRef.current,
        viewerEditorElement: revisionHistory.viewerEditorElementRef.current,
        viewerSidebarContainer:
          revisionHistory.viewerSidebarContainerRef.current,
      },

      sidebar: {
        container: sidebarRef.current,
      },
    }

    editorConfig.plugins.push(...collabPlugins, ...uiCollabPlugins)

    toolbar.items.push('|', 'historyDropdown')
    balloonToolbar.push('|', 'addComment')
  }

  balloonToolbar.push('|', 'annotation')

  return {
    config: {
      ...editorConfig,
      balloonToolbar: options.balloonToolbar || balloonToolbar,
      toolbar,
    },
    inlineToolbarType,
    isMounted,
    setInlineToolbarType,
    revisionHistory,
    sidebarRef,
    toolbarRef,
  }
}
