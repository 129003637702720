import { faArchive } from '@fortawesome/pro-regular-svg-icons/faArchive'
import { ButtonView, Plugin } from 'ckeditor5'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { getIconPath } from './util'

export class CommentArchiveUI extends Plugin {
  static get requires() {
    return ['CommentsArchiveUI']
  }

  init() {
    const editor = this.editor as BlissbookEditor
    const { t } = editor

    editor.ui.componentFactory.add('commentsArchive', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faArchive),
        label: t('Comments archive'),
        tooltip: true,
      })

      view.on('execute', () => {
        editor.fire('toggleCommentsArchive')
      })

      return view
    })
  }
}
