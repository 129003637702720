import React, { useMemo } from 'react'
import type { OperandField } from '../operands'
import {
  type BuildGroupsFieldArgs,
  type BuildMetadataFieldArgs,
  type BuildPeopleFieldArgs,
  type BuildSavedSegmentsFieldArgs,
  buildGroupsField,
  buildMetadataField,
  buildPeopleField,
  buildSavedSegmentsField,
} from './peopleFields'

export type GetAudienceFieldsArgs = Partial<
  BuildGroupsFieldArgs &
    BuildMetadataFieldArgs &
    BuildPeopleFieldArgs &
    BuildSavedSegmentsFieldArgs
> & {
  /** Dynamic metadata keys */
  metadataKeys?: string[]
}

export function getAudienceFields({
  metadataKeys,
  searchGroups,
  searchMetadataValues,
  searchPeople,
  searchSavedSegments,
}: GetAudienceFieldsArgs): OperandField[] {
  const fields = []

  if (searchSavedSegments) {
    const savedSegmentsField = buildSavedSegmentsField({ searchSavedSegments })
    fields.push(savedSegmentsField)
  }

  if (searchGroups) {
    const groupsField = buildGroupsField({ searchGroups })
    fields.push(groupsField)
  }

  if (searchPeople) {
    const peopleField = buildPeopleField({ searchPeople })
    fields.push(peopleField)
  }

  if (metadataKeys && searchMetadataValues) {
    for (const metadataKey of metadataKeys) {
      const metadataField = buildMetadataField(metadataKey, {
        searchMetadataValues,
      })
      fields.push(metadataField)
    }
  }

  return fields
}

export function useAudienceFields(
  args?: GetAudienceFieldsArgs,
  deps: any[] = [],
) {
  return useMemo(() => getAudienceFields(args), deps)
}
