import { cx } from '@emotion/css'
import React from 'react'

export function SingleValueOperandView({
  children,
  icon,
  inline,
  isInvalid,
  label,
}: {
  children: React.ReactNode
  icon: JSX.Element
  inline?: boolean
  isInvalid?: boolean
  label: string
}) {
  return (
    <div
      className={cx(
        'tw-flex tw-items-center tw-gap-1.5 tw-px-3 tw-py-1.5 tw-rounded',
        isInvalid ? 'tw-bg-red-50 tw-text-red-700' : 'tw-bg-gray-200',
        inline && 'tw-max-w-full',
      )}
    >
      {icon}
      <div className={cx(inline && 'tw-min-w-0 ellipsis')}>
        <span className='tw-font-semibold'>{label}</span> {children}
      </div>
    </div>
  )
}
