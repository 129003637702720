import {
  addIdsToRootExpression,
  emptyRootExpression,
} from '@blissbook/lib/expression'
import { ExpressionConjunction } from '@blissbook/ui/application/graph'
import { Button, Dropdown } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useState } from 'react'
import { NewOperandButton } from './NewOperandButton'
import {
  RootExpressionInput,
  type RootExpressionInputProps,
} from './RootExpressionInput'
import { findOrBuildField } from './operands'

type RootExpressionDropdownInputProps = Omit<
  RootExpressionInputProps,
  'addButtonEmptyOutline' | 'children'
>

export function RootExpressionDropdownInput(
  props: RootExpressionDropdownInputProps,
) {
  const { fields, onChange, showArchivedField, value } = props
  const [lastOperandOpen, setLastOperandOpen] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const rootExpression = useMemo(() => addIdsToRootExpression(value), [value])
  const hasExpression = rootExpression.expressions.length > 0
  const hasFilter = hasExpression || showArchivedField?.value

  const filterLabels = new Set<string>()
  if (showArchivedField?.value) filterLabels.add('Archived')
  for (const expression of rootExpression.expressions) {
    for (const operand of expression.operands) {
      const field = findOrBuildField(fields, operand)
      filterLabels.add(field?.label)
    }
  }

  const className = cx('btn tw-border-transparent tw-whitespace-nowrap')
  const icon = (
    <FontAwesomeIcon className='tw-mr-2' icon={['far', 'bars-filter']} />
  )

  if (!hasFilter) {
    return (
      <NewOperandButton
        className={cx(className, 'btn-input')}
        fields={fields}
        onSelect={(newOperand) => {
          const newExpression = {
            conjunction: ExpressionConjunction.And,
            operands: [newOperand],
          }
          onChange({
            ...rootExpression,
            expressions: [...rootExpression.expressions, newExpression],
          })
          setOpen(true)
          setLastOperandOpen(true)
        }}
        showArchivedField={
          showArchivedField && {
            ...showArchivedField,
            onChange: (value) => {
              showArchivedField.onChange(value)
              setOpen(true)
            },
          }
        }
      >
        {icon} {'Filter'}
      </NewOperandButton>
    )
  }

  function resetFilter() {
    onChange(emptyRootExpression)
    showArchivedField?.onChange(false)
  }

  return (
    <Dropdown.Provider isOpen={isOpen} setOpen={setOpen}>
      <Dropdown.ToggleButton
        caret={false}
        className={cx('btn btn-hover-input', hasFilter && 'active', className)}
        color='hover-input'
      >
        {icon}

        <span className='tw-mr-1 tw-inline xl:tw-hidden'>
          {filterLabels.size}
        </span>
        <span className='tw-mr-1 tw-hidden xl:tw-inline 2xl:tw-hidden'>
          Filter: {[...filterLabels].join(', ')}
        </span>
        <span className='tw-mr-1 tw-hidden xl:tw-hidden 2xl:tw-inline'>
          Filtered by: {[...filterLabels].join(', ')}
        </span>

        <Button
          className='btn-icon btn-hover-danger'
          onClick={(event) => {
            event.stopPropagation()
            resetFilter()
          }}
        >
          <FontAwesomeIcon className='tw-ml-1.5' icon='times' />
        </Button>
      </Dropdown.ToggleButton>
      <Dropdown.Menu
        className='tw-p-3'
        strategy='fixed'
        style={{ maxWidth: 1000 }}
      >
        <RootExpressionInput
          {...props}
          className='tw-mb-2'
          forceLastOperandOpen={lastOperandOpen}
          onCloseOperand={() => {
            setLastOperandOpen(false)
          }}
        />

        <div className='tw-mb-2 tw-h-px tw-bg-gray-100' />

        <div className='tw-flex tw-items-center tw-justify-between'>
          <Button color='primary' onClick={() => setOpen(false)}>
            Done
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
