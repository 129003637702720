import type { SelectOperandValue } from '@blissbook/lib/expression'
import React from 'react'
import {
  type CheckboxSelectOperandField,
  CheckboxSelectOperandInput,
} from './CheckboxSelectOperandInput'
import {
  type RadioSelectOperandField,
  RadioSelectOperandInput,
} from './RadioSelectOperandInput'
import {
  type TypeaheadSelectOperandField,
  TypeaheadSelectOperandInput,
  type TypeaheadSelectOperandOption,
} from './TypeaheadSelectOperandInput'

export type {
  CheckboxSelectOperandField,
  RadioSelectOperandField,
  TypeaheadSelectOperandField,
  TypeaheadSelectOperandOption,
}

export type SelectOperandField =
  | CheckboxSelectOperandField
  | RadioSelectOperandField
  | TypeaheadSelectOperandField

export type SelectOperandInputProps = {
  className?: string
  field: SelectOperandField
  forceOpen?: boolean
  isOpen: boolean
  onChange: (value: SelectOperandValue) => void
  onRemove: () => void
  setOpen: (isOpen: boolean) => void
  value: SelectOperandValue
}

export function SelectOperandInput({
  field,
  ...props
}: SelectOperandInputProps) {
  switch (field.inputType) {
    case 'checkbox':
      return (
        <CheckboxSelectOperandInput
          {...props}
          field={field as CheckboxSelectOperandField}
        />
      )
    case 'radio':
      return (
        <RadioSelectOperandInput
          {...props}
          field={field as RadioSelectOperandField}
        />
      )
    case 'typeahead':
      return (
        <TypeaheadSelectOperandInput
          {...props}
          field={field as TypeaheadSelectOperandField}
        />
      )
  }
}
