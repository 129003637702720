import type { SelectOperandValue } from '@blissbook/lib/expression'
import { Checkbox, Dropdown } from '@blissbook/ui/lib'
import React from 'react'
import type { SelectOperandInputProps } from './SelectOperandInput'
import {
  OperandDropdownFooter,
  OperandDropdownToggleButton,
} from './components'
import type { BaseOperandField } from './types'

type SelectOperandOption = {
  id: string
  label: string
}

export type CheckboxSelectOperandField = BaseOperandField &
  Pick<SelectOperandValue, 'type' | 'field' | 'path'> & {
    defaultValue: SelectOperandValue
    heading?: string
    inputType: 'checkbox'
    options: SelectOperandOption[]
  }

export type CheckboxSelectOperandInputProps = SelectOperandInputProps & {
  field: CheckboxSelectOperandField
}

export function CheckboxSelectOperandInput({
  className,
  field,
  forceOpen,
  onChange,
  onRemove,
  value,
  ...props
}: CheckboxSelectOperandInputProps) {
  const { setOpen } = props
  const { heading, options } = field
  const { values } = value
  const isInvalid = values.length === 0
  const activeOptions = options.filter((option) => values.includes(option.id))
  return (
    <Dropdown.Provider {...props}>
      <OperandDropdownToggleButton
        className={className}
        icon={<field.Icon />}
        isInvalid={isInvalid && !forceOpen}
        label={field.label}
        onRemove={onRemove}
      >
        is {activeOptions.map((option) => option.label).join(', ')}
      </OperandDropdownToggleButton>

      <Dropdown.Menu
        className='tw-flex tw-flex-col tw-gap-2 tw-p-3'
        maxHeight={400}
      >
        {heading && <div className='tw-text-gray-500'>{heading}</div>}

        {options.map((option) => {
          const checked = values.includes(option.id)
          return (
            <Checkbox
              id={`${field.field}-${option.id}`}
              disabled={checked && values.length === 1}
              key={option.id}
              label={option.label}
              onChangeValue={(newValue) => {
                let newValues = [...values]
                if (newValue) {
                  newValues.push(option.id)
                } else {
                  newValues = newValues.filter((v) => v !== option.id)
                }
                onChange({ ...value, values: newValues })
              }}
              value={checked}
            />
          )
        })}

        <OperandDropdownFooter onClose={() => setOpen(false)} />
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
