import type { Expression, OperandValue } from '@blissbook/lib/expression'
import { ExpressionConjunction } from '@blissbook/ui/application/graph'
import { Dropdown } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Tooltip } from '../tooltip'
import { NewOperandButton } from './NewOperandButton'
import { OperandContextProvider } from './OperandContext'
import { OperandInput } from './OperandInput'
import { type OperandField, findOrBuildField } from './operands'
import { OperandDropdownToggleButton } from './operands/components'

export type ExpressionInputProps = {
  fields: OperandField[]
  forceLastOperandOpen: boolean
  onChange: (value: Expression) => void
  onCloseOperand: () => void
  value: Expression
}

export function ExpressionInput({
  fields,
  forceLastOperandOpen,
  onChange,
  onCloseOperand,
  value,
}: ExpressionInputProps) {
  const [_forceLastOperandOpen, setForceLastOperandOpen] = useState(false)
  forceLastOperandOpen ||= _forceLastOperandOpen

  function buildNewValue(): Expression {
    return {
      ...value,
      operands: [...value.operands],
    }
  }

  function handleNewOperand(newOperand: OperandValue) {
    const newValue = buildNewValue()
    newValue.operands.push(newOperand)
    onChange(newValue)
    setForceLastOperandOpen(true)
  }

  let isFirstOperand = true
  return (
    <>
      {value.operands.map((operand, index) => {
        const field = findOrBuildField(fields, operand)
        if (!field) return null

        const showConjunction = !isFirstOperand
        isFirstOperand = false
        const isValidField = fields.includes(field)
        const isLastOperand = index === value.operands.length - 1
        const key = `${isValidField ? 'valid' : 'invalid'}-${operand.id}`
        return (
          <OperandContextProvider key={key} value={operand}>
            {showConjunction && (
              <Dropdown.Provider>
                <OperandDropdownToggleButton
                  className='tw-rounded-none tw-mb-2 tw-mr-0.5'
                  caret={false}
                >
                  {value.conjunction}
                </OperandDropdownToggleButton>
                <Dropdown.Menu>
                  {Object.values(ExpressionConjunction).map((conjunction) => (
                    <Dropdown.Item
                      key={conjunction}
                      onClick={() => {
                        onChange({ ...value, conjunction })
                      }}
                    >
                      {conjunction}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown.Provider>
            )}

            <OperandInput
              className={`tw-mb-2 tw-mr-0.5 ${
                showConjunction ? 'tw-rounded-none' : 'tw-rounded-r-none'
              }`}
              field={field}
              forceOpen={isLastOperand && forceLastOperandOpen}
              onChange={(newOperand) => {
                const newValue = buildNewValue()
                newValue.operands[index] = newOperand
                onChange(newValue)
              }}
              onClose={() => {
                setForceLastOperandOpen(false)
                onCloseOperand()
              }}
              onRemove={() => {
                const newValue = buildNewValue()
                newValue.operands.splice(index, 1)
                onChange(newValue)
              }}
              value={operand}
            />
          </OperandContextProvider>
        )
      })}

      <Tooltip content='Add a filter to this group'>
        <NewOperandButton
          className='btn btn-secondary tw-mb-2 tw-mr-2 tw-px-2 tw-rounded-l-none'
          fields={fields}
          onSelect={handleNewOperand}
        >
          <FontAwesomeIcon className='tw-my-0.5' icon='plus' />
        </NewOperandButton>
      </Tooltip>
    </>
  )
}
