import type { OperandValue } from '@blissbook/lib/expression'
import React from 'react'
import {
  GroupsOperandView,
  PeopleOperandView,
  SavedSegmentsOperandView,
  SelectOperandView,
} from './operands'

export type OperandViewProps = {
  inline?: boolean
  value: OperandValue
}

export function OperandView({ value, ...props }: OperandViewProps) {
  const { inline } = props
  switch (value.type) {
    case 'groups':
      return <GroupsOperandView {...props} value={value} />
    case 'people':
      return <PeopleOperandView {...props} value={value} />
    case 'savedSegments':
      return <SavedSegmentsOperandView {...props} value={value} />
    case 'select':
      return <SelectOperandView {...props} value={value} />
    default:
      return (
        <div className={inline && 'tw-inline-flex tw-min-w-0 ellipsis'}>
          {JSON.stringify(value)}
        </div>
      )
  }
}
