import { faLock } from '@fortawesome/pro-solid-svg-icons/faLock'
import { ButtonView, Plugin } from 'ckeditor5'
import type { BlissbookEditor } from '../../BlissbookEditor'
import {
  type SetAudienceExpressionCommand,
  getSelectedAudienceExpression,
} from '../AudienceExpression'
import { getIconPath } from './util'

export async function editAudienceExpression(editor: BlissbookEditor) {
  const command: SetAudienceExpressionCommand = editor.commands.get(
    'setAudienceExpression',
  )

  // If there is an existing audience expression, clear it
  const { selection } = editor.model.document
  const prevAudienceExpression = getSelectedAudienceExpression(selection)
  if (prevAudienceExpression) {
    command.execute({ audienceExpression: undefined })
    editor.focus()
    return
  }

  // Otherwise, pick an audience expression via modal
  const audienceExpression = await editor.pickAudienceExpression(
    prevAudienceExpression,
  )
  if (audienceExpression) {
    command.execute({ audienceExpression })
  }

  editor.focus()
}

export class AudienceExpressionUI extends Plugin {
  init() {
    const editor = this.editor as BlissbookEditor
    const { t } = editor

    editor.ui.componentFactory.add('audienceExpression', (locale) => {
      const buttonView = new ButtonView(locale)
      const command: SetAudienceExpressionCommand = editor.commands.get(
        'setAudienceExpression',
      )

      buttonView.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faLock),
        label: t('Limit Access'),
        tooltip: true,
      })

      buttonView.bind('isEnabled').to(command, 'isEnabled')

      buttonView
        .bind('isOn')
        .to(command, 'value', (value) => value !== undefined)

      // Callback executed once the button is clicked
      buttonView.on('execute', async () => {
        await editAudienceExpression(editor)
      })

      return buttonView
    })
  }
}
