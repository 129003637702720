import {
  type OperandType,
  type PeopleOperandValue,
  isPeopleOperandValueValid,
} from '@blissbook/lib/expression'
// @ts-ignore: WIP imports
import { Dropdown, SearchInput } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef } from 'react'
import { usePeople } from '../ExpressionContext'
import type { Person } from '../types'
import { renderMaxValues } from './GroupsOperandInput'
import {
  OperandDropdownFooter,
  OperandDropdownOption,
  OperandDropdownToggleButton,
  RemoveIcon,
} from './components'
import type { BaseOperandField } from './types'

type MatchOptionValue = {
  isEvery: boolean
  isNot: boolean
}

type MatchOption = {
  label: string
  hasValues: boolean
  value: MatchOptionValue
}

const allMatchOptions: MatchOption[] = [
  {
    label: 'includes any matching',
    hasValues: true,
    value: { isNot: false, isEvery: false },
  },
  {
    label: 'includes all matching',
    hasValues: true,
    value: { isNot: false, isEvery: true },
  },
  {
    label: 'excludes any matching',
    hasValues: true,
    value: { isNot: true, isEvery: false },
  },
  {
    label: 'excludes all matching',
    hasValues: true,
    value: { isNot: true, isEvery: true },
  },
  {
    label: 'has any value',
    hasValues: false,
    value: { isNot: false, isEvery: false },
  },
  {
    label: 'is unknown',
    hasValues: false,
    value: { isNot: true, isEvery: false },
  },
]

function getMatchOptions({
  allowAny,
  allowEvery,
}: {
  allowAny?: boolean
  allowEvery?: boolean
} = {}) {
  return allMatchOptions.filter((option) => {
    if (!option.hasValues) return allowAny
    if (option.value.isEvery) return allowEvery
    return true
  })
}

function getMatchOption(
  options: MatchOption[],
  value: PeopleOperandValue,
): MatchOption | undefined {
  if (value.ids) {
    return options.find(
      (option) =>
        option.hasValues === true &&
        option.value.isEvery === value.isEvery &&
        option.value.isNot === value.isNot,
    )
  }

  return options.find(
    (option) =>
      option.hasValues === false && option.value.isNot === value.isNot,
  )
}

type PersonOption = {
  children: React.ReactNode
  key: string
  label: string
  value: Person
}

export function renderPeople(people: Person[], maxValues?: number) {
  const values = people.map((person) => person.fullName)
  return renderMaxValues(values, maxValues)
}

export type PeopleOperandField = BaseOperandField & {
  type: OperandType.People
  allowAny?: boolean
  allowEvery?: boolean
  defaultValue: PeopleOperandValue
  searchPeople: (text: string) => Promise<Person[]>
}

export const PeopleFieldIcon: PeopleOperandField['Icon'] = (props) => (
  <FontAwesomeIcon {...props} icon={['far', 'user']} />
)

export type PeopleOperandInputProps = {
  className?: string
  field: PeopleOperandField
  forceOpen?: boolean
  isOpen: boolean
  onChange: (value: PeopleOperandValue) => void
  onRemove: () => void
  setOpen: (isOpen: boolean) => void
  value: PeopleOperandValue
}

export function PeopleOperandInput({
  className,
  field,
  forceOpen,
  onChange,
  onRemove,
  setOpen,
  value,
  ...props
}: PeopleOperandInputProps) {
  const { searchPeople } = field
  const isValid = isPeopleOperandValueValid(value)
  const matchOptions = getMatchOptions(field)
  const matchOption = getMatchOption(matchOptions, value)

  const displayLabel = matchOption?.label ?? '--- Select ---'

  const people = usePeople(value.ids)

  const personIdsRef = useRef<number[]>([])
  if (value.ids) personIdsRef.current = value.ids
  const personIds = personIdsRef.current

  function handleSetOpen(isOpen: boolean) {
    setOpen(isOpen)
  }

  async function getPersonOptions(text: string) {
    const people = await searchPeople(text)
    return people.map((person) => ({
      children: (
        <OperandDropdownOption
          checked={(operand: PeopleOperandValue) =>
            operand.ids?.includes(person.id)
          }
          Icon={(props) => (
            <FontAwesomeIcon {...props} icon={['far', 'user-friends']} />
          )}
        >
          {person.fullName}
        </OperandDropdownOption>
      ),
      key: person.id,
      value: person,
    }))
  }

  function handleAddPersonId(personId: number) {
    onChange({ ...value, ids: [...personIds, personId] })
  }

  function handleRemovePersonId(personId: number) {
    const newPersonIds = personIds.filter((id) => id !== personId)
    onChange({ ...value, ids: newPersonIds })
  }

  return (
    <Dropdown.Provider {...props} setOpen={handleSetOpen}>
      <OperandDropdownToggleButton
        className={className}
        icon={<field.Icon />}
        isInvalid={!isValid && !forceOpen}
        label={field.label}
        onRemove={onRemove}
      >
        {displayLabel}{' '}
        <span className='ellipsis' title={renderPeople(people)}>
          {renderPeople(people, 3)}
        </span>
      </OperandDropdownToggleButton>

      <Dropdown.Menu
        className='tw-flex tw-flex-col tw-overflow-visible tw-space-y-2 tw-p-3'
        maxHeight={400}
      >
        <div className='tw-flex tw-gap-2 tw-items-center'>
          <Dropdown.Provider>
            <Dropdown.ToggleButton
              className='btn btn-input'
              style={{ width: 215 }}
            >
              {displayLabel}
            </Dropdown.ToggleButton>
            <Dropdown.Menu className='tw-p-3' sameWidth>
              {matchOptions.map((option, index) => (
                <Dropdown.Item
                  active={option === matchOption}
                  checked={option === matchOption}
                  key={index}
                  onClick={() => {
                    const { hasValues } = option
                    onChange({
                      ...value,
                      ...option.value,
                      ids: hasValues ? personIds : undefined,
                    })
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Provider>

          <SearchInput
            autoFocus={people.length === 0}
            className='tw-w-64'
            dropdownWidth={400}
            getOptions={getPersonOptions}
            minLength={0}
            noClearValueOnSelect
            onSelect={(option: PersonOption) => {
              const person = option.value
              if (personIds?.includes(person.id)) {
                handleRemovePersonId(person.id)
              } else {
                handleAddPersonId(person.id)
              }
            }}
            placeholder='Search for people...'
          />
        </div>

        {people.length > 0 && (
          <div className='tw-flex-1 tw-flex tw-flex-col tw-overflow-auto'>
            {people.map((person, index) => (
              <div
                className='tw-flex tw-items-center tw-justify-between tw-text-gray-600 hover:tw-text-gray-800 tw-px-2 tw-py-2 tw-text-base tw-group'
                key={index}
              >
                {person.fullName}
                <RemoveIcon
                  className='tw-invisible group-hover:tw-visible'
                  onClick={() => {
                    handleRemovePersonId(person.id)
                  }}
                />
              </div>
            ))}
          </div>
        )}

        <OperandDropdownFooter onClose={() => setOpen(false)} />
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
