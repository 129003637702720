import {
  type ButtonExecuteEvent,
  Collection,
  type HeadingCommand,
  type ListDropdownItemDefinition,
  type ParagraphCommand,
  Plugin,
  ViewModel,
  addListToDropdown,
  createDropdown,
} from 'ckeditor5'
import { renderKeyboardShortcut } from '../../../lib/keyboard'
import {
  type AdjustHeadingsCommand,
  MAX_HEADING_LEVEL,
  MIN_HEADING_LEVEL,
} from '../Headings/AdjustHeadingsCommand'

export class HeadingsUI extends Plugin {
  init() {
    const { editor } = this
    const { t } = editor
    const accessibleLabel = t('Heading')
    const adjustHeadingCommand = editor.commands.get(
      'adjustHeadings',
    ) as AdjustHeadingsCommand
    const headingCommand: HeadingCommand = editor.commands.get('heading')
    const paragraphCommand: ParagraphCommand = editor.commands.get('paragraph')

    editor.keystrokes.set('Ctrl+Alt+0', (_data, cancel) => {
      cancel()
      headingCommand.execute({ value: 'paragraph' })
    })

    for (let level = MIN_HEADING_LEVEL; level <= MAX_HEADING_LEVEL; level++) {
      editor.keystrokes.set(`Ctrl+Alt+${level}`, (_data, cancel) => {
        cancel()
        headingCommand.execute({ value: `heading${level}` })
      })
    }

    // Decrease heading level with minus
    editor.keystrokes.set(['Ctrl', 'Alt', 189], (_data, cancel) => {
      cancel()
      editor.execute('adjustHeadings', { delta: -1 })
    })

    // Increase heading level with plus
    editor.keystrokes.set(['Ctrl', 'Alt', 187], (_data, cancel) => {
      cancel()
      editor.execute('adjustHeadings', { delta: 1 })
    })

    editor.ui.componentFactory.add('headings', (locale) => {
      const titles: Record<string, string> = {}
      const itemDefinitions: Collection<ListDropdownItemDefinition> =
        new Collection()
      const commands = [headingCommand, paragraphCommand]

      // Add the paragraph option
      const paragraphDef: ListDropdownItemDefinition = {
        type: 'button',
        model: new ViewModel({
          commandName: 'paragraph',
          label: 'Paragraph',
          role: 'menuitemradio',
          tooltip: renderKeyboardShortcut('Mod-Alt-0'),
          tooltipPosition: 'e',
          withText: true,
        }),
      }

      paragraphDef.model.bind('isOn').to(paragraphCommand, 'value')
      itemDefinitions.add(paragraphDef)
      titles.paragraph = 'Paragraph'

      // Add the heading options
      for (let level = MIN_HEADING_LEVEL; level <= MAX_HEADING_LEVEL; level++) {
        const label = `Heading ${level}`
        const model = `heading${level}`

        const headingDef: ListDropdownItemDefinition = {
          type: 'button',
          model: new ViewModel({
            commandName: 'heading',
            commandParams: { value: model },
            label,
            role: 'menuitemradio',
            tooltip: renderKeyboardShortcut(`Mod-Alt-${level}`),
            tooltipPosition: 'e',
            withText: true,
          }),
        }

        headingDef.model
          .bind('isOn')
          .to(headingCommand, 'value', (value) => value === model)
        itemDefinitions.add(headingDef)
        titles[model] = label
      }

      itemDefinitions.add({
        type: 'separator',
      })

      const raiseHeadingsDef: ListDropdownItemDefinition = {
        type: 'button',
        model: new ViewModel({
          commandName: 'adjustHeadings',
          commandParams: { delta: -1 },
          label: 'Raise all Heading Levels',
          tooltip: renderKeyboardShortcut(['Mod', 'Alt', '-']),
          tooltipPosition: 'e',
          withText: true,
        }),
      }
      raiseHeadingsDef.model
        .bind('isEnabled')
        .to(
          adjustHeadingCommand,
          'value',
          (value) => value?.minHeadingLevel > MIN_HEADING_LEVEL,
        )
      itemDefinitions.add(raiseHeadingsDef)

      const lowerHeadingsDef: ListDropdownItemDefinition = {
        type: 'button',
        model: new ViewModel({
          commandName: 'adjustHeadings',
          commandParams: { delta: 1 },
          label: 'Lower all Heading Levels',
          tooltip: renderKeyboardShortcut(['Mod', 'Alt', '+']),
          tooltipPosition: 'e',
          withText: true,
        }),
      }
      lowerHeadingsDef.model
        .bind('isEnabled')
        .to(
          adjustHeadingCommand,
          'value',
          (value) => value?.maxHeadingLevel < MAX_HEADING_LEVEL,
        )
      itemDefinitions.add(lowerHeadingsDef)

      const dropdownView = createDropdown(locale)
      addListToDropdown(dropdownView, itemDefinitions, {
        ariaLabel: accessibleLabel,
        role: 'menu',
      })

      dropdownView.buttonView.set({
        ariaLabel: accessibleLabel,
        ariaLabelledBy: undefined,
        isOn: false,
        withText: true,
      })

      dropdownView.extendTemplate({
        attributes: {
          class: ['ck-heading-dropdown'],
        },
      })

      dropdownView
        .bind('isEnabled')
        .toMany(commands, 'isEnabled', (...areEnabled) => {
          return areEnabled.some((isEnabled) => isEnabled)
        })

      dropdownView.buttonView
        .bind('label')
        .to(
          headingCommand,
          'value',
          paragraphCommand,
          'value',
          (heading, paragraph) => {
            const whichModel = paragraph ? 'paragraph' : heading

            if (typeof whichModel === 'boolean') {
              return titles.paragraph
            }

            // If none of the commands is active, display default title.
            if (!titles[whichModel]) {
              return titles.paragraph
            }

            return titles[whichModel]
          },
        )

      dropdownView.buttonView
        .bind('ariaLabel')
        .to(
          headingCommand,
          'value',
          paragraphCommand,
          'value',
          (heading, paragraph) => {
            const whichModel = paragraph ? 'paragraph' : heading

            if (typeof whichModel === 'boolean') {
              return accessibleLabel
            }

            // If none of the commands is active, display default title.
            if (!titles[whichModel]) {
              return accessibleLabel
            }

            return `${titles[whichModel]}, ${accessibleLabel}`
          },
        )

      // Execute command when an item from the dropdown is selected.
      this.listenTo<ButtonExecuteEvent>(dropdownView, 'execute', (evt) => {
        const { commandName, commandParams } = evt.source as any
        editor.execute(commandName, commandParams)
        editor.editing.view.focus()
      })

      return dropdownView
    })
  }
}
