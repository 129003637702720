import {
  type AudienceRootExpression,
  OperandType,
} from '@blissbook/lib/expression'
import React from 'react'
import { PeopleExpressionInput } from './PeopleExpressionInput'
import { useAudienceExpressionFields } from './hooks'

export type AudienceExpressionInputProps = {
  addButtonEmptyOutline?: boolean
  allowPeople?: boolean
  className?: string
  disabled?: boolean
  onChange: (value: AudienceRootExpression) => void
  style?: React.CSSProperties
  value: AudienceRootExpression
}

export function AudienceExpressionInput({
  allowPeople,
  onChange,
  ...props
}: AudienceExpressionInputProps) {
  const { value } = props
  const hasPeople = value.expressions.some((expression) =>
    expression.operands.some((op) => op.type === OperandType.People),
  )
  const fields = useAudienceExpressionFields({
    allowPeople: allowPeople || hasPeople,
  })
  return (
    <PeopleExpressionInput
      {...props}
      fields={fields}
      onChange={(newValue) => onChange(newValue as AudienceRootExpression)}
      showSegmentAlert={true}
    />
  )
}
