import {
  type SavedSegment,
  useSavedSegments,
} from '@blissbook/application/graph'
import { useMemo } from 'react'

export function useSavedSegmentsById(savedSegmentsIdSet: Set<number>) {
  const savedSegments = useSavedSegments()
  return useMemo(() => {
    const savedSegmentsById = new Map<number, SavedSegment>()
    savedSegments
      .filter((ss) => savedSegmentsIdSet.has(ss.id))
      .forEach((ss) => savedSegmentsById.set(ss.id, ss))
    return savedSegmentsById
  }, [savedSegments, savedSegmentsIdSet])
}
