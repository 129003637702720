import { sanitizeHtml } from '@blissbook/lib/sanitize'
// @ts-ignore: WIP imports
import { Button, SearchInput } from '@blissbook/ui/lib'
import { ChangeIcon } from '@blissbook/ui/lib/icons'
import { useStore } from '@blissbook/ui/util/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import indexOf from 'lodash/indexOf'
import React, { type MouseEvent, useEffect, useMemo, useState } from 'react'
import { DesktopLanguageDropdown } from '../language'
import { translate } from '../translate'
import { SearchResultInput } from './input'
import { type SearchResult, renderResultHtml } from './results'
import type { SearchState } from './state'
import './desktop.scss'

const onMouseDown = (event: MouseEvent) => {
  event.preventDefault()
}

export const DesktopSearchBar: React.FC<{
  isOpen: boolean
  setTocOpen: (isOpen: boolean) => void
  state: SearchState
  isTocOpen: boolean
}> = ({ isOpen, setTocOpen, state, isTocOpen }) => {
  const handbook = useStore('handbook')
  const { moveResultIndex, onChangeSearch, results, search } = state
  const hasChanges = handbook.annotations.length > 0
  const hasLanguages = handbook.languageCodes.length > 1
  const hasResults = results.length > 0
  const baseWidth = 250
  const width = baseWidth + (hasResults ? 100 : 0) + (hasLanguages ? 60 : 0)

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 40) {
      // Down
      event.preventDefault()
      event.stopPropagation()
      moveResultIndex(1)
    } else if (event.keyCode === 38) {
      // Up
      event.preventDefault()
      event.stopPropagation()
      moveResultIndex(-1)
    }
  }

  return (
    <div
      className={classnames('tw-left-0 tw-hidden lg:tw-block below-header', {
        '-open': isOpen,
        '-results': results.length > 0,
      })}
      css={{
        marginLeft: isTocOpen ? 380 : -baseWidth,
      }}
      id='desktop-search'
      style={{ width }}
    >
      <header className='tw-flex tw-items-center'>
        <Button
          aria-label={translate('Open Table of Contents')}
          title={translate('Open Table of Contents')}
          className='btn-icon menu'
          onClick={() => setTocOpen(true)}
        >
          <FontAwesomeIcon icon='bars' />
          <span>TOC</span>
          <If condition={hasChanges}>
            <ChangeIcon className='-outer' size={21} />
            <ChangeIcon className='-inner' size={17} />
          </If>
        </Button>

        <SearchInput
          clearIcon={false}
          css={{ flex: 1, minWidth: 0 }}
          onChangeValue={onChangeSearch}
          onKeyDown={onKeyDown}
          placeholder={`${translate('Search')}...`}
          value={search}
        />

        <SearchResultInput state={state} />

        <If condition={hasLanguages}>
          <DesktopLanguageDropdown />
        </If>
      </header>

      <SearchResultsList state={state} />
    </div>
  )
}

type SearchTooltip = {
  text: string
  x: number
  y: number
}

const SearchResultsList: React.FC<{
  state: SearchState
}> = ({ state }) => {
  const { resultIndex, results, search, setResultIndex } = state
  const [tooltip, setTooltip] = useState<SearchTooltip>()

  const updateTooltip = (event: MouseEvent) => {
    const { clientX, clientY } = event
    const target = document.elementFromPoint(clientX, clientY)
    const node = $(target).closest('.result')[0]
    const index = node ? indexOf(node.parentNode.children, node) : -1
    const result = results[index]
    if (!result || !result.tooltip) {
      setTooltip(undefined)
    } else {
      setTooltip({
        text: translate('In: {{title}}', {
          title: result.tooltip,
        }),
        x: clientX,
        y: clientY,
      })
    }
  }

  const resetTooltip = () => {
    setTooltip(undefined)
  }

  const onClickResult = (event: MouseEvent) => {
    const resultNode = $(event.target).closest('.result')[0] as HTMLElement

    if (resultNode?.parentNode) {
      const resultIndex = indexOf(resultNode.parentNode.children, resultNode)
      setResultIndex(resultIndex)
    }
  }

  return (
    <Choose>
      <When condition={!search} />
      <When condition={!results.length}>
        <div className='no-results' onMouseDown={onMouseDown}>
          {translate('No results found.')}
        </div>
      </When>
      <Otherwise>
        {/* biome-ignore lint/a11y/useKeyWithClickEvents: requires some work */}
        <ul
          className='results list-unstyled'
          onClick={onClickResult}
          onMouseDown={onMouseDown}
          onMouseLeave={resetTooltip}
          onMouseMove={updateTooltip}
          onWheel={(event) => {
            updateTooltip(event)
          }}
        >
          {results.map((result, index) => (
            <SearchResultListItem
              active={index === resultIndex}
              key={index}
              result={result}
              search={search}
            />
          ))}
        </ul>

        <If condition={!!tooltip}>
          <div
            className='search-tooltip'
            style={{
              left: tooltip.x,
              top: tooltip.y,
            }}
          >
            {tooltip.text}
          </div>
        </If>
      </Otherwise>
    </Choose>
  )
}

const SearchResultListItem = React.memo<{
  active: boolean
  result: SearchResult
  search: string
}>(({ active, children, result, search, ...props }) => {
  const [activeNode, setActiveNode] = useState<HTMLElement>()
  useEffect(() => {
    if (!activeNode) return
    activeNode.scrollIntoView({ block: 'nearest' })
  }, [activeNode])

  const html = useMemo(() => renderResultHtml(result, search), [result, search])

  return (
    <li
      {...props}
      className={classnames('result', { active })}
      ref={active ? setActiveNode : undefined}
    >
      <div className='text-center result-type'>{translate(result.type)}</div>
      <div
        // biome-ignore lint/security/noDangerouslySetInnerHtml: filtered manually
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}
        style={{ flex: 1 }}
      />
    </li>
  )
})
