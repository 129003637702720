import type {
  DateOperandValue,
  GroupsOperandValue,
  HandbooksOperandValue,
  ManagersOperandValue,
  OperandValue,
  PeopleOperandValue,
  SavedSegmentsOperandValue,
  SelectOperandValue,
  TextOperandValue,
} from '@blissbook/lib/expression'
import React, { useState } from 'react'
import {
  DateOperandInput,
  GroupsOperandInput,
  HandbooksOperandInput,
  ManagersOperandInput,
  type OperandField,
  PeopleOperandInput,
  SavedSegmentsOperandInput,
  SelectOperandInput,
  TextOperandInput,
} from './operands'

export type OperandInputProps = {
  className?: string
  field: OperandField
  forceOpen?: boolean
  onChange: (newValue: OperandValue) => void
  onClose: () => void
  onRemove: () => void
  value: OperandValue
}

export function OperandInput({
  field,
  forceOpen = false,
  onClose,
  value,
  ...props
}: OperandInputProps) {
  const [isOpen, setOpen] = useState(forceOpen)

  // Properties that all opertand inputs must take
  const inputProps = {
    ...props,
    forceOpen,
    isOpen,
    setOpen(isOpen: boolean) {
      setOpen(isOpen)
      if (!isOpen) onClose()
    },
  }

  switch (field.type) {
    case 'date':
      return (
        <DateOperandInput
          {...inputProps}
          field={field}
          value={value as DateOperandValue}
        />
      )
    case 'groups':
      return (
        <GroupsOperandInput
          {...inputProps}
          field={field}
          value={value as GroupsOperandValue}
        />
      )
    case 'handbooks':
      return (
        <HandbooksOperandInput
          {...inputProps}
          field={field}
          value={value as HandbooksOperandValue}
        />
      )
    case 'managers':
      return (
        <ManagersOperandInput
          {...inputProps}
          field={field}
          value={value as ManagersOperandValue}
        />
      )
    case 'people':
      return (
        <PeopleOperandInput
          {...inputProps}
          field={field}
          value={value as PeopleOperandValue}
        />
      )
    case 'savedSegments':
      return (
        <SavedSegmentsOperandInput
          {...inputProps}
          field={field}
          value={value as SavedSegmentsOperandValue}
        />
      )
    case 'select':
      return (
        <SelectOperandInput
          {...inputProps}
          field={field}
          value={value as SelectOperandValue}
        />
      )
    case 'text':
      return (
        <TextOperandInput
          {...inputProps}
          field={field}
          value={value as TextOperandValue}
        />
      )
    default:
      return null
  }
}
