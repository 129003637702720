import { type RootExpression, isIdsOperand } from '@blissbook/lib/expression'

const invalidTypes = ['people', 'savedSegments']

export function shouldCreateSegment(data: RootExpression): boolean {
  return (
    !data.expressions.some((expression) =>
      expression.operands.some((operand) =>
        invalidTypes.includes(operand.type),
      ),
    ) &&
    (data.expressions.length >= 3 ||
      data.expressions.some((expression) =>
        expression.operands.some(
          (operand) => isIdsOperand(operand) && operand.ids?.length >= 3,
        ),
      ))
  )
}
