import classnames from 'classnames'
import keyBy from 'lodash/keyBy'
import React from 'react'

export const handbookStates = [
  {
    id: 'draft' as const,
    action: 'Edit',
    buttonClassName: 'btn-gray-400',
    labelClassName: 'tw-bg-gray-400',
    textClassName: 'tw-text-gray-400',
    name: 'Draft',
  },
  {
    id: 'live' as const,
    action: 'View',
    buttonClassName: 'btn-green',
    labelClassName: 'tw-bg-green-500',
    textClassName: 'tw-text-green-500',
    name: 'Live',
  },
]

export type HandbookStateId = (typeof handbookStates)[number]['id']

export const handbookStatesById = keyBy(handbookStates, 'id')

export const HandbookStateLabel = ({
  className,
  variant,
  ...props
}: {
  className?: string
  variant: HandbookStateId
}) => {
  const state = handbookStatesById[variant]
  return (
    <div
      {...props}
      className={classnames(state.labelClassName, className)}
      css={{
        borderRadius: 10,
        color: 'white',
        fontSize: 14,
        textAlign: 'center',
        width: 60,
      }}
    >
      {state.name}
    </div>
  )
}
