import {
  type GroupsOperandValue,
  type OperandType,
  isGroupsOperandValueValid,
} from '@blissbook/lib/expression'
// @ts-ignore: WIP imports
import { Dropdown, SearchInput } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useGroups } from '../ExpressionContext'
import type { Group } from '../types'
import {
  OperandDropdownFooter,
  OperandDropdownOption,
  OperandDropdownToggleButton,
  RemoveIcon,
} from './components'
import type { BaseOperandField } from './types'

export type MatchOptionValue = {
  isEvery: boolean
  isNot: boolean
}

export type MatchOption = {
  label: string
  value: MatchOptionValue
}

export const matchOptions: MatchOption[] = [
  {
    label: 'include any matching',
    value: { isNot: false, isEvery: false },
  },
  {
    label: 'include all matching',
    value: { isNot: false, isEvery: true },
  },
  {
    label: 'exclude any matching',
    value: { isNot: true, isEvery: false },
  },
  {
    label: 'exclude all matching',
    value: { isNot: true, isEvery: true },
  },
]

export function getMatchOption(value: MatchOptionValue): MatchOption {
  return matchOptions.find(
    (option) =>
      option.value.isEvery === value.isEvery &&
      option.value.isNot === value.isNot,
  )
}

type GroupOption = {
  children: React.ReactNode
  key: string
  label: string
  value: Group
}

export function renderMaxValues(
  allValues: readonly string[],
  maxValues?: number,
) {
  const values = [...allValues]
  if (maxValues !== undefined && values.length > maxValues) {
    values.splice(maxValues - 1)
    values.push(`and ${allValues.length - values.length} others`)
  }
  return values.join(', ')
}

export function renderGroups(groups: Group[], maxValues?: number) {
  const values = groups.map((group) => group.name)
  return renderMaxValues(values, maxValues)
}

export type GroupsOperandField = BaseOperandField & {
  type: OperandType.Groups
  defaultValue: GroupsOperandValue
  searchGroups: (text: string) => Promise<Group[]>
}

export const GroupsFieldIcon: GroupsOperandField['Icon'] = (props) => (
  <FontAwesomeIcon {...props} icon={['far', 'user-friends']} />
)

export type GroupsOperandInputProps = {
  className?: string
  field: GroupsOperandField
  forceOpen?: boolean
  isOpen: boolean
  onChange: (value: GroupsOperandValue) => void
  onRemove: () => void
  setOpen: (isOpen: boolean) => void
  value: GroupsOperandValue
}

export function GroupsOperandInput({
  className,
  field,
  forceOpen,
  onChange,
  onRemove,
  setOpen,
  value,
  ...props
}: GroupsOperandInputProps) {
  const { searchGroups } = field
  const isValid = isGroupsOperandValueValid(value)
  const matchOption = getMatchOption(value)
  const groupIds = value.ids
  const groups = useGroups(groupIds)

  function handleSetOpen(isOpen: boolean) {
    setOpen(isOpen)
  }

  async function getGroupOptions(text: string) {
    const groups = await searchGroups(text)
    return groups.map((group) => ({
      children: (
        <OperandDropdownOption
          checked={(operand: GroupsOperandValue) =>
            operand.ids.includes(group.id)
          }
          Icon={(props) => (
            <FontAwesomeIcon {...props} icon={['far', 'user-friends']} />
          )}
        >
          {group.name}
        </OperandDropdownOption>
      ),
      key: group.id,
      value: group,
    }))
  }

  function handleAddGroupId(groupId: number) {
    onChange({ ...value, ids: [...groupIds, groupId] })
  }

  function handleRemoveGroupId(groupId: number) {
    const newGroupIds = groupIds.filter((id) => id !== groupId)
    onChange({ ...value, ids: newGroupIds })
  }

  return (
    <Dropdown.Provider {...props} setOpen={handleSetOpen}>
      <OperandDropdownToggleButton
        className={className}
        icon={<field.Icon />}
        isInvalid={!isValid && !forceOpen}
        label={field.label}
        onRemove={onRemove}
      >
        {matchOption.label}{' '}
        <span className='ellipsis' title={renderGroups(groups)}>
          {renderGroups(groups, 3)}
        </span>
      </OperandDropdownToggleButton>

      <Dropdown.Menu
        className='tw-flex tw-flex-col tw-overflow-visible tw-space-y-2 tw-p-3'
        maxHeight={400}
      >
        <div className='tw-flex tw-gap-2 tw-items-center'>
          <Dropdown.Provider>
            <Dropdown.ToggleButton
              className='btn btn-input'
              style={{ width: 215 }}
            >
              {matchOption.label}
            </Dropdown.ToggleButton>
            <Dropdown.Menu className='tw-p-3' sameWidth>
              {matchOptions.map((option, index) => (
                <Dropdown.Item
                  active={option === matchOption}
                  checked={option === matchOption}
                  key={index}
                  onClick={() => {
                    onChange({ ...value, ...option.value })
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Provider>

          <SearchInput
            autoFocus={groups.length === 0}
            className='tw-w-64'
            dropdownWidth={400}
            getOptions={getGroupOptions}
            minLength={0}
            noClearValueOnSelect
            onSelect={(option: GroupOption) => {
              const group = option.value
              if (groupIds.includes(group.id)) {
                handleRemoveGroupId(group.id)
              } else {
                handleAddGroupId(group.id)
              }
            }}
            placeholder='Search for groups...'
          />
        </div>

        {groups.length > 0 && (
          <div className='tw-flex-1 tw-flex tw-flex-col tw-overflow-auto'>
            {groups.map((group, index) => (
              <div
                className='tw-flex tw-items-center tw-justify-between tw-text-gray-600 hover:tw-text-gray-800 tw-px-2 tw-py-2 tw-text-base tw-group'
                key={index}
              >
                {group.name}
                <RemoveIcon
                  className='tw-invisible group-hover:tw-visible'
                  onClick={() => {
                    handleRemoveGroupId(group.id)
                  }}
                />
              </div>
            ))}
          </div>
        )}

        <OperandDropdownFooter onClose={() => setOpen(false)} />
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
