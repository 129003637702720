import {
  type ManagersOperandValue,
  type OperandType,
  isManagersOperandValueValid,
} from '@blissbook/lib/expression'
// @ts-ignore: WIP imports
import { Dropdown, SearchInput } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { usePeople } from '../ExpressionContext'
import type { Person } from '../types'
import { renderPeople } from './PeopleOperandInput'
import {
  OperandDropdownFooter,
  OperandDropdownToggleButton,
  RemoveIcon,
} from './components'
import type { BaseOperandField } from './types'

type ManagerReportsOption = {
  label: string
  value: boolean
}

const reportsOptions: ManagerReportsOption[] = [
  {
    label: 'All Reports',
    value: false,
  },
  {
    label: 'Direct Reports',
    value: true,
  },
]

type ManagerOption = {
  key: number
  label: string
  value: Person
}

export type ManagersOperandField = BaseOperandField & {
  type: OperandType.Managers
  defaultValue: ManagersOperandValue
  searchManagers: (text: string) => Promise<Person[]>
}

export type ManagersOperandInputProps = {
  className?: string
  field: ManagersOperandField
  forceOpen?: boolean
  isOpen: boolean
  onChange: (value: ManagersOperandValue) => void
  onRemove: () => void
  setOpen: (isOpen: boolean) => void
  value: ManagersOperandValue
}

export function ManagersOperandInput({
  className,
  field,
  forceOpen,
  onChange,
  onRemove,
  setOpen,
  value,
  ...props
}: ManagersOperandInputProps) {
  const { searchManagers } = field
  const isValid = isManagersOperandValueValid(value)
  const managerIds = value.ids
  const managers = usePeople(managerIds)

  const reportsOption = reportsOptions.find(
    (option) => option.value === value.managesDirectly,
  )

  function handleSetOpen(isOpen: boolean) {
    setOpen(isOpen)
  }

  function handleSelectReports(managesDirectly: boolean) {
    onChange({ ...value, managesDirectly })
  }

  async function getManagerOptions(text: string) {
    const managers = await searchManagers(text)
    return managers.map((manager) => ({
      icon: ['far', 'user-tie'],
      label: manager.fullName,
      key: manager.id,
      value: manager,
    }))
  }

  function handleSelectManager(manager: Person) {
    onChange({ ...value, ids: [...managerIds, manager.id] })
  }

  function handleRemoveManagerId(managerId: number) {
    onChange({
      ...value,
      ids: managerIds.filter((id) => id !== managerId),
    })
  }

  return (
    <Dropdown.Provider {...props} setOpen={handleSetOpen}>
      <OperandDropdownToggleButton
        className={className}
        icon={<field.Icon />}
        isInvalid={!isValid && !forceOpen}
        label={reportsOption.label}
        onRemove={onRemove}
      >
        under{' '}
        <span className='ellipsis' title={renderPeople(managers)}>
          {renderPeople(managers, 3)}
        </span>
      </OperandDropdownToggleButton>

      <Dropdown.Menu
        className='tw-overflow-visible tw-space-y-2 tw-p-3'
        maxHeight={400}
      >
        <div className='tw-flex tw-gap-2 tw-items-center'>
          <Dropdown.Provider>
            <Dropdown.ToggleButton className='btn btn-input tw-w-32'>
              {reportsOption.label}
            </Dropdown.ToggleButton>
            <Dropdown.Menu className='tw-p-3 tw-w-40'>
              {reportsOptions.map((option, index) => (
                <Dropdown.Item
                  active={option === reportsOption}
                  checked={option === reportsOption}
                  className='tw-flex tw-items-center tw-justify-between'
                  key={index}
                  onClick={() => {
                    handleSelectReports(option.value)
                  }}
                >
                  <div className='tw-flex-1 ellipsis'>{option.label}</div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Provider>
          under
          <Choose>
            <When condition={managers.length > 0}>
              {managers.length > 0 && (
                <div className='tw-flex tw-flex-col'>
                  {managers.map((manager, index) => (
                    <div
                      className='tw-flex tw-items-center tw-justify-between tw-text-gray-500 tw-text-base tw-gap-2 tw-group'
                      key={index}
                    >
                      {manager.fullName}
                      <RemoveIcon
                        className='tw-invisible group-hover:tw-visible'
                        onClick={() => {
                          handleRemoveManagerId(manager.id)
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </When>
            <Otherwise>
              <SearchInput
                autoFocus={forceOpen}
                getOptions={getManagerOptions}
                minLength={0}
                onSelect={(option: ManagerOption) => {
                  handleSelectManager(option.value)
                }}
                placeholder='Search for Manager...'
              />
            </Otherwise>
          </Choose>
        </div>

        <OperandDropdownFooter onClose={() => setOpen(false)} />
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
