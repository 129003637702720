import React, {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

export enum ActivePanel {
  None = 'none',
  Annotations = 'annotations',
  CommentsArchive = 'commentsArchive',
}

export type CKEditorContext = {
  activePanel: ActivePanel
  setActivePanel: (panel: ActivePanel) => void
  trackingChanges: boolean
  setTrackingChanges: (tracking: boolean) => void
}

const defaultContext: CKEditorContext = {
  activePanel: ActivePanel.None,
  setActivePanel: () => {},
  trackingChanges: false,
  setTrackingChanges: () => {},
}

const OperandContext = createContext<CKEditorContext>(defaultContext)

export const CKEditorContextProvider = ({
  children,
  onShowAnnotations,
  onShowCommentsArchive,
}: {
  children: ReactNode
  onShowAnnotations?: (show: boolean) => void
  onShowCommentsArchive?: (show: boolean) => void
}) => {
  const [activePanel, setActivePanel] = useState<ActivePanel>(ActivePanel.None)
  const [trackingChanges, setTrackingChanges] = useState(false)

  const value = useMemo(
    () => ({
      activePanel,
      setActivePanel,
      trackingChanges,
      setTrackingChanges,
    }),
    [activePanel, setActivePanel, trackingChanges, setTrackingChanges],
  )

  useEffect(() => {
    onShowAnnotations?.(activePanel === ActivePanel.Annotations)
    onShowCommentsArchive?.(activePanel === ActivePanel.CommentsArchive)
  }, [activePanel, onShowAnnotations, onShowCommentsArchive])

  return (
    <OperandContext.Provider value={value}>{children}</OperandContext.Provider>
  )
}

export function useCKEditorContext() {
  return useContext(OperandContext)
}
