// New functions
export function decodeBase64JSON<T = any>(
  value: string | undefined,
  defaultValue?: T,
): T {
  try {
    const data = atob(value || '')
    const decodedData = new TextDecoder().decode(
      Uint8Array.from(data, (c) => c.charCodeAt(0)),
    )
    return JSON.parse(decodedData)
  } catch (_error) {
    // Ignore the error and return the default value, if any
    return defaultValue
  }
}

export function encodeBase64JSON<T = any>(
  value: T | undefined,
): string | undefined {
  if (value === undefined) return undefined
  const data = JSON.stringify(value)
  const encodedData = new TextEncoder().encode(data)
  return btoa(String.fromCharCode(...encodedData))
}
