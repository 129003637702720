import type { SelectOperandValue } from '@blissbook/lib/expression'
import {
  TypeaheadOperandInput,
  type TypeaheadOperandOption,
} from './components/TypeaheadOperandInput'
import type { BaseOperandField } from './types'

export type TypeaheadSelectOperandOption = TypeaheadOperandOption<string>

export type TypeaheadSelectOperandField = BaseOperandField &
  Pick<SelectOperandValue, 'type' | 'field' | 'path'> & {
    defaultValue: SelectOperandValue
    inputType: 'typeahead'
    allowAny?: boolean
    allowEvery?: boolean
    searchOptions: (text: string) => Promise<TypeaheadSelectOperandOption[]>
    useOptions: (
      values: string[] | undefined,
    ) => TypeaheadSelectOperandOption[] | undefined
  }

export const TypeaheadSelectOperandInput = TypeaheadOperandInput<
  TypeaheadSelectOperandField,
  SelectOperandValue,
  string
>
