import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye'
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf'
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import {
  ButtonView,
  Plugin,
  type ViewDocumentSelection,
  type ViewElement,
  WidgetToolbarRepository,
  isWidget,
} from 'ckeditor5'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { bindButtonView, getElementAttributes, getIconPath } from './util'

export function getSelectedPdfViewWidget(
  selection: ViewDocumentSelection,
): ViewElement | null {
  const viewElement = selection.getSelectedElement()

  if (viewElement && isPdfWidget(viewElement)) {
    return viewElement
  }

  return null
}

export function isPdfWidget(viewElement: ViewElement): boolean {
  return !!viewElement.getCustomProperty('pdf') && isWidget(viewElement)
}

export class PdfUI extends Plugin {
  init() {
    const editor = this.editor as BlissbookEditor
    const insertCommand = editor.commands.get('insertImage')
    const t = editor.t

    const widgetToolbarRepository = editor.plugins.get(WidgetToolbarRepository)
    widgetToolbarRepository.register('pdf', {
      ariaLabel: t('PDF Toolbar'),
      items: ['showPdfThumbnail', '|', 'editPdf', 'removePdf'],
      getRelatedElement: getSelectedPdfViewWidget,
    })

    editor.ui.componentFactory.add('insertPdf', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faFilePdf),
        label: t('Insert PDF'),
        tooltip: true,
      })

      // @ts-ignore Bind the state of the button to the command.
      view.bind('isOn', 'isEnabled').to(insertCommand, 'value', 'isEnabled')

      // Execute the command when the button is clicked (executed).
      this.listenTo(view, 'execute', async () => {
        const pdfUrl = await editor.pickPdf()

        if (pdfUrl) {
          editor.model.change((writer) => {
            // Insert a PDF at the current selection or at the end of the content
            const pdfElement = writer.createElement('pdf', {
              aspectRatio: '8.5/11',
              url: pdfUrl,
              width: '50%',
            })

            // Insert the PDF in the current selection or at the end of the editor's content
            editor.model.insertContent(
              pdfElement,
              editor.model.document.selection,
            )
          })
        }

        editor.focus()
      })

      return view
    })

    editor.ui.componentFactory.add('showPdfThumbnail', (locale) => {
      const buttonView = new ButtonView(locale)

      buttonView.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faEye),
        label: t('Show Thumbnail'),
        tooltip: true,
      })

      bindButtonView(buttonView, {
        editor,
        isOn: (selectedElement) =>
          selectedElement.getAttribute('showThumbnail') as boolean,
      })

      // Execute the command when the button is clicked (executed).
      this.listenTo(buttonView, 'execute', async () => {
        const { selection } = editor.model.document
        const pdfElement = selection.getSelectedElement()
        const showThumbnail = pdfElement.getAttribute(
          'showThumbnail',
        ) as boolean

        editor.model.change((writer) => {
          const attributes = getElementAttributes(pdfElement)
          const newPdfElement = writer.createElement('pdf', {
            ...attributes,
            showThumbnail: !showThumbnail,
          })
          editor.model.insertContent(newPdfElement, selection)
        })

        editor.focus()
      })

      return buttonView
    })

    editor.ui.componentFactory.add('editPdf', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faPenToSquare),
        label: t('Edit PDF'),
        tooltip: true,
      })

      // @ts-ignore Bind the state of the button to the command.
      view.bind('isOn', 'isEnabled').to(insertCommand, 'value', 'isEnabled')

      // Execute the command when the button is clicked (executed).
      this.listenTo(view, 'execute', async () => {
        const { selection } = editor.model.document
        const pdfElement = selection.getSelectedElement()
        const prevUrl = pdfElement.getAttribute('url') as string

        const url = await editor.pickPdf(prevUrl)
        if (url) {
          editor.model.change((writer) => {
            const attributes = getElementAttributes(pdfElement)
            const newPdfElement = writer.createElement('pdf', {
              ...attributes,
              url,
            })
            editor.model.insertContent(newPdfElement, selection)
          })
        }

        editor.focus()
      })

      return view
    })

    editor.ui.componentFactory.add('removePdf', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faTrashAlt),
        label: t('Remove PDF'),
        tooltip: true,
      })

      // @ts-ignore Bind the state of the button to the command.
      view.bind('isOn', 'isEnabled').to(insertCommand, 'value', 'isEnabled')

      // Execute the command when the button is clicked (executed).
      this.listenTo(view, 'execute', async () => {
        const { selection } = editor.model.document
        const pdf = selection.getSelectedElement()

        if (pdf) {
          editor.model.change((writer) => {
            writer.remove(pdf)
          })
        }

        editor.focus()
      })

      return view
    })
  }
}
