import type { SelectOperandValue } from '@blissbook/lib/expression'
import { Dropdown, Radio } from '@blissbook/ui/lib'
import React from 'react'
import type { SelectOperandInputProps } from './SelectOperandInput'
import {
  OperandDropdownFooter,
  OperandDropdownToggleButton,
} from './components'
import type { BaseOperandField } from './types'

type SelectOperandOption = {
  id: string
  label: string
}

export type RadioSelectOperandField = BaseOperandField &
  Pick<SelectOperandValue, 'type' | 'field' | 'path'> & {
    defaultValue: SelectOperandValue
    inputType: 'radio'
    options: SelectOperandOption[]
  }

export type RadioSelectOperandInputProps = SelectOperandInputProps & {
  field: RadioSelectOperandField
}

export function RadioSelectOperandInput({
  className,
  field,
  forceOpen,
  onChange,
  onRemove,
  value,
  ...props
}: RadioSelectOperandInputProps) {
  const { setOpen } = props
  const { options } = field
  const { values } = value
  const isInvalid = values.length === 0
  const activeOptions = options.filter((option) => values.includes(option.id))
  return (
    <Dropdown.Provider {...props}>
      <OperandDropdownToggleButton
        className={className}
        icon={<field.Icon />}
        isInvalid={isInvalid && !forceOpen}
        label={field.label}
        onRemove={onRemove}
      >
        is {activeOptions.map((option) => option.label).join(', ')}
      </OperandDropdownToggleButton>

      <Dropdown.Menu
        className='tw-flex tw-flex-col tw-gap-2 tw-p-3'
        maxHeight={400}
      >
        {options.map((option) => (
          <Radio
            checked={values.includes(option.id)}
            id={`${field.field}-${option.id}`}
            key={option.id}
            name={field.field}
            onChange={() => {
              onChange({ ...value, values: [option.id] })
            }}
            value={option.id}
          >
            {option.label}
          </Radio>
        ))}

        <OperandDropdownFooter onClose={() => setOpen(false)} />
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
