import { Dropdown } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import React, { type ReactNode, useContext } from 'react'
import { RemoveIcon } from './RemoveIcon'

export type OperandDropdownToggleButtonProps = {
  caret?: boolean
  children?: ReactNode
  className?: string
  icon?: JSX.Element
  isInvalid?: boolean
  label?: string
  onRemove?: () => void
  showCaret?: boolean
}

export function OperandDropdownToggleButton({
  caret = false,
  children,
  className,
  icon,
  isInvalid,
  label,
  onRemove,
}: OperandDropdownToggleButtonProps) {
  const transitionClassName = 'tw-transition tw-duration-150 tw-ease-in-out'
  const { isOpen } = useContext(Dropdown.Context)
  return (
    <Dropdown.ToggleButton
      caret={caret ? undefined : false}
      className={cx(
        'btn btn-secondary tw-relative tw-group',
        transitionClassName,
        isOpen
          ? 'tw-bg-blurple-100 tw-border-blurple-100 tw-text-blurple-500'
          : 'tw-bg-gray-200 tw-border-gray-200',
        isInvalid
          ? 'tw-bg-red-50 tw-border-red-50 tw-text-red-700 hover:tw-text-red-900'
          : 'tw-text-blurple-500',
        className,
      )}
      style={{
        maxWidth: 'calc(100% - .5rem)',
      }}
    >
      <span className='tw-inline-flex tw-items-center tw-gap-1.5 tw-max-w-full'>
        {icon}
        {label}

        {children && (
          <span
            className={cx(
              'tw-flex-1 tw-min-w-0 tw-font-normal ellipsis',
              transitionClassName,
              isInvalid
                ? 'tw-text-red-700 group-hover:tw-text-red-900'
                : 'tw-text-blurple-700',
            )}
          >
            {children}
          </span>
        )}
      </span>

      {onRemove && (
        <div
          className={cx(
            'tw-absolute tw-right-2 tw-py-1 tw-pl-6 tw-invisible group-hover:tw-visible tw-bg-gradient-to-l tw-from-50%',
            transitionClassName,
            isInvalid
              ? 'tw-from-red-50'
              : isOpen
                ? 'tw-from-blurple-100'
                : 'tw-from-gray-200',
          )}
        >
          <RemoveIcon
            className={cx(
              isInvalid
                ? 'tw-text-red-700 hover:tw-text-red-900'
                : 'tw-text-blurple-500 hover:tw-text-red-500',
            )}
            onClick={(event) => {
              event.stopPropagation()
              onRemove()
            }}
          />
        </div>
      )}
    </Dropdown.ToggleButton>
  )
}
