import type { WhodunnitPerson } from '@blissbook/application/graph'
import { type DateFormat, formatDate } from '@blissbook/lib/date-fns'
import { getFirstName } from '@blissbook/lib/name'
import { Tooltip } from '@blissbook/ui/lib/tooltip/Tooltip'
import React from 'react'

type Variant = 'name-first' | 'time-first'

export function formatWhodunnitDisplayName(
  whodunnit: WhodunnitPerson | undefined,
) {
  if (!whodunnit) return 'Someone'
  const { fullName, isBlissbook } = whodunnit
  return isBlissbook ? `${getFirstName(fullName)} from Blissbook` : fullName
}

export function WhodunnitView({
  compact,
  dateFormat,
  timestamp,
  whodunnit,
  variant = 'time-first',
  ...props
}: {
  className?: string
  compact?: boolean
  dateFormat?: DateFormat
  timestamp: Date
  whodunnit: WhodunnitPerson | undefined
  variant?: Variant
}) {
  const displayName = formatWhodunnitDisplayName(whodunnit)
  const tooltipContent =
    formatDate(timestamp, 'absolute') +
    (displayName ? ` by ${displayName}` : '')

  if (variant === 'name-first') {
    return (
      <Tooltip content={tooltipContent}>
        <span {...props}>
          {displayName}{' '}
          <span className='tw-text-gray-500 tw-text-sm'>
            {formatDate(timestamp, dateFormat)}
          </span>
        </span>
      </Tooltip>
    )
  }

  return (
    <Tooltip content={tooltipContent}>
      <span {...props}>
        {formatDate(timestamp, dateFormat)}
        <If condition={displayName && !compact}> by {displayName}</If>
      </span>
    </Tooltip>
  )
}
