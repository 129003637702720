import {
  type OperandType,
  type SavedSegmentsOperandValue,
  isSavedSegmentsOperandValueValid,
} from '@blissbook/lib/expression'
// @ts-ignore: WIP imports
import { Dropdown, SearchInput } from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSavedSegments } from '../ExpressionContext'
import type { SavedSegment } from '../types'
import {
  getMatchOption,
  matchOptions,
  renderMaxValues,
} from './GroupsOperandInput'
import {
  OperandDropdownFooter,
  OperandDropdownOption,
  OperandDropdownToggleButton,
  RemoveIcon,
} from './components'
import type { BaseOperandField } from './types'

type SavedSegmentOption = {
  children: React.ReactNode
  key: string
  label: string
  value: SavedSegment
}

export function renderSavedSegments(
  savedSegments: SavedSegment[],
  maxValues?: number,
) {
  const values = savedSegments.map((savedSegment) => savedSegment.name)
  return renderMaxValues(values, maxValues)
}

export type SavedSegmentsOperandField = BaseOperandField & {
  type: OperandType.SavedSegments
  defaultValue: SavedSegmentsOperandValue
  searchSavedSegments: (text: string) => Promise<SavedSegment[]>
}

export const SavedSegmentsFieldIcon: SavedSegmentsOperandField['Icon'] = (
  props,
) => <FontAwesomeIcon {...props} icon={['far', 'chart-pie-simple']} />

export type SavedSegmentsOperandInputProps = {
  className?: string
  field: SavedSegmentsOperandField
  forceOpen?: boolean
  isOpen: boolean
  onChange: (value: SavedSegmentsOperandValue) => void
  onRemove: () => void
  setOpen: (isOpen: boolean) => void
  value: SavedSegmentsOperandValue
}

export function SavedSegmentsOperandInput({
  className,
  field,
  forceOpen,
  onChange,
  onRemove,
  setOpen,
  value,
  ...props
}: SavedSegmentsOperandInputProps) {
  const { searchSavedSegments } = field
  const isValid = isSavedSegmentsOperandValueValid(value)
  const matchOption = getMatchOption(value)
  const savedSegmentIds = value.ids
  const savedSegments = useSavedSegments(savedSegmentIds)

  function handleSetOpen(isOpen: boolean) {
    setOpen(isOpen)
  }

  async function getSavedSegmentOptions(text: string) {
    const savedSegments = await searchSavedSegments(text)
    return savedSegments.map((savedSegment) => ({
      children: (
        <OperandDropdownOption
          checked={(operand: SavedSegmentsOperandValue) =>
            operand.ids.includes(savedSegment.id)
          }
          Icon={(props) => (
            <FontAwesomeIcon {...props} icon={['far', 'user-friends']} />
          )}
        >
          {savedSegment.name}
        </OperandDropdownOption>
      ),
      key: savedSegment.id,
      value: savedSegment,
    }))
  }

  function handleAddSavedSegmentId(savedSegmentId: number) {
    onChange({ ...value, ids: [...savedSegmentIds, savedSegmentId] })
  }

  function handleRemoveSavedSegmentId(savedSegmentId: number) {
    const newSavedSegmentIds = savedSegmentIds.filter(
      (id) => id !== savedSegmentId,
    )
    onChange({ ...value, ids: newSavedSegmentIds })
  }

  return (
    <Dropdown.Provider {...props} setOpen={handleSetOpen}>
      <OperandDropdownToggleButton
        className={className}
        icon={<field.Icon />}
        isInvalid={!isValid && !forceOpen}
        label={field.label}
        onRemove={onRemove}
      >
        {matchOption.label}{' '}
        <span className='ellipsis' title={renderSavedSegments(savedSegments)}>
          {renderSavedSegments(savedSegments, 3)}
        </span>
      </OperandDropdownToggleButton>

      <Dropdown.Menu
        className='tw-flex tw-flex-col tw-overflow-visible tw-space-y-2 tw-p-3'
        maxHeight={400}
      >
        <div className='tw-flex tw-gap-2 tw-items-center'>
          <Dropdown.Provider>
            <Dropdown.ToggleButton
              className='btn btn-input'
              style={{ width: 215 }}
            >
              {matchOption.label}
            </Dropdown.ToggleButton>
            <Dropdown.Menu className='tw-p-3' sameWidth>
              {matchOptions.map((option, index) => (
                <Dropdown.Item
                  active={option === matchOption}
                  checked={option === matchOption}
                  className='tw-flex tw-items-center tw-justify-between'
                  key={index}
                  onClick={() => {
                    onChange({ ...value, ...option.value })
                  }}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown.Provider>

          <SearchInput
            autoFocus={savedSegments.length === 0}
            className='tw-w-64'
            dropdownWidth={400}
            getOptions={getSavedSegmentOptions}
            minLength={0}
            noClearValueOnSelect
            onSelect={(option: SavedSegmentOption) => {
              const savedSegment = option.value
              if (savedSegmentIds.includes(savedSegment.id)) {
                handleRemoveSavedSegmentId(savedSegment.id)
              } else {
                handleAddSavedSegmentId(savedSegment.id)
              }
            }}
            placeholder='Search for segments...'
          />
        </div>

        {savedSegments.length > 0 && (
          <div className='tw-flex-1 tw-flex tw-flex-col tw-overflow-auto'>
            {savedSegments.map((savedSegment, index) => (
              <div
                className='tw-flex tw-items-center tw-justify-between tw-text-gray-600 hover:tw-text-gray-800 tw-px-2 tw-py-2 tw-text-base tw-group'
                key={index}
              >
                {savedSegment.name}
                <RemoveIcon
                  className='tw-invisible group-hover:tw-visible'
                  onClick={() => {
                    handleRemoveSavedSegmentId(savedSegment.id)
                  }}
                />
              </div>
            ))}
          </div>
        )}

        <OperandDropdownFooter onClose={() => setOpen(false)} />
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
}
