import type { SelectOperandValue } from '@blissbook/lib/expression'
import { getMetadataKeyLabel } from '@blissbook/lib/metadata'
import React from 'react'
import { MetadataIcon } from '../fields/MetadataIcon'
import { MultipleValueOperandView, SingleValueOperandView } from './components'
import {
  getMatchOption,
  getMatchOptions,
} from './components/TypeaheadOperandInput'

export type SelectOperandViewProps = {
  inline?: boolean
  value: SelectOperandValue
}

export function SelectOperandView({ inline, value }: SelectOperandViewProps) {
  const { path, values } = value

  // For now, assume this field is metadata
  const label = getMetadataKeyLabel(path)
  const icon = <MetadataIcon metadataKey={path} />

  // For now, assume this value came from a picklist (typeahead)
  const matchOptions = getMatchOptions({ allowAny: true, allowEvery: true })
  const matchOption = getMatchOption(matchOptions, value)

  if (values.length < 2) {
    const { isNot } = matchOption.value
    const [value] = values
    return (
      <div className={inline ? 'tw-inline-flex tw-max-w-full' : 'tw-flex'}>
        <SingleValueOperandView
          icon={icon}
          inline={inline}
          isInvalid={value === undefined}
          label={label}
        >
          {isNot ? 'is not' : 'is'} {value}
        </SingleValueOperandView>
      </div>
    )
  }

  return (
    <MultipleValueOperandView icon={icon} label={label} values={values}>
      {matchOption.label}
    </MultipleValueOperandView>
  )
}
