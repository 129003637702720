import type { Expression } from '@blissbook/lib/expression'
import pluralize from 'pluralize'
import React, { Fragment } from 'react'
import { OperandView } from './OperandView'
import type { TruncatedExpression } from './TruncatedRootExpression'

export type ExpressionViewProps = {
  hideParentheses?: boolean
  inline?: boolean
  value: TruncatedExpression
}

export function ExpressionView({
  hideParentheses,
  inline,
  value,
}: ExpressionViewProps) {
  let expressionEl = (
    <div
      className={
        inline
          ? 'tw-inline-block tw-space-x-2 tw-max-w-full'
          : 'tw-flex tw-flex-col tw-gap-1'
      }
    >
      {value.operands.map((operand, index) => (
        <Fragment key={index}>
          {index > 0 && <div>{value.conjunction}</div>}
          <OperandView inline={inline} value={operand} />
        </Fragment>
      ))}

      <If condition={value.truncatedCount > 0}>
        <div className={inline ? 'tw-inline-flex tw-max-w-full ellipsis' : ''}>
          ...{value.conjunction}{' '}
          {pluralize('other', value.truncatedCount, true)}
        </div>
      </If>
    </div>
  )

  const needsParentheses = value.operands.length > 1 || value.truncatedCount > 0
  const showParentheses = !hideParentheses && needsParentheses
  if (showParentheses) {
    expressionEl = (
      <div className='tw-border-2 tw-border-gray-300 tw-rounded tw-p-2'>
        {expressionEl}
      </div>
    )
  }

  return expressionEl
}
