import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle'
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage'
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons/faPenToSquare'
import { faSquare } from '@fortawesome/pro-regular-svg-icons/faSquare'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import {
  ButtonView,
  type ImageUtils,
  type InsertImageCommand,
  Plugin,
} from 'ckeditor5'
import type { BlissbookEditor } from '../../BlissbookEditor'
import {
  type ImageShapeId,
  circleImageShape,
  roundedImageShape,
} from '../Image'
import { bindButtonView, getIconPath } from './util'

const imageShapesUI = [
  { ...roundedImageShape, icon: faSquare },
  { ...circleImageShape, icon: faCircle },
]

export class ImageUI extends Plugin {
  init() {
    const editor = this.editor as BlissbookEditor
    const imageUtils: ImageUtils = editor.plugins.get('ImageUtils')
    const { t } = editor

    editor.ui.componentFactory.add('insertBlissbookImage', (locale) => {
      const imageCommand: InsertImageCommand =
        editor.commands.get('insertImage')
      const view = new ButtonView(locale)

      view.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faImage),
        label: t('Insert Image'),
        tooltip: true,
      })

      // @ts-ignore Bind the state of the button to the command.
      view.bind('isOn', 'isEnabled').to(imageCommand, 'value', 'isEnabled')

      // Callback executed once the button is clicked
      view.on('execute', async () => {
        const imageUrl = await editor.pickImage()

        if (imageUrl) {
          editor.model.change((writer) => {
            // Insert an image at the current selection or at the end of the content
            const imageElement = writer.createElement('imageBlock', {
              src: imageUrl,
            })

            // Insert the image in the current selection or at the end of the editor's content
            editor.model.insertContent(
              imageElement,
              editor.model.document.selection,
            )
          })
        }

        editor.focus()
      })

      return view
    })

    editor.ui.componentFactory.add('editImage', (locale) => {
      const imageCommand: InsertImageCommand =
        editor.commands.get('insertImage')
      const view = new ButtonView(locale)

      view.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faPenToSquare),
        label: t('Replace Image'),
        tooltip: true,
      })

      // @ts-ignore Bind the state of the button to the command.
      view.bind('isOn', 'isEnabled').to(imageCommand, 'value', 'isEnabled')

      // Callback executed once the button is clicked
      view.on('execute', async () => {
        const { selection } = editor.editing.view.document
        const figure = imageUtils.getClosestSelectedImageWidget(selection)
        const image = imageUtils.findViewImgElement(figure)
        const prevUrl = image.getAttribute('src')

        const imageUrl = await editor.pickImage(prevUrl)
        if (imageUrl) {
          editor.execute('replaceImageSource', { source: imageUrl })
        }

        editor.focus()
      })

      return view
    })

    editor.ui.componentFactory.add('removeImage', (locale) => {
      const view = new ButtonView(locale)

      view.set({
        class: 'ck-icon-fa',
        icon: getIconPath(faTrashAlt),
        label: t('Remove Image'),
        tooltip: true,
      })

      // Callback executed once the button is clicked
      view.on('execute', async () => {
        const { selection } = editor.model.document
        const image = selection.getSelectedElement()

        if (image) {
          editor.model.change((writer) => {
            writer.remove(image)
          })
        }

        editor.focus()
      })

      return view
    })

    for (const shape of imageShapesUI) {
      editor.ui.componentFactory.add(`imageShape:${shape.id}`, (locale) => {
        const buttonView = new ButtonView(locale)

        buttonView.set({
          class: 'ck-icon-fa',
          icon: getIconPath(shape.icon),
          label: t(shape.label),
          tooltip: true,
        })

        bindButtonView(buttonView, {
          editor,
          isOn: (selectedElement) =>
            selectedElement.getAttribute('imageShape') === shape.id,
        })

        // Callback executed once the button is clicked
        buttonView.on('execute', () => {
          const { selection } = editor.model.document
          const image = selection.getSelectedElement()

          if (image) {
            editor.model.change((writer) => {
              const prevShapeType = image.getAttribute('imageShape') as
                | ImageShapeId
                | undefined
              if (prevShapeType === shape.id) {
                writer.removeAttribute('imageShape', image)
              } else {
                writer.setAttribute('imageShape', shape.id, image)
              }
            })
          }

          editor.focus()
        })

        return buttonView
      })
    }
  }
}
