import type { RootExpression } from '@blissbook/lib/expression'
import {
  RootExpressionView,
  useExpressionIds,
} from '@blissbook/ui/lib/expression'
import React from 'react'
import {
  useGroupsById,
  useHandbooksById,
  usePeopleById,
  useSavedSegmentsById,
} from './hooks'

export type PeopleExpressionViewProps = {
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  inline?: boolean
  maxLines?: number
  style?: React.CSSProperties
  value: RootExpression
}

export function PeopleExpressionView(props: PeopleExpressionViewProps) {
  const { value } = props
  const { groupIds, handbookIds, personIds, savedSegmentIds } =
    useExpressionIds(value)
  const groupsById = useGroupsById(groupIds)
  const handbooksById = useHandbooksById(handbookIds)
  const peopleById = usePeopleById(personIds)
  const savedSegmentsById = useSavedSegmentsById(savedSegmentIds)
  return (
    <RootExpressionView
      {...props}
      groupsById={groupsById}
      handbooksById={handbooksById}
      peopleById={peopleById}
      savedSegmentsById={savedSegmentsById}
    />
  )
}
