import { cx } from '@emotion/css'
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faList } from '@fortawesome/pro-regular-svg-icons/faList'
import { faListNumeric } from '@fortawesome/pro-regular-svg-icons/faListNumeric'
import { faRedo } from '@fortawesome/pro-regular-svg-icons/faRedo'
import { faTable } from '@fortawesome/pro-regular-svg-icons/faTable'
import { faUndo } from '@fortawesome/pro-regular-svg-icons/faUndo'
import { faAlignCenter } from '@fortawesome/pro-solid-svg-icons/faAlignCenter'
import { faAlignLeft } from '@fortawesome/pro-solid-svg-icons/faAlignLeft'
import { faAlignRight } from '@fortawesome/pro-solid-svg-icons/faAlignRight'
import { faBold } from '@fortawesome/pro-solid-svg-icons/faBold'
import { faItalic } from '@fortawesome/pro-solid-svg-icons/faItalic'
import { faTextSlash } from '@fortawesome/pro-solid-svg-icons/faTextSlash'
import { faUnderline } from '@fortawesome/pro-solid-svg-icons/faUnderline'
import { ButtonView, DropdownView, Plugin } from 'ckeditor5'
import type { BlissbookEditor } from '../../BlissbookEditor'
import { getIconPath } from './util'

// Icons we need to decorate to override
const viewIcons: Record<string, IconDefinition> = {
  alignLeft: faAlignLeft,
  alignCenter: faAlignCenter,
  alignRight: faAlignRight,
  bold: faBold,
  bulletedList: faList,
  insertTable: faTable,
  numberedList: faListNumeric,
  redo: faRedo,
  removeFormat: faTextSlash,
  undo: faUndo,
  italic: faItalic,
  underline: faUnderline,
}

export class IconsUI extends Plugin {
  init() {
    const editor = this.editor as BlissbookEditor
    const { componentFactory } = editor.ui

    const { create } = componentFactory
    componentFactory.create = (name: string) => {
      const icon = viewIcons[name]
      const view = create.call(componentFactory, name)
      if (!icon) return view

      if (view instanceof ButtonView) {
        view.set({
          class: cx('ck-icon-fa', view.class),
          icon: getIconPath(icon),
        })
      }

      if (view instanceof DropdownView) {
        view.buttonView.set({
          class: cx('ck-icon-fa', view.class),
          icon: getIconPath(icon),
        })
      }

      return view
    }
  }
}
