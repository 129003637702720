import { PeopleExpressionTooltip } from '@blissbook/application/expression'
import { Button } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { DocumentSelection, Editor } from 'ckeditor5'
import React from 'react'
import type { BlissbookEditor } from '../BlissbookEditor'
import {
  type AudienceExpressionGroup,
  useAudienceExpressionGroups,
} from '../hooks'

function hasGroupFocus({
  editor,
  group,
  selection,
}: {
  editor: Editor
  group: AudienceExpressionGroup
  selection: DocumentSelection
}) {
  // Any element selected directly?
  const { blocks } = group
  const selectedElement = selection.getSelectedElement()
  const isSelected = blocks.includes(selectedElement)
  if (isSelected) return true

  // Otherwise, look at ranges
  const { model } = editor
  const firstPos = selection.getFirstPosition()
  const lastPos = selection.getLastPosition()
  const firstBlock = blocks.at(0)
  const firstBlockPos = model.createPositionAt(firstBlock, 'before')
  const lastBlock = blocks.at(-1)
  const lastBlockPos = model.createPositionAt(lastBlock, 'end')
  return (
    (firstPos.isAfter(firstBlockPos) || firstPos.isEqual(firstBlockPos)) &&
    (lastPos.isBefore(lastBlockPos) || lastPos.isEqual(lastBlockPos))
  )
}

function AudienceExpressionGroupView({
  editor,
  focus,
  group,
}: {
  editor: BlissbookEditor
  focus: boolean
  group: AudienceExpressionGroup
}) {
  const { blocks, rect } = group
  const paddingY = 2
  const paddingX = 4

  async function handleClick() {
    const audienceExpression = await editor.pickAudienceExpression(
      group.audienceExpression,
    )
    if (!audienceExpression) return

    editor.commands.execute('setAudienceExpression', {
      audienceExpression,
      blocks,
    })
  }

  return (
    <div className='tw-group tw-pointer-events-none'>
      <PeopleExpressionTooltip audienceExpression={group.audienceExpression}>
        <Button
          className={cx(
            'tw-absolute tw-pointer-events-auto tw-border-purple-100/0 tw-pointer-events-auto tw-py-0 tw-px-1 tw-text-purple-500 tw-text-xs tw-transition-colors',
            focus ? 'tw-bg-purple-100' : 'tw-bg-white hover:tw-bg-purple-100',
          )}
          onClick={handleClick}
          style={{
            left: -10,
            top: rect.top - paddingY,
          }}
        >
          <FontAwesomeIcon icon={faLock} />
        </Button>
      </PeopleExpressionTooltip>

      <div
        className={cx(
          'tw-absolute tw-border-[3px] tw-border-dashed tw-rounded-sm tw-transition-colors',
          focus
            ? 'tw-border-purple-500'
            : 'tw-border-transparent group-hover:tw-border-purple-500',
        )}
        style={{
          top: rect.top - paddingY,
          left: rect.left - paddingX,
          width: rect.width + paddingX * 2,
          height: rect.height + paddingY * 2,
        }}
      />
    </div>
  )
}

export function CKEditorAudienceExpressionsView({
  editor,
}: {
  editor: BlissbookEditor
}) {
  const { selection } = editor.model.document
  const groups = useAudienceExpressionGroups(editor)
  return (
    <div>
      {groups.map((group, index) => {
        return (
          <AudienceExpressionGroupView
            editor={editor}
            focus={hasGroupFocus({ editor, group, selection })}
            group={group}
            key={index}
          />
        )
      })}
    </div>
  )
}
