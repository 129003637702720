import { useGroupsById as _useGroupsById } from '@blissbook/application/hooks'
import type { Group } from '@blissbook/common/group'
import { useCallback, useMemo } from 'react'

function useOrderedGroups() {
  const groupsById = _useGroupsById()
  return useMemo(() => {
    const groups = Object.values(groupsById).filter((group) => !group.archived)
    return groups.sort((a, b) => a.name.localeCompare(b.name))
  }, [groupsById])
}

export type FilterGroupsArgs = {
  limit: number
  search: string
}

async function filterGroups(
  groups: Group[],
  { limit, search }: FilterGroupsArgs,
) {
  search = search.toLowerCase()

  const result: Group[] = []
  for (const group of groups) {
    if (result.length >= limit) break
    if (group.name.toLowerCase().includes(search)) {
      result.push(group)
    }
  }
  return result
}

export type SearchGroupsArgs = Omit<FilterGroupsArgs, 'search'>

export function useSearchGroups(args: SearchGroupsArgs) {
  const groups = useOrderedGroups()
  return useCallback(
    (search) => filterGroups(groups, { ...args, search }),
    [groups],
  )
}
