import { Plugin, Widget } from 'ckeditor5'
import { SetAudienceExpressionCommand } from './SetAudienceExpressionCommand'
import { audienceExpressionAttributeKey } from './SetAudienceExpressionCommand'

const domAttr = 'data-audience-expression'

const itemNames = [
  'paragraph',
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'table',
  'readMore',
]

export class AudienceExpressionEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()

    this.editor.commands.add(
      'setAudienceExpression',
      new SetAudienceExpressionCommand(this.editor),
    )
  }

  _defineSchema() {
    const { editor } = this
    const { model } = editor
    const { schema } = model

    // Add a custom attribute to all block elements
    for (const itemName of itemNames) {
      schema.extend(itemName, {
        allowAttributes: [audienceExpressionAttributeKey],
      })
    }

    // Downcast converter: model -> view
    editor.conversion.for('downcast').add((dispatcher) => {
      dispatcher.on(
        `attribute:${audienceExpressionAttributeKey}`,
        (_event, data, conversionApi) => {
          const viewWriter = conversionApi.writer
          const viewElement = conversionApi.mapper.toViewElement(data.item)
          if (!viewElement) return

          if (data.attributeNewValue) {
            viewWriter.setAttribute(
              domAttr,
              data.attributeNewValue,
              viewElement,
            )
          } else {
            viewWriter.removeAttribute(domAttr, viewElement)
          }
        },
      )
    })

    editor.conversion.for('upcast').add((dispatcher) => {
      dispatcher.on('element', (_event, data, conversionApi) => {
        const viewElement = data.viewItem

        if (viewElement.hasAttribute(domAttr)) {
          conversionApi.writer.setAttribute(
            audienceExpressionAttributeKey,
            viewElement.getAttribute(domAttr),
            data.modelRange,
          )
        }
      })
    })
  }
}
