import type { OperandValue } from '@blissbook/lib/expression'
import {
  Dropdown,
  // @ts-ignore: WIP imports
  ScrollContainer,
  // @ts-ignore: WIP imports
  SearchInput,
} from '@blissbook/ui/lib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { nanoid } from 'nanoid'
import React, { useMemo, useState, forwardRef } from 'react'
import type { ShowArchivedField } from './fields'
import { type OperandField, getOperandFieldId } from './operands'

type NewOperandDropdownItemProps = {
  description?: string
  Icon: (props: { className: string }) => JSX.Element
  label: string
  onClick: () => void
}

type SearchWrapperProps = {
  search: string | null
  setSearch: React.Dispatch<React.SetStateAction<string | null>>
}

function NewOperandDropdownItem({
  description,
  Icon,
  label,
  ...props
}: NewOperandDropdownItemProps) {
  return (
    <Dropdown.Item {...props}>
      <Icon className='dropdown-item-icon' />
      {label}
      {description && (
        <div
          className='tw-text-xs tw-whitespace-normal tw-text-black-faded tw-leading-tight tw-pb-0.5'
          style={{ paddingLeft: 22 }}
        >
          {description}
        </div>
      )}
    </Dropdown.Item>
  )
}

export type NewOperandButtonProps = {
  children?: React.ReactNode
  className?: string
  fields: OperandField[]
  onSelect: (value: OperandValue) => void
  showArchivedField?: ShowArchivedField
}

export const NewOperandButton = forwardRef<
  HTMLButtonElement,
  NewOperandButtonProps
>(({ fields, onSelect, showArchivedField, ...props }, ref) => {
  const [isOpen, setOpen] = useState(false)
  const [search, setSearch] = useState<string | null>(null)
  const showSearchBar = fields.length > 10

  function handleSelectField(field: OperandField) {
    onSelect({
      ...field.defaultValue,
      id: nanoid(),
    })

    if ('showArchived' in field) {
      showArchivedField?.onChange(field.showArchived)
    }
  }

  /** When the dropdown closes, clear the search */
  function handleSetOpen(isOpen: boolean) {
    setOpen(isOpen)
    if (!isOpen) {
      setSearch(null)
    }
  }

  const filteredItems = useMemo(() => {
    if (!search) return fields
    const regEx = new RegExp(search, 'i')
    return fields.filter((item) => regEx.test(item.label))
  }, [fields, search])

  return (
    <Dropdown.Provider isOpen={isOpen} setOpen={handleSetOpen}>
      <Dropdown.ToggleButton {...props} caret={false} ref={ref} />
      <Dropdown.Menu className='tw-max-w-64'>
        {showSearchBar && (
          <div className='tw-sticky tw-top-0 tw-bg-white tw-z-10'>
            <SearchWrapper search={search} setSearch={setSearch} />
          </div>
        )}
        <ScrollContainer maxHeight='288px'>
          {filteredItems.map((field) => (
            <NewOperandDropdownItem
              description={field.description}
              Icon={field.Icon}
              key={getOperandFieldId(field)}
              label={field.label}
              onClick={() => handleSelectField(field)}
            />
          ))}
        </ScrollContainer>

        {showArchivedField !== undefined && !showArchivedField.value && (
          <div className='tw-sticky tw-bottom-0'>
            <Dropdown.Divider />
            <NewOperandDropdownItem
              description={showArchivedField.description}
              Icon={(props) => (
                <FontAwesomeIcon {...props} icon={['far', 'eye']} />
              )}
              onClick={() => {
                showArchivedField.onChange(true)
              }}
              label='Show Archived'
            />
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown.Provider>
  )
})

const SearchWrapper = ({ search, setSearch }: SearchWrapperProps) => (
  <div className='dropdown-item-text tw-px-1 tw-pt-1'>
    <SearchInput
      autoFocus
      css={{
        width: '100%',
      }}
      debounce={0}
      onChangeValue={setSearch}
      value={search}
    />
  </div>
)
