import { useGroupsById as _useGroupsById } from '@blissbook/application/hooks'
import type { Group } from '@blissbook/ui/lib/expression'
import { useMemo } from 'react'

export function useGroupsById(groupIdSet: Set<number>) {
  const _groupsById = _useGroupsById()
  return useMemo(() => {
    const groupsById = new Map<number, Group>()
    for (const groupId of groupIdSet) {
      const group = _groupsById[groupId]
      if (!group) continue
      groupsById.set(groupId, group)
    }
    return groupsById
  }, [groupIdSet, _groupsById])
}
