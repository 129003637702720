import type { GroupsOperandValue } from '@blissbook/lib/expression'
import React from 'react'
import { useGroups } from '../ExpressionContext'
import { getMatchOption } from './GroupsOperandInput'
import { GroupsFieldIcon } from './GroupsOperandInput'
import { MultipleValueOperandView, SingleValueOperandView } from './components'

export type GroupsOperandViewProps = {
  inline?: boolean
  value: GroupsOperandValue
}

export function GroupsOperandView({ inline, value }: GroupsOperandViewProps) {
  const matchOption = getMatchOption(value)
  const groups = useGroups(value.ids)

  if (groups.length < 2) {
    const { isNot } = matchOption.value
    const [group] = groups
    return (
      <div className={inline ? 'tw-inline-flex tw-max-w-full' : 'tw-flex'}>
        <SingleValueOperandView
          icon={<GroupsFieldIcon />}
          inline={inline}
          isInvalid={!group}
          label='Group'
        >
          {isNot ? 'is not' : 'is'} {group?.name}
        </SingleValueOperandView>
      </div>
    )
  }

  return (
    <MultipleValueOperandView
      icon={<GroupsFieldIcon />}
      label='Groups'
      values={groups.map((group) => group.name)}
    >
      {matchOption.label}
    </MultipleValueOperandView>
  )
}
